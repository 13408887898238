import React, { useContext } from 'react'
import "../Dashboard/dashboard.css"
import { Link, useLocation } from 'react-router-dom'
import { AuthContext } from '../../context/authContext'

import ppolLogo from "../../images/ppolLogo.png"
import profileDefaultImg from "../../images/profileDefaultImg.png"
import accountIcon from "../../images/accountIcon.png"
import beneficiosIcon from "../../images/beneficiosIcon.png"
import configIcon from "../../images/configIcon.png"
import contactsIcon from "../../images/contactsIcon.png"
import metricsIcon from "../../images/metricsIcon.png"
import supportIcon from "../../images/supportIcon.png"
import tutorialIcon from "../../images/tutorialIcon.png"
import profileDotsIcon from "../../images/profileDotsIcon.png"
import webIcon from "../../images/webIcon.png"
import logoutIcon from "../../images/logoutIcon.png"
import shareIcon from "../../images/shareIcon.png"
import proximamenteImg from "../../images/proximamenteImg.png"

function ProfileBenefits() {
  let {currentUser, logout} = useContext(AuthContext);
  const userId = useLocation().pathname.split("/")[1];
  //console.log(currentUser)

  const logoutUser = async (e) => {
    e.preventDefault()
    try{
        await logout()
        currentUser = null
        //navigate(`/${username}/edit`)
    }catch(error){
        console.log("*" + error.response.data.error)
    }
  }
  
  return (
  <div className="main" style={{backgroundColor:"#F5F5F5"}}>
      <nav className='nav' style={{display:window.innerWidth>730?"flex":"none"}}>
          <div className='nav-top'>
              <img id="ppolLogo" loading="lazy" src={ppolLogo} alt='Ppol Logo'></img>
              <Link to={`/${userId}/edit`} className='navBtn'><img loading="lazy" src={accountIcon} alt="Mi Cuenta"/>Mi Cuenta</Link>
              <Link to={`/${userId}/contacts`} className='navBtn'><img loading="lazy" src={contactsIcon} alt="Contactos"/>Contactos</Link>
              <Link to={`/${userId}/metrics`} className='navBtn'><img loading="lazy" src={metricsIcon} alt="Métricas"/>Métricas</Link>
              <Link to={`/${userId}/benefits`} className='navBtn' style={{backgroundColor:"#E8542F",color:"#6A1803"}}><img loading="lazy" src={beneficiosIcon} alt="Beneficios" style={{filter:"invert(0)"}}/>Beneficios</Link>
                
          </div>
          <div className="nav-bottom">
              <Link to={`/${userId}/tutorial`} className='navBtn'><img loading="lazy" src={tutorialIcon} alt="Tutoriales"/>Tutoriales</Link>
              <Link to={`/${userId}/support`} className='navBtn'><img loading="lazy" src={supportIcon} alt="Soporte"/>Soporte</Link>
              <Link to={`/${userId}/config`} className='navBtn'><img loading="lazy" src={configIcon} alt="Configuración"/>Configuración</Link>
              <div className='profileBtn'>
                  <div className="profileNavImg">
                      <img loading="lazy" src={profileDefaultImg} alt=''></img>
                  </div>
                  <div className='profileNavNameOptions'>
                      <span>{`/${userId}`}</span>
                      <img loading="lazy" src={profileDotsIcon} alt=""/>
                      <div className="profileBtnOptions">
                          <a className='profileOptionsBtn' href={`/${userId}`} target='_blank' rel="noreferrer">
                              <img loading="lazy" src={webIcon} alt="Ver Perfil" />
                              <span>Ver perfil</span>
                          </a>
                          <button className='profileOptionsBtn'>
                              <img loading="lazy" src={shareIcon} alt="Copiar Link" />
                              <span>Copiar Link</span>
                          </button>
                          <button className='profileOptionsBtn' onClick={logoutUser}>
                              <img loading="lazy" src={logoutIcon} alt="Salir" />
                              <span>Salir</span>
                          </button>
                      </div>
                  </div>
              </div>
          </div>
      </nav>
      <div className="prox" style={{gridColumn:window.innerWidth>730?"2":"1"}}>
          <img className='proxImg' loading="lazy" src={proximamenteImg} alt="Proximamente"/>
      </div>
  </div>
  )
}

export default ProfileBenefits