import React, { useContext, useState } from 'react'
import "../Dashboard/dashboard.css"
import { useQuery } from '@tanstack/react-query';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { AuthContext } from '../../context/authContext'
import { dotSpinner, ripples } from 'ldrs';
import Navbar from '../Navbar/Navbar.jsx';
import NavbarMobile from '../Navbar/NavbarMobile.jsx'
import Loading from '../Loading/Loading.jsx';
import { makeRequest } from '../../axios'

import ppolLogo from "../../images/ppolLogo.png"
import profileDefaultImg from "../../images/profileDefaultImg.png"
import accountIcon from "../../images/accountIcon.png"
import beneficiosIcon from "../../images/beneficiosIcon.png"
import configIcon from "../../images/configIcon.png"
import contactsIcon from "../../images/contactsIcon.png"
import metricsIcon from "../../images/metricsIcon.png"
import supportIcon from "../../images/supportIcon.png"
import tutorialIcon from "../../images/tutorialIcon.png"
import profileDotsIcon from "../../images/profileDotsIcon.png"
import webIcon from "../../images/webIcon.png"
import logoutIcon from "../../images/logoutIcon.png"
import shareIcon from "../../images/shareIcon.png"
import loadingGif from "../../images/loadingGif.gif"
import ppolWhite from "../../images/ppolWhite.svg"

import supportCall from "../../images/supportCall.png"
import supportWsp from "../../images/supportWsp.png"
import supportEmail from "../../images/supportEmail.png"

function ProfileSupport() {
  document.title = `Soporte | PPOL`
  let {currentUser} = useContext(AuthContext);
  const userId = useLocation().pathname.split("/")[1];

  const navigate = useNavigate()

  const {isLoading, data} = useQuery(["user"],() => 
    makeRequest.get("findUserBasicInfo.php", {params: {user: userId}})
    .then((res) => {
      if(currentUser.userlink === res.data.userlink){
        localStorage.setItem("profileImg", JSON.parse(res.data.profileImages).profile)
      } else {
        localStorage.clear()
        navigate("/404")
      }
      return res.data
    })
  )

  return (
    <div className="main">
        <Navbar activeBtn={'support'}/>
        {isLoading?
          <Loading/>
          :
          <div className="support">
            <NavbarMobile activeBtn='support'/>
            <section className='main-title-container'>
              <h1 className='main-title'>Soporte</h1>
            </section>
            <section className='supportCont'>
              <section className='supportSection'>
                <div className='supportSectionTextCont'>
                  <p className='supportSectionTitle'>Agenda una reunión virtual con un especialista</p>
                  <span className='supportSectionDesc'>cal.com/somos-ppol</span>
                  <a className='supportSectionBtn' href='https://cal.com/somos-ppol/30min' target='_blank' rel='noreferrer'>Contactar</a>
                </div>
                <div className='supportSectionImgCont'>
                  <img className='supportSectionImg' src={supportCall} alt='Agendar reunión con el equipo de soporte.'/>
                </div>
              </section>
              <section className='supportSection'>
                <div className='supportSectionTextCont'>
                  <p className='supportSectionTitle'>Escríbenos directo por Whatsapp</p>
                  <span className='supportSectionDesc'>+51 960 525 864</span>
                  <a className='supportSectionBtn' href='https://wa.me/51960525864?text=Me%20interesa%20saber%20más%20sobre%20Ppol' target='_blank' rel='noreferrer'>Contactar</a>
                </div>
                <div className='supportSectionImgCont'>
                  <img className='supportSectionImg' src={supportWsp} alt='Ir al Whatsapp del equipo de soporte.'/>
                </div>
              </section>
              <section className='supportSection'>
                <div className='supportSectionTextCont'>
                  <p className='supportSectionTitle'>Mándanos un mensaje por correo</p>
                  <span className='supportSectionDesc'>somos@ppol.io</span>
                  <a className='supportSectionBtn' href='mailto: somos@ppol.io' target='_blank' rel='noreferrer'>Contactar</a>
                </div>
                <div className='supportSectionImgCont'>
                  <img className='supportSectionImg' src={supportEmail} alt='Mandar un correo al equipo de soporte.'/>
                </div>
              </section>
            </section>
          </div>
        }
      </div>
    )
  
}

export default ProfileSupport