import React, {useState} from 'react'

import { faqData } from './faqData';

const Faq = () => {

      const [openAnswerIndex, setOpenAnswerIndex] = useState(null);

      const toggleAnswerVisibility = (index) => {
        setOpenAnswerIndex((prevIndex) => (prevIndex === index ? null : index));
      };

  return (
    <section className='faq-section-container'>
        <div className='faq-section'>
            <div className='faq-header'>
                <div>
                    <h1>Preguntas</h1>
                    <h1>frecuentes <svg className='faq-title-svg' xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 150 150" fill="none">
                        <path d="M16.1538 150L0 133.846L110.769 23.0769H11.5385V0H150V138.462H126.923V39.2308L16.1538 150Z" fill="#E8542F"/>
                    </svg></h1>
                </div>
                <svg className='faq-header-svg' xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150" fill="none">
                    <path d="M16.1538 150L0 133.846L110.769 23.0769H11.5385V0H150V138.462H126.923V39.2308L16.1538 150Z" fill="#E8542F"/>
                </svg>
            </div>
            <div className='faq-question-container'>
                {faqData.map((faq, index) => (
                    <div className='faq-question-item' key={faq.id} onClick={() => toggleAnswerVisibility(index)}>
                        <div className='faq-question'>
                            <p>{faq.question}</p>
                            <button className={openAnswerIndex === index ? 'faq-btn-rotate' : 'faq-btn-rotate-back'}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="18" viewBox="0 0 33 18" fill="none">
                                    <path d="M32.9979 2.09155C32.9979 2.36605 32.9416 2.63785 32.8322 2.89134C32.7228 3.14484 32.5624 3.37504 32.3604 3.56874L18.0058 17.3877C17.5981 17.7798 17.0454 18 16.4691 18C15.8927 18 15.34 17.7798 14.9323 17.3877L0.577733 3.57078C0.196384 3.17373 -0.0105086 2.65001 0.000410533 2.10939C0.0113297 1.56876 0.239213 1.05319 0.636307 0.670707C1.0334 0.288224 1.56888 0.0685222 2.13054 0.0576406C2.69219 0.0467589 3.23642 0.245541 3.64918 0.612334L16.4691 12.952L29.2889 0.612333C29.593 0.319904 29.9803 0.120789 30.4019 0.0401432C30.8235 -0.0405025 31.2605 0.00094043 31.6576 0.159236C32.0547 0.317532 32.3942 0.585578 32.6331 0.929506C32.8721 1.27344 32.9997 1.67782 33 2.09155L32.9979 2.09155Z" fill="white" />
                                </svg>
                            </button>
                        </div>
                        <div className={`faq-answer ${openAnswerIndex === index ? 'visible' : ''}`}>
                        <p>{faq.answer}</p>
                    </div>
            </div>
                ))}
            </div>
        </div>
    </section>
  )
}

export default Faq