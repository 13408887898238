import React, {useState} from 'react'
import { makeRequest } from '../../axios'

import "./styles.css"
import ppolLogo from "../../images/ppolLogo.png"

function UserCreator() {
    const [usercode, setUsercode] = useState("")
    const [status, setStatus] = useState("")
    const [password, setPassword] = useState("")
    const [userPassword, setUserPassword] = useState("")

    const resetPassword = () => {
        setStatus("")
        const inputPassword = document.getElementById("userGeneratorPassword").value;
        if(inputPassword===process.env.REACT_APP_CHANGE_USER_PW_KEY){
            if(usercode.length !== 8 || isNaN(usercode)){
                window.alert(`El código ${usercode} no tiene 8 dígitos numéricos.`)
            } else {
                makeRequest.get("/resetUserPassword.php", {params: {code: usercode, password: password}})
                .then(() => {
                    setStatus(`Contraseña del usuario ${usercode} actualizada.`)
                })
                .catch(() => {
                    setStatus(`Usuario ${usercode} no existente.`)
                })
            }
        } else {
            window.alert("Esa no es la contraseña pillín vanidón 🤨. La contraseña es adminPPOL2023")
        }
    }

    return (
        <div className="userGenerator">
            <img className="userGeneratorImg" loading="lazy" src={ppolLogo} alt=""/>
            <h1 className="userGeneratorTitle">Cambio de Contraseña</h1>
            <div className="userGeneratorInfo">
                <p className='userGeneratorInfoTitle'>Ingresa el código a cambiar clave.</p>
                <input id="userGeneratorCodes" className="userGeneratorInput" type="text" placeholder='Código' value={usercode} onChange={(e)=>setUsercode(e.target.value)}/>
                <input className='userGeneratorInput' type='password' placeholder='Nueva contraseña' value={password} onChange={(e)=>setPassword(e.target.value)}/>
                <hr style={{width:"100%"}}/>
                <input id="userGeneratorPassword" className="userGeneratorInput" type="password" placeholder='Contraseña para acceso' value={userPassword} onChange={(e)=>setUserPassword(e.target.value)}/>
                <button className='userGeneratorBtn' onClick={resetPassword}>Generar códigos</button>
                {status}
            </div>
        </div>
      )
}

export default UserCreator