import axios from "axios";
import { createContext, useEffect, useState } from "react";

export const AuthContext = createContext();

const PageURL = process.env.REACT_APP_PPOL_URL==="PRODUCTION"?"/backend":process.env.REACT_APP_PPOL_LOCAL_URL

export const AuthContextProvider = ({children}) => {
    const [currentUser, setCurrentUser] = useState(
        JSON.parse(localStorage.getItem("info") || null)
    )
    //console.log(localStorage.getItem("user"))

    const login = async (username, password) => {
        const res = await axios.post(
            `${PageURL}/loginUser.php`,
            username,password,
            {
                withCredentials:true
            }
        )
        setCurrentUser(res.data)
    }

    const register = async (code, username, email, email2, password, name, lastname, birthday, phone, phone2, phone3, company, position) => {
        const res = await axios.post(
            `${PageURL}/postSignup.php`, {
                code,
                username,
                email,
                email2,
                password,
                name,
                lastname,
                birthday,
                phone,
                phone2,
                phone3,
                company,
                position
            }
        )
        setCurrentUser(res.data)
    }

    const registerCode = async (code) => {
        //console.log(code)
        await axios.post(
            //`${PageURL}/signup/code`,
            `${PageURL}/getRegisterCode.php`,
            { code }
        )
    }

    const logout = async () => {
        await axios.post(`${PageURL}/logoutUser.php`)
        setCurrentUser(null);
        //console.log(currentUser)
    }

    const changeUsername = async (password,newPassword,newPasswordConf,userlink,username,name,lastname,email,email2,phone,phone2,phone3,company,position,colorToText,profileContact,profileSendInfo,profileContactText,profileSendInfoText,privateProfile) => {
        const res = await axios.post(
            `${PageURL}/updateUserConfig.php`,
            password,newPassword,newPasswordConf,userlink,username,name,lastname,email,email2,phone,phone2,phone3,company,position,colorToText,profileContact,profileSendInfo,profileContactText,profileSendInfoText,privateProfile
        )
        setCurrentUser({...currentUser, user:res.data.user})
    }

    const changeAdminUsername = async (password,newPassword,newPasswordConf,userlink,username,name,lastname,email,email2,phone,phone2,phone3,company,position,colorToText,profileContact,profileSendInfo,profileContactText,profileSendInfoText) => {
        const res = await axios.post(
            `${PageURL}/updateUserConfig.php`,
            password,newPassword,newPasswordConf,userlink,username,name,lastname,email,email2,phone,phone2,phone3,company,position,colorToText,profileContact,profileSendInfo,profileContactText,profileSendInfoText
        )
    }

    useEffect(()=>{
        //console.log(currentUser)
        if(currentUser){
            localStorage.setItem("info", JSON.stringify(currentUser))
            localStorage.setItem("user", JSON.stringify(currentUser.user))
            localStorage.setItem("userlink", JSON.stringify(currentUser.userlink))
        } else {
            localStorage.clear()
        }
    }, [currentUser])

    return(
        <AuthContext.Provider value={{currentUser, login, logout, registerCode, changeUsername, register, changeAdminUsername}}>
            {children}
        </AuthContext.Provider>
    )
}