import React, { useContext, useEffect, useState, useCallback } from 'react'
import "../Dashboard/dashboard.css"
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { AuthContext } from '../../context/authContext'
import { useQuery } from '@tanstack/react-query'
import { makeRequest } from '../../axios'
import TextareaAutosize from 'react-textarea-autosize';
import {utils, writeFile} from 'xlsx';
import Select from 'react-select'
import Navbar from '../Navbar/Navbar.jsx'
import NavbarMobile from '../Navbar/NavbarMobile.jsx'
import Loading from '../Loading/Loading.jsx';

import { getColor } from './getColor'
import { getCountryName, getFlagEmoji } from '../ProfileMetrics/countries'
import labelProps from './labelProps.json'

import closeCreationBarImg from "../../images/closeCreationBar.svg"

function ProfileContacts() {
    document.title = `Contactos | PPOL`
    const navigate = useNavigate()
    let {currentUser} = useContext(AuthContext);
    const userId = useLocation().pathname.split("/")[1];

    const usersPerPage = 8;
    const [tableData, setTableData] = useState([])
    const [contactInfo, setContactInfo] = useState(<></>)
    const [confirmInfo, setConfirmInfo] = useState(<></>)
    const [dataInfo, setDataInfo] = useState([])
    const [filteredContacts, setFilteredContacts] = useState([])
    const [selectedContacts, setSelectedContacts] = useState([])
    const [searchBarFilter, setSearchBarFilter] = useState("")
    const [dateFilter, setDateFilter] = useState("total")
    const [labelFilter, setLabelFilter] = useState(null)
    const [profileSettings, setProfileSettings] = useState({})
    const [showInfo, setShowInfo] = useState(false)
    
    const selectOptions1 = [
        { value: '', label: '-' },
        { value: 'Prospecto', label: 'Prospecto' },
        { value: 'Proveedor', label: 'Proveedor' },
        { value: 'Alianza', label: 'Alianza' },
        { value: 'Intermediario', label: 'Intermediario' },
        { value: 'Cliente', label: 'Cliente' }
    ]

    const selectOptions2 = [
        { value: 'Pendiente', label: 'Pendiente' },
        { value: 'En pausa', label: 'En pausa' },
        { value: 'Agendado', label: 'Agendado' },
        { value: 'Cerrado', label: 'Cerrado' },
        { value: 'Cancelado', label: 'Cancelado' }
    ]

    const {isLoading, data} = useQuery(['user'], () =>
        makeRequest.get("findUserBasicInfo.php", {params: {user: userId}})
        .then((res) => {
            if(currentUser.userlink===res.data.userlink){
                localStorage.setItem("profileImg", JSON.parse(res.data.profileImages).profile)
                setProfileSettings(JSON.parse(res.data.profileSettings))
                makeRequest.get("getUserContacts.php", {params: {code: res.data.userlink}})
                .then(({data}) => {
                    setDataInfo(data.reverse())
                })
                .catch(()=>{
                    setTableData([])
                })
            } else {
                localStorage.clear()
                navigate("/404")
            }
            return res.data
        })
        .catch(() => {
            localStorage.clear()
            navigate("/404")
        })
    )

    const contactFilter = [{"id":0,"value":null,"color":"#FFFFFF","textColor":"#141414","label":"-"}, ...labelProps.flatMap(item => Object.values(item)[0].filter(obj => obj.value !== null))];
    const timeFilter = [{"value":"month","label":"Este mes"},{"value":"day","label":"Hoy"},{"value":"week","label":"Últimos 7 días"},{"value":"semester","label":"Últimos 6 meses"},{"value":"total","label":"Total"}]

    useEffect(()=>{
        if(dataInfo.length>0){
            const dataSearchFilter = searchBarFilter ?
                dataInfo.filter((contact)=>
                    contact.name.toLowerCase().includes(searchBarFilter.toLowerCase()) || 
                    contact.lastname.toLowerCase().includes(searchBarFilter.toLowerCase()) || 
                    contact.contactMsg.toLowerCase().includes(searchBarFilter.toLowerCase()) || 
                    contact.email.toLowerCase().includes(searchBarFilter.toLowerCase()) || 
                    contact.phone.toLowerCase().includes(searchBarFilter.toLowerCase()) || 
                    (contact.name + ' ' + contact.lastname).toLowerCase().includes(searchBarFilter.toLowerCase())
                ):dataInfo

            const dataLabelFilter = labelFilter?
                dataSearchFilter.filter((contact)=>
                    contact.label===labelFilter || 
                    contact.label2===labelFilter
                ):dataSearchFilter

            const dateNow = new Date()

            let dataDateFilter = dataLabelFilter
            //dateFilter==='total'
            if(dateFilter==='day'){
                dataDateFilter = dataLabelFilter.filter((contact) => {
                    const contactDate = new Date(contact.datetime)
                    return isSameDay(dateNow, contactDate)
                })
            } else if(dateFilter==='week'){
                const oneWeekAgo = new Date(dateNow)
                oneWeekAgo.setDate(oneWeekAgo.getDate() - 7)

                dataDateFilter = dataLabelFilter.filter((contact) => {
                    const contactDate = new Date(contact.datetime)
                    return contactDate >= oneWeekAgo && contactDate <= dateNow
                })
            } else if(dateFilter==='semester'){
                const sixMonthAgo = new Date(dateNow)
                sixMonthAgo.setMonth(sixMonthAgo.getMonth() - 6)

                dataDateFilter = dataLabelFilter.filter((contact) => {
                    const contactDate = new Date(dateNow)
                    return contactDate >= sixMonthAgo && contactDate <= dateNow
                })
            } else if(dateFilter==='month'){
                const monthStartDay = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1)

                dataDateFilter = dataLabelFilter.filter((contact) => {
                    const contactDate = new Date(contact.datetime)
                    return contactDate >= monthStartDay && contactDate <= dateNow
                })
            }
            setFilteredContacts(dataDateFilter)
        } else {
            setFilteredContacts([])
        }
    },[searchBarFilter, dateFilter, labelFilter, dataInfo])

    function isSameDay(date1, date2){
        return  date1.getFullYear() === date2.getFullYear() &&
                date1.getMonth() === date2.getMonth() &&
                date1.getDate() === date2.getDate()
    }
    /*if(dateFilter==='week'){
        const dateBefore = dateNow
        dateBefore.setDate(dateBefore.getDate()-3)
        console.log(dateNow)
        console.log(dateBefore)
        console.log('---------------------------------')
    }*/
    //console.log(new Date().toISOString(navigator.language))
    //console.log(new Date(contact.datetime).toISOString(navigator.language))
    /*if(dateFilter=''){

    }*/
    //console.log(new Date(contact.datetime).toISOString(navigator.language))

    /*useEffect(()=>{
        console.log(selectedContacts)
    },[selectedContacts])*/

    useEffect(()=>{
        let tableInfo = []
        if(filteredContacts.length>0){
            {filteredContacts.map((contact)=>{
                tableInfo.push(
                    <tr className='contactTableRow' key={contact.id}>
                        <td className='contactTableCheckCont'>
                            <input
                                className='contactTableCheck'
                                type='checkbox'
                                onChange={(e) => handleCheckboxChange(e, contact)}
                                checked={selectedContacts.some(selected => selected.id === contact.id)}
                            />
                        </td>
                        <td className='contactTableName contactTableWeb'>{`${contact.name} ${contact.lastname}`}</td>
                        <td className='contactTablePhoneMail'>
                            {(contact.name||contact.lastname)&&<span className='contactTableName contactTablePhone'>{`${contact.name} ${contact.lastname}`}</span>}
                            {contact.email&&<p className='contactTablePhoneMailText'>{contact.email}</p>}
                            {contact.phone&&<p className='contactTablePhoneMailText'>{contact.phone}</p>}
                        </td>
                        <td className='contactTableLabelCont' onClick={()=>showContactInfo(contact)}>
                            {contact.label&&<div className='contactLabel' style={{backgroundColor:getColor("color",contact.label), color:getColor("text",contact.label) }}>{contact.label}</div>}
                            {contact.label2&&<div className='contactLabel' style={{backgroundColor:getColor("color",contact.label2), color:getColor("text",contact.label2) }}>{contact.label2}</div>}
                            {contact.label&&<div className='contactLabelMini' style={{backgroundColor:getColor("color",contact.label), color:getColor("text",contact.label) }}/>}
                            {contact.label2&&<div className='contactLabelMini' style={{backgroundColor:getColor("color",contact.label2), color:getColor("text",contact.label2) }}/>}
                        </td>
                        <td className='contactTableMsg contactTableWeb'>{contact.contactMsg}</td>
                        <td className='contactTableEditBtnCont contactTableTablet'>
                            <button className='contactTableEditBtn contactTableTablet' onClick={()=>showContactInfo(contact)}>Editar</button>
                        </td>
                    </tr>
                )
            })}
            setTableData(tableInfo)
        } else {
            setTableData([])
        }
    },[filteredContacts, selectedContacts])

    const handleCheckboxChange = (e, contact) => {
        if (e.target.checked) {
            setSelectedContacts(prevSelected => [...prevSelected, contact]);
        } else {
            setSelectedContacts(prevSelected => prevSelected.filter(selected => selected.id !== contact.id));
        }
    };

    const selectAllContacts = () => {
        setSelectedContacts(filteredContacts)
    }

    const saveContactChanges = (info) => {
        //MAKE REQUEST A DB CON UPDATE LABEL Y CONTACTMSG USANDO ID
        makeRequest.post("postContactChange.php", {
            "id":info.id,
            "userMsg":info.userMsg,
            "label":info.label,
            "label2":info.label2,
            "label3":info.label3,
            "label4":info.label4,
        })
        const tempData = [...dataInfo]
        tempData.forEach(contact => {
            if(contact.id===info.id){
                contact.label=info.label;
                contact.label2=info.label2;
                contact.label3=info.label3;
                contact.label4=info.label4;
                contact.userMsg=info.userMsg
            }
        })
        setDataInfo(tempData)
        setContactInfo(<></>)
    }

    const confirmDeleteContact = (info) => {
        setConfirmInfo(
            <div className="floatInfoBg">
                <div className='floatInfoMiniCont'>
                    <div className='floatInfoSectionCont'>
                        <div className='floatInfoSectionMain'>
                            <div className='floatInfoSectionFull'>
                                <p className='floatInfoSectionMiniTitle'>¿Eliminar Contacto?</p>
                            </div>
                        </div>
                        <div className='floatInfoSectionMain'>
                            <div className='floatInfoSectionFull'>
                                <p className='floatInfoSectionMiniText'>Si eliminas este contacto, no podrás acceder a él luego. ¿Estás seguro de eliminarlo?</p>
                            </div>
                        </div>
                        <div className='floatInfoSectionMain'>
                            <div className='floatInfoSectionFull'>
                                <button className="floatInfoSectionSplittedBtn floatInfoDeleteContactBtn" onClick={()=>deleteContact(info)}>Eliminar</button>
                            </div>
                            <div className='floatInfoSectionFull'>
                                <button className="floatInfoSectionSplittedBtn floatInfoDefaultBtn" onClick={()=>setConfirmInfo(<></>)}>Cancelar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const deleteContact = (info) => {
        //MAKE REQUEST A DB CON UPDATE ISDELETED TRUE USANDO ID
        makeRequest.post("postContactDelete.php", {
            "id":info.id
        })
        const tempData = [...dataInfo]
        const newData = tempData.filter(contact => contact.id!==info.id)
        setDataInfo(newData)
        setConfirmInfo(<></>)
        setContactInfo(<></>)
    }

    const showContactInfo = (info) => {
        setContactInfo(
            <div className='floatInfoBg'>
                <div className='floatInfoCont'>
                    <div className='floatInfoSectionCont'>
                        <div className='floatInfoSectionMain'>
                            <div className='floatInfoSectionSplit'>
                                <p className='floatInfoSectionTitle'>Contacto</p>
                                <button className='floatInfoCloseBtn' onClick={()=>{setContactInfo(<></>)}}>
                                    <img className='floatInfoCloseBtnImg' src={closeCreationBarImg} alt='Cerrar'/>
                                </button>
                            </div>
                        </div>
                        <div className='floatInfoSectionMain'>
                            <div className='floatInfoSectionFull'>
                                <hr className='floatInfoSectionSeparation'/>
                            </div>
                        </div>
                        <div className='floatInfoSectionMain'>
                            <div className='floatInfoSectionFull'>
                                <span className='floatInfoSectionSubtitle'>Nombre</span>
                            </div>
                            <div className='floatInfoSectionFull'>
                                <span className='floatInfoSectionDesc'>{info.name} {info.lastname}</span>
                            </div>
                        </div>
                        <div className='floatInfoSectionMain'>
                            <div className='floatInfoSectionFull'>
                                <span className='floatInfoSectionSubtitle'>Cargo / Empresa</span>
                            </div>
                            <div className='floatInfoSectionFull'>
                                <span className='floatInfoSectionDesc'>{info.companyPosition&&info.company?`${info.companyPosition} en ${info.company}`:info.companyPosition||info.company?info.companyPosition||info.company:"-"}</span>
                            </div>
                        </div>
                        <div className='floatInfoSectionMain'>
                            <div className='floatInfoSectionFull'>
                                <span className='floatInfoSectionSubtitle'>Celular</span>
                            </div>
                            <div className='floatInfoSectionFull'>
                                {info.phone?<a className='floatInfoSectionDesc' href={`tel:${info.phone}`}>{info.phone}</a>:<span className='floatInfoSectionDesc'>-</span>}
                            </div>
                        </div>
                        <div className='floatInfoSectionMain'>
                            <div className='floatInfoSectionFull'>
                                <span className='floatInfoSectionSubtitle'>Correo</span>
                            </div>
                            <div className='floatInfoSectionFull'>
                                {info.email?<a className='floatInfoSectionDesc' href={`mailto:${info.email}`}>{info.email}</a>:<span className='floatInfoSectionDesc'>-</span>}
                            </div>
                        </div>
                        <div className='floatInfoSectionMain'>
                            <div className='floatInfoSectionFull'>
                                <span className='floatInfoSectionSubtitle'>Ubicación</span>
                            </div>
                            <div className='floatInfoSectionFull'>
                                <span className='floatInfoSectionDesc'>{getFlagEmoji(info.country)} {info.city}, {getCountryName(info.country)}</span>
                            </div>
                        </div>
                        <div className='floatInfoSectionMain'>
                            <div className='floatInfoSectionFull'>
                                <span className='floatInfoSectionSubtitle'>Conexión</span>
                            </div>
                            <div className='floatInfoSectionFull'>
                                <span className='floatInfoSectionDesc'>{new Date(info.datetime).toLocaleString(navigator.language)}</span>
                            </div>
                        </div>
                        <div className='floatInfoSectionMain'>
                            <div className='floatInfoSectionFull'>
                                <span className='floatInfoSectionSubtitle'>Mensaje</span>
                            </div>
                            <div className='floatInfoSectionFull'>
                                <span className='floatInfoSectionDesc'>{info.contactMsg?info.contactMsg:"-"}</span>
                            </div>
                        </div>
                        {profileSettings.canDeleteContacts || profileSettings.canDownloadContacts?
                            <div className='floatInfoSectionMain'>
                                <div className='floatInfoSectionFull'>
                                    <span className='floatInfoSectionSubtitle'>Opciones</span>
                                </div>
                                <div className='floatInfoSectionSplit'>
                                    {profileSettings.canDeleteContacts?
                                        <button className='floatInfoSectionSplittedBtn floatInfoDeleteContactBtn' onClick={()=>confirmDeleteContact(info)}>Eliminar contacto</button>
                                        :
                                        <></>
                                    }
                                    {profileSettings.canDownloadContacts?
                                        <button className='floatInfoSectionSplittedBtn floatInfoDownloadContactBtn' onClick={()=>createVCard(info)}>Descargar contacto</button>
                                        :
                                        <></>
                                    }
                                </div>
                            </div>
                            :
                            <></>}
                        <div className='floatInfoSectionMain'>
                            <div className='floatInfoSectionFull'>
                                <span className='floatInfoSectionSubtitle'>Etiquetas</span>
                            </div>
                            <div className='floatInfoSectionSplit'>
                                <div className='floatInfoSectionLabel'/>
                                <div className='floatInfoSectionLabel'/>
                            </div>
                            <div className='floatInfoSectionSplit'>
                                <Select
                                    defaultValue={selectOptions1.find(option=>option.value===info.label)}
                                    className='floatInfoSelect'
                                    isSearchable={false}
                                    isDisabled={!profileSettings.canEditContacts}
                                    onChange={(e)=>{
                                        info.label = e.value;
                                        showContactInfo(info)
                                    }}
                                    options={selectOptions1}
                                    styles={{
                                        control: (baseStyles) => ({
                                            ...baseStyles,
                                            borderColor: "#D9D9D9",
                                            margin: 0,
                                            height: "100%",
                                            borderRadius: "9px"
                                        }),
                                        option: (baseStyles, state) => ({
                                            ...baseStyles,
                                            backgroundColor: state.isFocused? "#E9E9E9":"#FFFFFF",
                                            color: "#8D8D8D",
                                            height: "42px",
                                            display:"flex",
                                            alignItems:"center",
                                            width: "100%"
                                        })
                                    }}
                                />
                                <Select
                                    defaultValue={selectOptions2.find(option=>option.value===info.label2)}
                                    className='floatInfoSelect'
                                    isSearchable={false}
                                    isDisabled={!profileSettings.canEditContacts}
                                    onChange={(e)=>{
                                        info.label2 = e.value;
                                        showContactInfo(info)
                                    }}
                                    options={selectOptions2}
                                    styles={{
                                        control: (baseStyles) => ({
                                            ...baseStyles,
                                            borderColor: "#D9D9D9",
                                            margin: 0,
                                            height: "100%",
                                            borderRadius: "9px"
                                        }),
                                        option: (baseStyles, state) => ({
                                            ...baseStyles,
                                            backgroundColor: state.isFocused? "#E9E9E9":"#FFFFFF",
                                            color: "#8D8D8D",
                                            height: "42px",
                                            display:"flex",
                                            alignItems:"center",
                                            width: "100%"
                                        })
                                    }}
                                />
                                {/*<select className='floatInfoSectionSplittedBtn floatInfoDefaultBtn' value={info.label}
                                    onChange={(e)=>{
                                        info.label = e.target.value;
                                        showContactInfo(info)
                                    }}
                                >
                                    <option className="floatInfoSectionOption" value="">-</option>
                                    <option className="floatInfoSectionOption" value="Prospecto">Prospecto</option>
                                    <option className="floatInfoSectionOption" value="Proveedor">Proveedor</option>
                                    <option className="floatInfoSectionOption" value="Alianza">Alianza</option>
                                    <option className="floatInfoSectionOption" value="Intermediario">Intermediario</option>
                                    <option className="floatInfoSectionOption" value="Cliente">Cliente</option>
                                </select>
                                <select className='floatInfoSectionSplittedBtn floatInfoDefaultBtn' value={info.label2}
                                    onChange={(e)=>{
                                        info.label2 = e.target.value;
                                        showContactInfo(info)
                                    }}
                                >
                                    <option value="Pendiente">Pendiente</option>
                                    <option value="En pausa">En pausa</option>
                                    <option value="Agendado">Agendado</option>
                                    <option value="Cerrado">Cerrado</option>
                                    <option value="Cancelado">Cancelado</option>
                                </select>*/}
                            </div>
                        </div>
                        <div className='floatInfoSectionMain'>
                            <div className='floatInfoSectionSplit'>
                                <span className='floatInfoSectionSubtitle'>Notas</span>
                                <div className='floatInfoSectionCounter' style={{color:info.userMsg?.length>=350?"#e8542f":"#5A5A5A"}}>{info.userMsg?info.userMsg.length:0}/350</div>
                            </div>
                            <div className='floatInfoSectionFull'>
                                <TextareaAutosize className='floatInfoSectionTextarea' spellCheck={false} placeholder='Nota' value={info.userMsg || ""} maxLength={350} onChange={e=>{info.userMsg=e.target.value;showContactInfo(info)}} readOnly={!profileSettings.canEditContacts}></TextareaAutosize>
                            </div>
                        </div>
                        {profileSettings.canEditContacts?
                            <div className='floatInfoSectionMain'>
                                <div className='floatInfoSectionSplit'>
                                    <button className='floatInfoSectionSplittedBtn floatInfoCancelChanges' onClick={()=>{setContactInfo(<></>)}}>Cancelar</button>
                                    <button className='floatInfoSectionSplittedBtn floatInfoSaveChanges' onClick={()=>saveContactChanges(info)}>Guardar</button>
                                </div>
                            </div>:
                            <></>
                        }
                    </div>
                </div>
            </div>
        )
    }

    const createVCard = (info) => {
        const vcfData = `BEGIN:VCARD
VERSION:3.0
FN:${info.name} ${info.lastname}
TEL:${info.phone}
EMAIL:${info.email}
TITLE:${info.companyPosition}
ORG:${info.company}
END:VCARD`;
    
        const blob = new Blob([vcfData], {type:"text/vcard"});
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `${info.name} ${info.lastname}.vcf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url)
      }

    const downloadXlsx = () => {
        const arrayDataInfo = []
        selectedContacts.forEach((row) => {
            const dataRow = {
                Nombre:row.name,
                Apellido:row.lastname,
                Teléfono:row.phone,
                Correo:row.email,
                Compañía:row.company,
                Puesto:row.companyPosition,
                Ciudad:row.city,
                País:row.country,
                Fecha:row.datetime,
                Etiqueta:row.label,
                Etiqueta2:row.label2,
                Mensaje:row.contactMsg,
                Nota:row.userMsg
            }
            arrayDataInfo.push(dataRow)
        })
        const ws = utils.json_to_sheet(arrayDataInfo);
        const wb = utils.book_new()
        utils.book_append_sheet(wb, ws, 'Contactos')
        writeFile(wb, `Contactos de ${data.username}.xlsx`)
    }

    return (
        <div className="main">
                <Navbar activeBtn='contacts'/>
                {isLoading?
                    <Loading/>
                    :
                    <div className="configuration">
                        <NavbarMobile activeBtn={'contacts'}/>
                        <section className='main-title-container'>
                            <h1 className='main-title'>Personas con las que conectaste</h1>
                        </section>
                        <section className='contactSectionCont'>
                            <section className='contactFilterSection'>
                                <div className='contactSearchFilterCont'>
                                    <div className='contactSearchBarCont'>
                                        <input className='contactSearchBar' type="text" placeholder='Buscar' value={searchBarFilter} onChange={(e)=>setSearchBarFilter(e.target.value)}/>
                                        <svg className='contactSearchBarIcon' width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16.625 16.625L13.1812 13.1812M15.0417 8.70833C15.0417 12.2061 12.2061 15.0417 8.70833 15.0417C5.21053 15.0417 2.375 12.2061 2.375 8.70833C2.375 5.21053 5.21053 2.375 8.70833 2.375C12.2061 2.375 15.0417 5.21053 15.0417 8.70833Z" stroke="#141414" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </div>
                                    <div className='contactFilterCont'>
                                        <Select
                                            className='contactFilterSelect contactFilterSelectBig'
                                            defaultValue={timeFilter[4]}
                                            options={timeFilter}
                                            styles={{
                                                control: (baseStyles) => ({...baseStyles, height:"100%"}),
                                                option: (baseStyles, state) => ({...baseStyles, color:state.isSelected?"#141414":"#8D8D8D"})
                                            }}
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary:'transparent',
                                                    primary25:"#F5F5F5"
                                                }
                                            })}
                                            onChange={(e)=>setDateFilter(e.value)}
                                        />
                                        <Select
                                            className='contactFilterSelect contactFilterSelectSmall'
                                            defaultValue={contactFilter[0]}
                                            options={contactFilter}
                                            styles={{
                                                control: (baseStyles) => ({...baseStyles, height:"100%"}),
                                                option: (baseStyles, state) => ({...baseStyles, color:state.isSelected?"#141414":"#8D8D8D"})
                                            }}
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary:'transparent',
                                                    primary25:"#F5F5F5"
                                                }
                                            })}
                                            onChange={(e)=>setLabelFilter(e.value)}
                                        />
                                    </div>
                                </div>
                                <div className='contactBtnCont'>
                                    {selectedContacts.length!==filteredContacts.length &&
                                        <button className='contactBtnWhite' onClick={selectAllContacts}>Seleccionar todo</button>
                                    }
                                    {selectedContacts.length>0 && profileSettings.canExportContacts &&
                                        <button className='contactBtnOrange' onClick={downloadXlsx}>
                                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M4.15596 5.65506C4.80036 3.30788 6.94831 1.5835 9.50033 1.5835C12.0523 1.5835 14.2003 3.30788 14.8447 5.65506C16.7727 6.10361 18.2087 7.83172 18.2087 9.896C18.2087 11.4064 17.4389 12.7367 16.274 13.5165C15.9107 13.7597 15.419 13.6623 15.1758 13.299C14.9326 12.9356 15.03 12.4439 15.3933 12.2007C16.1379 11.7023 16.6253 10.8559 16.6253 9.896C16.6253 8.45341 15.5223 7.26753 14.1142 7.13712C13.7555 7.1039 13.4645 6.83271 13.406 6.47725C13.0973 4.59949 11.4654 3.16683 9.50033 3.16683C7.53529 3.16683 5.90333 4.59949 5.59464 6.47725C5.5362 6.83271 5.24516 7.1039 4.88646 7.13712C3.47834 7.26753 2.37533 8.45341 2.37533 9.896C2.37533 10.8559 2.8628 11.7023 3.60736 12.2007C3.9707 12.4439 4.06809 12.9356 3.82487 13.299C3.58166 13.6623 3.08996 13.7597 2.72662 13.5165C1.56171 12.7367 0.791992 11.4064 0.791992 9.896C0.791992 7.83172 2.228 6.1036 4.15596 5.65506ZM8.70866 11.4114L6.89345 13.2266C6.58429 13.5358 6.08303 13.5358 5.77387 13.2266C5.4647 12.9175 5.4647 12.4162 5.77387 12.107L8.94053 8.94037C9.2497 8.6312 9.75095 8.6312 10.0601 8.94037L13.2268 12.107C13.536 12.4162 13.536 12.9175 13.2268 13.2266C12.9176 13.5358 12.4164 13.5358 12.1072 13.2266L10.292 11.4114V16.6252C10.292 17.0624 9.93755 17.4168 9.50033 17.4168C9.0631 17.4168 8.70866 17.0624 8.70866 16.6252V11.4114Z" fill="white"/>
                                            </svg>
                                            <span>Exportar</span>
                                        </button>
                                    }
                                </div>
                            </section>
                            <p className='contactFilterFound'>{filteredContacts.length} contacto{filteredContacts.length>1?'s':''} encontrado{filteredContacts.length>1?'s':''}</p>
                            <section className='contactTableContainer'>
                                <table className='contactTable'>
                                    <thead className='contactTableHeader'>
                                        <tr>
                                            <th/>
                                            <th className='contactTableWeb'>Nombre</th>
                                            <th>{window.innerWidth>500?"Correo y celular":"Datos de contacto"}</th>
                                            <th>Etiqueta</th>
                                            <th className='contactTableWeb'>Mensaje</th>
                                            <th className='contactTableTablet'/>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData}
                                    </tbody>
                                </table>
                                <div className='contactTablePaginationCont'>
                                    {/*Agregar if si hay anterior*/}
                                    <button className='contactTablePagBtn'>Anterior</button>
                                    {/*Agregar if sobre cuantas paginas son y en cual se encuentra*/}
                                    <span className='contactTablePagText'>Página 1 de 1</span>
                                    {/*Agregar if si hay siguiente*/}
                                    <button className='contactTablePagBtn'>Siguiente</button>
                                </div>
                            </section>
                        </section>



                        {/*<div className='contactTableCont'>
                            
                            {tableData.length>0?
                                <table className="contactsTable">
                                    <tbody>
                                        <tr>
                                            {profileSettings.canExportContacts?
                                                <button className="contactsDownloadBtn" onClick={()=>downloadXlsx()}>Exportar contactos</button>
                                                :
                                                <></>
                                            }
                                        </tr>
                                        <tr className='contactTableTitleRow'>
                                            {window.innerWidth>900?<th className="contactTableTitleText">NOMBRE</th>:null}
                                            <th className="contactTableTitleText">DATOS DE CONTACTO</th>
                                            <th className="contactTableTitleText">ETIQUETA</th>
                                            {window.innerWidth>900?<th className="contactTableTitleText">MENSAJE</th>:null}
                                            {window.innerWidth>900?<th className="contactTableTitleText"></th>:null}
                                        </tr>
                                        {tableData}
                                    </tbody>
                                </table>
                                :
                                <div className='metricContainerError'>
                                    <img style={{filter:"invert(1)"}} src={ppolLogo} alt='Ppol'/>
                                    <p>No hay datos</p>
                                </div>
                            }
                                
                            
                        </div>*/}
                    </div>
                }
                {contactInfo}
                {confirmInfo}
            </div>
        )
    
}

export default ProfileContacts