import React, {useState} from 'react'
import { makeRequest } from '../../axios'
import { dotSpinner, ripples } from 'ldrs';

import "./styles.css"
import ppolLogo from "../../images/ppolLogo.png"
import profileDefaultImg from "../../images/profileDefaultImg.png"
import bannerDefaultImg from "../../images/bannerDefaultImg.png"
import verifiedIcon from "../../images/verifiedIcon.svg"

function UserCreator() {
    const [usercode, setUsercode] = useState("")
    const [status, setStatus] = useState("")
    const [userInfo, setUserInfo] = useState(null)
    const [loading, setLoading] = useState(false)

    dotSpinner.register()
    ripples.register()

    const resetPassword = () => {
        setStatus("")
        setUserInfo(null)
        setLoading(true)
        if(usercode.length !== 8 || isNaN(usercode)){
            makeRequest.get("/findUserBasicInfo.php", {params: {user: usercode}})
            .then(({data})=>{
                setLoading(false)
                setUserInfo(data)
            })
            .catch(() => {
                setLoading(false)
                setStatus("Código/Nombre de Usuario inválido.")
            })
        } else {
            makeRequest.get("/getCardCounter.php", {params: {userlink: usercode}})
            .then((res) => {
                if(res.data.username){
                    makeRequest.get("/findUserBasicInfo.php", {params: {user: res.data.username}})
                    .then(({data})=>{
                        setLoading(false)
                        setUserInfo(data)
                    })
                } else {
                    setLoading(false)
                    setStatus("Usuario aún no registrado.")
                }
            })
            .catch(() => {
                setLoading(false)
                setStatus("Código/Nombre de Usuario inválido.")
            })
        }
    }

    return (
        <div className="userGenerator">
            <img className="userGeneratorImg" loading="lazy" src={ppolLogo} alt=""/>
            <h1 className="userGeneratorTitle">Consulta de Usuario</h1>
            <div className="userGeneratorInfo">
                <p className='userGeneratorInfoTitle'>Ingresa el código o nombre de usuario.</p>
                <input id="userGeneratorCodes" className="userGeneratorInput" type="text" placeholder='Código/Nombre de usuario' maxLength="50" value={usercode} onChange={(e)=>setUsercode(e.target.value)}/>
                <button className='userGeneratorBtn' onClick={resetPassword}>Consultar</button>
            </div>
            <div style={{marginTop:"50px"}}>
                {status?
                    <div className='utilities-profile-loadinginfo'>
                        <p className='userGeneratorInfoTitle'>{status}</p>
                    </div>
                    :
                    <></>
                }
                {userInfo?
                    <div className='utilities-profile-info'>
                        <img className='utilities-profile-bannerImg' alt='Foto de portada' src={JSON.parse(userInfo.profileImages).banner?JSON.parse(userInfo.profileImages).banner.includes("storage.")?JSON.parse(userInfo.profileImages).banner:`https://ppol.io/media/${userInfo.userlink}/${JSON.parse(userInfo.profileImages).banner}`:bannerDefaultImg}/>
                        <img className="utilities-profile-photoImg" alt="Foto de perfil" src={JSON.parse(userInfo.profileImages).profile?JSON.parse(userInfo.profileImages).profile.includes("storage.")?JSON.parse(userInfo.profileImages).profile:`https://ppol.io/media/${userInfo.userlink}/${JSON.parse(userInfo.profileImages).profile}`:profileDefaultImg}/>
                        {userInfo.isVerified==="1"?
                            <img className="utilities-profile-verifiedIcon" loading="lazy" src={verifiedIcon} alt="✓"/>
                            :
                            <></>
                        }
                        <div className='utilities-profile-title'>
                            <span className='utilities-profile-username'>{userInfo.username}</span>
                            <span className='utilities-profile-code'>{userInfo.userlink}</span>
                            <a className='utilities-profile-button' href={`https://ppol.io/${userInfo.username}`} target="_blank" rel='noreferrer'>Ver perfil</a>
                        </div>
                    </div>
                    :
                    <></>
                }
                {loading?
                    <div className='utilities-profile-loadinginfo'>
                        <l-dot-spinner size="70" speed="1.0" color="white"/>
                    </div>
                    :
                    <></>
                }
            </div>
        </div>
      )
}

export default UserCreator