import featuresVideo1 from './b-01.mp4'
import featuresVideo2 from './b-02.mp4'
import featuresVideo3 from './b-03.mp4'

import featuresVideo4 from './b-08.mp4'
import featuresVideo5 from './b-05.mp4'
import featuresVideo6 from './b-07.mp4'

export const featuresData = [
    {
        frontVideo: featuresVideo1,
        backVideo: featuresVideo4,
        frontVideoMp4: "https://storage.googleapis.com/ppol-storage/15021998mynL4oBxfzZW8SJC0M1v4RzHpx9Q7bxPMasmIwsogBGtGYUL26.mp4",
        backVideoMp4: "https://storage.googleapis.com/ppol-storage/15021998mXmrfK1O44r3DCzEOyLpynBrGH9SMEfmgrQhAUOgwSfpLNdXF3.mp4",
        frontText: "Transmite tu identidad con una sola tarjeta",
        backText: "Muestra tu información de manera impactante"
    },
    {
        frontVideo: featuresVideo2,
        backVideo: featuresVideo5,
        frontVideoMp4: "https://storage.googleapis.com/ppol-storage/15021998jR2io6RaCnRnVhfCtFeaPh9ottN0LYl87pLiQO3uz1Mr2ZpJCs.mp4",
        backVideoMp4: "https://storage.googleapis.com/ppol-storage/15021998chURhhD4u4yAObnU2XGUO2ksp3SdfTeiPCGHKuQXxRpM9zyYsj.mp4",
        frontText: "Intercambia información de forma rápida y sencilla",
        backText: "Integra toda tu información en un único espacio"
    },
    {
        frontVideo: featuresVideo3,
        backVideo: featuresVideo6,
        frontVideoMp4: "https://storage.googleapis.com/ppol-storage/15021998N4zBmlyTOdCNDwZjTDeJ7DyBO5sSMq0322MIkTLjLiSueToRwh.mp4",
        backVideoMp4: "https://storage.googleapis.com/ppol-storage/150219989vfTAKjWYcFMEOsV6SbSys6rvpieaAyMvfbkkdqj8mirnLPxMN.mp4",
        frontText: "Lleva el registro de las visitas a tu perfil",
        backText: "Actualiza tus datos de forma inmediata"
    }
];