import { dotSpinner } from 'ldrs';

import './loading.css'

function Loading() {
    dotSpinner.register()
    return(
        <div className='loadingContainer'>
            <svg width="108.1" height="44" viewBox="0 0 108.1 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g>
                    <g>
                        <path d="M24,22c0,5.2-4.3,9.5-9.7,9.5c-1.7,0-3.3-0.4-4.8-1.3c-0.1-0.1-0.2,0-0.2,0.1v6.3c0,0.2-0.1,0.3-0.3,0.3H3.9
                            c-0.2,0-0.3-0.1-0.3-0.3V13.1c0-0.2,0.1-0.3,0.3-0.3H9c0.2,0,0.3,0.1,0.3,0.3v0.6c0,0.1,0.1,0.2,0.2,0.1c1.5-0.8,3.1-1.3,4.8-1.3
                            C19.6,12.5,24,16.8,24,22z M17.9,22c0-2.4-2-4.4-4.4-4.4s-4.1,2-4.1,4.4s1.7,4.4,4.1,4.4S17.9,24.4,17.9,22z" fill='#141414'/>
                        <path d="M46.5,22c0,5.2-4.3,9.5-9.7,9.5c-1.7,0-3.3-0.4-4.8-1.3c-0.1-0.1-0.2,0-0.2,0.1v6.3c0,0.2-0.1,0.3-0.3,0.3h-5.1
                            c-0.2,0-0.3-0.1-0.3-0.3V13.1c0-0.2,0.1-0.3,0.3-0.3h5.1c0.2,0,0.3,0.1,0.3,0.3v0.6c0,0.1,0.1,0.2,0.2,0.1
                            c1.5-0.8,3.1-1.3,4.8-1.3C42.1,12.5,46.5,16.8,46.5,22z M40.4,22c0-2.4-2-4.4-4.4-4.4s-4.1,2-4.1,4.4s1.7,4.4,4.1,4.4
                            S40.4,24.4,40.4,22z" fill='#141414'/>
                        <path d="M66.7,22c0,5.2-4.3,9.5-9.7,9.5s-9.7-4.3-9.7-9.5s4.3-9.5,9.7-9.5S66.7,16.8,66.7,22z M61.3,22c0-2.3-1.9-4.1-4.2-4.1
                            s-4.2,1.9-4.2,4.1s1.9,4.1,4.2,4.1C59.4,26.1,61.3,24.3,61.3,22z" fill='#141414'/>
                        <path d="M73.7,7.4v23.3c0,0.2-0.1,0.3-0.3,0.3H68c-0.2,0-0.3-0.1-0.3-0.3V7.4c0-0.2,0.1-0.3,0.3-0.3h5.4
                            C73.6,7.1,73.7,7.2,73.7,7.4z" fill='#141414'/>
                    </g>
                    <g>
                        <path d="M91.8,7.9H81.5c-0.1,0-0.2,0.1-0.1,0.2l11.5,12.2h11.4c0.1,0,0.2-0.1,0.1-0.2L93.7,8.7C93.2,8.2,92.5,7.9,91.8,7.9z" fill='#141414'/>
                        <path d="M92.9,20.3H81.5c-0.1,0-0.2,0.1-0.1,0.2L92.1,32c0.5,0.5,1.2,0.8,1.9,0.8h10.3c0.1,0,0.2-0.1,0.1-0.2L92.9,20.3z" fill='#141414'/>
                    </g>
                </g>
            </svg>
            <l-dot-spinner size="70" speed="1.0" color="#141414"></l-dot-spinner>
        </div>
    )
}

export default Loading