import React from 'react'
import "../Landing/landing.css"
import "./addCards.css"

import ppolLogo from "../../images/ppolLogo.png"
import facebookLogo from "../../images/facebookLogo.png"
import instagramLogo from "../../images/instagramLogo.png"
import linkedinLogo from "../../images/linkedinLogo.png"
import tiktokLogo from "../../images/tiktokLogo.png"
import youtubeLogo from "../../images/youtubeLogo.png"

function PaymentRejected() {
    const closeNavbar = () => {
        document.querySelector("#menu-toggle").checked = false
    }

    return (
        <div>
            <nav className="navbar">
                <div className="navbarMini">
                    <a href="/" onClick={closeNavbar}>
                        <img className="ppolLogoMini" loading="lazy" src={ppolLogo} alt="Ppol Logo"/>
                    </a>
                    <input id="menu-toggle" type="checkbox"/>
                    <label className="menu-button-container" htmlFor="menu-toggle">
                        <div className="menu-button"></div>
                    </label>
                    <ul className="menu"> 
                        <li className="ppolLogoNavLi">
                            <a href="/login">
                                <button id="navBtnMini2" className="navBtnMini">
                                    Iniciar sesión
                                </button>
                            </a>
                            <a href='/buy/ppol' rel='noreferrer'>
                                <button id="navBtnMini2Alt" className="navBtnMini">
                                    Comprar
                                </button>
                            </a>
                        </li>
                        <li id="navCopyright">
                            ®2023 Ppol. Tecnología patentada
                        </li>
                    </ul>
                </div>

                <ul className="navLeft">
                    <li className="ppolLogoContainer">
                        <a href="/">
                            <img className="ppolLogo2" loading="lazy" src={ppolLogo} alt="Ppol Logo"/>
                        </a>
                    </li>
                </ul>
                <ul className="navRight">
                    <li className="ppolLogoNavLi">
                        <a href="/login">
                            <button id="btnCreate" className="btnNav">Iniciar sesión</button>
                        </a>
                    </li>
                    <li className="ppolLogoNavLi">
                        <a href='/buy/ppol' rel='noreferrer'>
                            <button id="btnCreateAlt" className="btnNav">Comprar</button>
                        </a>
                    </li>
                </ul>
            </nav>

            <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                <div className='paymentStatus'>
                    <h2 className='paymentStatusTitle'>Pago rechazado</h2>
                    <p className='paymentStatusText'>Lamentamos que esto haya pasado, pero tu pago ha sido rechazado.</p>
                    <p className='paymentStatusText'>Puedes intentarlo nuevamente en la brevedad posible.</p>
                    <p className='paymentStatusText'>Si crees que se trata de un error puedes contactarnos mediante <a style={{textDecoration:"underline", color:"#E8542F"}} href='https://wa.me/51960525864'>whatsapp</a> o <a style={{textDecoration:"underline", color:"#E8542F"}} href='mailto:somos@ppol.io'>correo</a>.</p>
                    <a className="paymentStatusLink" style={{marginBottom:"90px"}} href="/">Ir al inicio</a>
                </div>
            </div>
            
            <footer className="foot">
                <p className="footP1">Conecta con nosotros</p>
                <div className="footImgContainer">
                    <a href="https://www.facebook.com/profile.php?id=100089400941802" target="_blank" rel="noreferrer">
                        <img loading="lazy" src={facebookLogo} alt="Facebook Ppol"/>
                    </a>
                    <a href="https://www.instagram.com/somosppol/" target="_blank" rel="noreferrer">
                        <img loading="lazy" src={instagramLogo} alt="Instagram Ppol"/>
                    </a>
                    <a href="https://www.linkedin.com/company/somosppol/" target="_blank" rel="noreferrer">
                        <img loading="lazy" src={linkedinLogo} alt="LinkedIn Ppol"/>
                    </a>
                    <a href="https://www.tiktok.com/@somosppol" target="_blank" rel="noreferrer">
                        <img loading="lazy" src={tiktokLogo} alt="TikTok Ppol"/>
                    </a>
                    <a href="https://www.youtube.com/@somosppol" target="_blank" rel="noreferrer">
                        <img loading="lazy" src={youtubeLogo} alt="Youtube Ppol"/>
                    </a>
                </div>
                <ul className="footLiContainer">
                    <a href="#custom-Section">
                        <li>Producto</li>
                    </a>
                    {/*<a href="#communitySection">
                        <li>Comunidad</li>
                    </a>*/}
                    <a href="#ppolMeUs">
                        <li>Públicos</li>
                    </a>
                    <a href="#faqSection">
                        <li>FAQ</li>
                    </a>
                    <a href="#suscriptionSection">
                        <li>Suscríbete</li>
                    </a>
                </ul>
                <p className="footP2">®2023 Ppol. Tecnología patentada</p>
            </footer>
        </div>
    )
}

export default PaymentRejected