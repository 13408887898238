import React from 'react'
import GridLayout from "react-grid-layout"
import { useState, useEffect, useContext } from 'react'
import { Swiper, SwiperSlide } from "swiper/react"
import { FreeMode } from 'swiper/modules';
import { useQuery } from '@tanstack/react-query'
import { makeRequest } from '../../axios'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthContext } from '../../context/authContext'
import TextareaAutosize from 'react-textarea-autosize';
import { dotSpinner, ripples } from 'ldrs';
import Loading from '../Loading/Loading.jsx';
//import mapboxgl from 'mapbox-gl';
import MapboxStatic from './MapboxStatic';
//import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import "./dashboard.css"
import "../../../node_modules/react-grid-layout/css/styles.css"
import "../../../node_modules/react-resizable/css/styles.css"
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'mapbox-gl/dist/mapbox-gl.css'
import { languageProfile } from "../../languageProfile"

//import loadingGif from "../../images/loadingGif.gif"
import profileBannerGradient from "../../images/profileBannerGradient.png"

import linkIconAmazon from "../../images/socialIcons/linkIconAmazon.svg"
import linkIconBeacons from "../../images/socialIcons/linkIconBeacons.svg"
import linkIconBehance from "../../images/socialIcons/linkIconBehance.svg"
import linkIconBuymeacoffee from "../../images/socialIcons/linkIconBuymeacoffee.svg"
import linkIconDeviantart from "../../images/socialIcons/linkIconDeviantart.svg"
import linkIconDiscord from "../../images/socialIcons/linkIconDiscord.svg"
import linkIconDribbble from "../../images/socialIcons/linkIconDribbble.svg"
import linkIconEmail from "../../images/socialIcons/linkIconEmail.svg"
import linkIconFacebook from "../../images/socialIcons/linkIconFacebook.svg"
import linkIconGithub from "../../images/socialIcons/linkIconGithub.svg"
import linkIconInstagram from "../../images/socialIcons/linkIconInstagram.svg"
import linkIconLinkedin from "../../images/socialIcons/linkIconLinkedin.svg"
import linkIconLinktree from "../../images/socialIcons/linkIconLinktree.svg"
import linkIconOnlyfans from "../../images/socialIcons/linkIconOnlyfans.svg"
import linkIconPatreon from "../../images/socialIcons/linkIconPatreon.svg"
import linkIconPinterest from "../../images/socialIcons/linkIconPinterest.svg"
import linkIconReddit from "../../images/socialIcons/linkIconReddit.svg"
import linkIconSnapchat from "../../images/socialIcons/linkIconSnapchat.svg"
import linkIconSoundcloud from "../../images/socialIcons/linkIconSoundcloud.svg"
import linkIconSpotify from "../../images/socialIcons/linkIconSpotify.svg"
import linkIconSteam from "../../images/socialIcons/linkIconSteam.svg"
import linkIconTelegram from "../../images/socialIcons/linkIconTelegram.svg"
import linkIconTiktok from "../../images/socialIcons/linkIconTiktok.svg"
import linkIconTwitch from "../../images/socialIcons/linkIconTwitch.svg"
import linkIconTwitter from "../../images/socialIcons/linkIconTwitter.svg"
import linkIconVimeo from "../../images/socialIcons/linkIconVimeo.svg"
import linkIconWhatsapp from "../../images/socialIcons/linkIconWhatsapp.svg"
import linkIconYoutube from "../../images/socialIcons/linkIconYoutube.svg"
import linkIconWeb from "../../images/socialIcons/linkIconWeb.svg"
import linkIconPpol from "../../images/socialIcons/linkIconPpol.svg"
import linkIconKick from "../../images/socialIcons/linkIconKick.svg"
import linkIconWeibo from "../../images/socialIcons/linkIconWeibo.svg"
import linkIconWechat from "../../images/socialIcons/linkIconWechat.svg"
import linkIconThreads from "../../images/socialIcons/linkIconThreads.svg"
import linkIconVk from "../../images/socialIcons/linkIconVk.svg"
import verifiedIcon from "../../images/verifiedIcon.svg"
import closeCreationBarImg from "../../images/closeCreationBar.svg"

import tempArrowIcon from "../../images/tempArrowIcon.png"
import ppolLogo from "../../images/ppolLogo.png"
import profileDefaultImg from "../../images/profileDefaultImg.png"
import bannerDefaultImg from "../../images/bannerDefaultImg.png"
import editTextBtn from "../../images/editTextBtn.svg"

function Profile() {
  const [arraySocialLinks, setArraySocialLinks] = useState(null);
  const [socialLinks, setSocialLinks] = useState([]);
  const [profileName, setProfileName] = useState("")
  const [profileTitle, setProfileTitle] = useState("")
  const [profileDescription, setProfileDescription] = useState("")
  const [showBioInfo, setShowBioInfo] = useState(false)
  const [profileLayout, setProfileLayout] = useState(null)
  const [profileBody, setProfileBody] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [profileContact, setProfileContact] = useState(null)
  const [profileSendInfo, setProfileSendInfo] = useState(null)
  const [profileImages, setProfileImages] = useState([])
  const [userlink, setUserlink] = useState("")
  const [verified, setVerified] = useState(false)
  const [openContactForm, setOpenContactForm] = useState(false)
  const [contactName, setContactName] = useState("")
  const [contactLastname, setContactLastname] = useState("")
  const [contactEmail, setContactEmail] = useState("")
  const [contactPhone, setContactPhone] = useState("")
  const [contactCompany, setContactCompany] = useState("")
  const [contactPosition, setContactPosition] = useState("")
  const [contactMsg, setContactMsg] = useState("")
  const [contactFormError, setContactFormError] = useState("")
  const [language, setLanguage] = useState({})
  const [profileContactText, setProfileContactText] = useState("")
  const [profileSendInfoText, setProfileSendInfoText] = useState("")
  const [isPrivateProfile, setIsPrivateProfile] = useState(false)
  const [privateCode, setPrivateCode] = useState("")
  const [privateCodeError, setPrivateCodeError] = useState(null)

  const navigate = useNavigate()
  let {currentUser} = useContext(AuthContext);
  const userId = useLocation().pathname.split("/")[1];
  //console.log(useLocation().state)
  const card = useLocation().state?.card || false
  const location = useLocation()
  dotSpinner.register()
  ripples.register()

  const {isLoading, data} = useQuery(["user"], () =>
    makeRequest.get("findUser.php", { params: { user: userId } })
    .then((res) => {
    
      document.title = `${JSON.parse(res.data.profileBio).name || "PPOL"} | @${res.data.username || "PPOL"} | PPOL`
      setUserlink(res.data.userlink)
      setProfileName(res.data.userProfileName?res.data.userProfileName:"")
      setProfileTitle(res.data.userProfileTitle?res.data.userProfileTitle:"")
      setProfileDescription(res.data.userProfileDescription?res.data.userProfileDescription:"")
      setArraySocialLinks(JSON.parse(res.data.sociallinks))
      setVerified(res.data.isVerified==="0"?false:true)
      const changeProfileLayout = JSON.parse(res.data.profileLayout).map((item)=>{
        if(item.i !== "profile-created-with-ppol"){
          return {
            ...item,
            isResizable: false,
            isDraggable: false,
            static:false
          }
        }
        return item
      })
      setProfileLayout(changeProfileLayout)
      setProfileBody(JSON.parse(res.data.profileBody))
      setShowBioInfo(true)
      setProfileContact(res.data.profileContact)
      setProfileSendInfo(res.data.profileSendInfo)
      setProfileImages(JSON.parse(res.data.profileImages))
      setProfileContactText(res.data.profileContactText)
      setProfileSendInfoText(res.data.profileSendInfoText)
      setShowInfo(true)
      setIsPrivateProfile(res.data.isPrivate)
      //console.log(res.data.profileBio)
      /* ----- AQUI HACE LA CARGA A BASE DE DATOS ----- */
      if(currentUser?.userlink!==res.data.userlink){
        if(process.env.REACT_APP_PPOL_URL==="PRODUCTION"){
          fetch('https://ipinfo.io/json')
          .then((response) => response.json())
          .then((info) => {
            makeRequest.post("userVisitor.php", {
              "userlink":res.data.userlink,
              "IP":info.ip,
              "country":info.country,
              "city":info.city,
              "geo":info.loc,
              "lang":navigator.language,
              "isMob":`${/Mobi|Android/i.test(navigator.userAgent)}`,
              "usingCard":`${card}`
            })
            .catch((err) => {
              console.log(err)
            })
          })
          .catch((error) => {
              console.error('Error al obtener la información de geolocalización:', error);
          })
        }
        navigate(location.state,{replace:true})
      } else {
        //localStorage.clear()
        navigate(location.state,{replace:true})
      }
      return res.data
    })
    .catch(() => {
      navigate("/404")
    })
)

  useEffect(()=>{
      convertToSocialLinks(arraySocialLinks)
  },[arraySocialLinks])

  useEffect(()=>{
    if(!openContactForm){
      setContactName("")
      setContactLastname("")
      setContactEmail("")
      setContactPhone("")
      setContactCompany("")
      setContactPosition("")
      setContactMsg("")
    }
  },[openContactForm])

  useEffect(()=>{
    const languageSplit = (navigator.language).split('-')[0];
    const selectedLanguage = languageProfile(languageSplit)
    setLanguage(selectedLanguage)
  },[navigator.language]);

  const renderGridItem = (item) => {
      const correctItem = profileBody.find((itemFind) => itemFind.key === item.i)
      if(item.i.includes("createText4x0m")){
        return(
          <div key={`${item.i}`} className='text4x0m'>
            <input
              readOnly
              maxLength="61"
              style={{textAlign:correctItem.values?.align || 'left', backgroundColor:"transparent"}}
              value={correctItem.values?.title || ''}
            />
          </div>
        )
      } else if (item.i.includes("createText2x0m")){
        return(
          <div key={`${item.i}`} className='text2x0m'>
            <input
              readOnly
              maxLength="30"
              style={{textAlign:correctItem.values?.align || 'left', backgroundColor:"transparent"}}
              value={correctItem.values?.title || ''}
            />
          </div>
        )
      } else if (item.i.includes("createTextfxf")){
        return(
          <div
            key={`${item.i}`}
            className='textfxf'
            style={{alignItems:correctItem.values?.alignV || "flex-start"}}
          >
            <TextareaAutosize
              readOnly
              maxLength="2937"
              spellCheck="false"
              style={{height:"100%", textAlign:correctItem.values?.align || 'left', fontSize:`${correctItem.values?.size || 13}px`, backgroundColor:"transparent"}}
              value={correctItem.values?.title || ''}
            />
          </div>
        )
      } else if (item.i.includes("createImg2x2")){
        return(
          <div key={`${item.i}`} className='img2x2'>
            {correctItem.values?.type==="img"?
              ((correctItem.values?.link)?
                <a className='img2x2Video' rel='noreferrer' target='_blank' href={correctItem.values?.link.includes("http")?correctItem.values?.link:`https://${correctItem.values?.link}`}>
                  <img className="img2x2Video" alt="imagen" src={correctItem.values?.src || profileDefaultImg} loading="lazy"/>
                </a>
                :
                <img className="img2x2Video" alt="imagen" src={correctItem.values?.src || profileDefaultImg} loading="lazy"/>
              )
              :
              <video className="img2x2Video" alt="video" preload='metadata' controls>
                <source src={`${correctItem.values?.src}#t=0.01`}/>
              </video>
            }
          </div>
        )
      } else if (item.i.includes("createImg2x4")){
        return(
          <div key={`${item.i}`} className='img2x4'>
            {correctItem.values?.type==="img"?
              ((correctItem.values?.link)?
                <a className='img2x4Video' rel='noreferrer' target='_blank' href={correctItem.values?.link.includes("http")?correctItem.values?.link:`https://${correctItem.values?.link}`}>
                  <img className="img2x4Video" alt="imagen" src={correctItem.values?.src || profileDefaultImg} loading="lazy"/>
                </a>
                :
                <img className="img2x4Video" alt="imagen" src={correctItem.values?.src || profileDefaultImg} loading="lazy"/>
              )
              :
              <video className="img2x4Video" alt="" preload='metadata' controls>
                <source src={`${correctItem.values?.src}#t=0.01`}/>
              </video>
            }
          </div>
        )
      } else if (item.i.includes("createImg4x2")){
        return(
          <div key={`${item.i}`} className='img4x2'>
            {correctItem.values?.type==="img"?
              ((correctItem.values?.link)?
                <a className='img4x2Video' rel='noreferrer' target='_blank' href={correctItem.values?.link.includes("http")?correctItem.values?.link:`https://${correctItem.values?.link}`}>
                  <img className="img4x2Video" alt="imagen" src={correctItem.values?.src || profileDefaultImg} loading="lazy"/>
                </a>
                :
                <img className="img4x2Video" alt="imagen" src={correctItem.values?.src || profileDefaultImg} loading="lazy"/>
              )
              :
              <video className="img4x2Video" alt="" preload='metadata' controls>
                <source src={`${correctItem.values?.src}#t=0.01`}/>
              </video>
            }
          </div>
        )
      } else if (item.i.includes("createImg4x4")){
        return(
          <div key={`${item.i}`} className='img4x4'>
            {correctItem.values?.type==="img"?
              ((correctItem.values?.link)?
                <a className='img4x4Video' rel='noreferrer' target='_blank' href={correctItem.values?.link.includes("http")?correctItem.values?.link:`https://${correctItem.values?.link}`}>
                  <img className="img4x4Video" alt="imagen" src={correctItem.values?.src || profileDefaultImg} loading="lazy"/>
                </a>
                :
                <img className="img4x4Video" alt="imagen" src={correctItem.values?.src || profileDefaultImg} loading="lazy"/>
              )
              :
              <video className="img4x4Video" alt="" preload='metadata' controls>
                <source src={`${correctItem.values?.src}#t=0.01`}/>
              </video>
            }
          </div>
        )
      } else if (item.i.includes("createImgfxf")){
        return(
          <div key={`${item.i}`} className='imgfxf'>
            {correctItem.values?.type==="img"?
              ((correctItem.values?.link)?
                <a className='imgfxfVideo' rel='noreferrer' target='_blank' href={correctItem.values?.link.includes("http")?correctItem.values?.link:`https://${correctItem.values?.link}`}>
                  <img className="imgfxfVideo" alt="imagen" src={correctItem.values?.src || profileDefaultImg} loading="lazy"/>
                </a>
                :
                <img className="imgfxfVideo" alt="imagen" src={correctItem.values?.src || profileDefaultImg} loading="lazy"/>
              )
              :
              <video className="imgfxfVideo" alt="" preload='metadata' controls>
                <source src={`${correctItem.values?.src}#t=0.01`}/>
              </video>
            }
          </div>
        )
      } else if (item.i.includes("createSeparation")){
        return (
          <div key={`${item.i}`} className='separation'>
            <div
              className='separationLine'
              style={{backgroundColor:correctItem.values?.color || "#34353A"}}
            >
            </div>
          </div>
        )
      } else if(item.i.includes("createEraser4x1m")){
        return (
          <div key={`${item.i}`} className='eraser4x1m'>
            <div className='eraser4x1mMediaCont'>
              {correctItem.values?.type==='img'?
                <img className="eraser4x1mVideo" alt="imagen" loading="lazy" src={correctItem.values?.src || profileDefaultImg}/>
                :
                <video className="eraser4x1mVideo" alt="video" preload='metadata' controls>
                  <source src={`${correctItem.values?.src}#t=0.01`}/>
                </video>
              }
            </div>
            {(correctItem.values?.link)?
              <a
                className='eraser4x1mContainer'
                rel='noreferrer'
                target="_blank"
                href={(correctItem.values?.link.includes("http"))?correctItem.values?.link:`https://${correctItem.values?.link}`}
              >
                {(correctItem.values?.title)?
                  <TextareaAutosize
                    className='eraser4x1mTitle'
                    readOnly
                    maxLength="68"
                    spellCheck={false}
                    value={correctItem.values?.title || ''}
                    style={{backgroundColor:"transparent"}}
                  />
                  :
                  <></>
                }
                {(correctItem.values?.desc)?
                  <TextareaAutosize
                    className='eraser4x1mDescription'
                    readOnly
                    maxLength="123"
                    spellCheck={false}
                    value={correctItem.values?.desc || ''}
                    style={{backgroundColor:"transparent"}}
                  />
                  :
                  <></>
                }
              </a>
              :
              <div
                className='eraser4x1mContainer'
                rel='noreferrer'
                target="_blank"
                href={(correctItem.values?.link.includes("http"))?correctItem.values?.link:`https://${correctItem.values?.link}`}
              >
                {(correctItem.values?.title)?
                  <TextareaAutosize
                    className='eraser4x1mTitle'
                    readOnly
                    maxLength="68"
                    spellCheck={false}
                    value={correctItem.values?.title || ''}
                    style={{backgroundColor:"transparent"}}
                  />
                  :
                  <></>
                }
                {(correctItem.values?.desc)?
                  <TextareaAutosize
                    className='eraser4x1mDescription'
                    readOnly
                    maxLength="123"
                    spellCheck={false}
                    value={correctItem.values?.desc || ''}
                    style={{backgroundColor:"transparent"}}
                  />
                  :
                  <></>
                }
              </div>
            }
          </div>
        )
      } else if(item.i.includes("createSection4x1m")){
        return(
          <div key={`${item.i}`} className="section4x1m">
            <div className='section4x1mMediaCont'>
              {correctItem.values?.type==='img'?
                <img className="section4x1mVideo" alt="imagen" loading="lazy" src={correctItem.values?.src || profileDefaultImg}/>
                :
                <video className="section4x1mVideo" alt="video" preload='metadata' controls>
                  <source src={`${correctItem.values?.src}#t=0.01`}/>
                </video>
              }
            </div>
            {(correctItem.values?.link)?
              <a
                className='section4x1mContainer'
                rel='noreferrer'
                target="_blank"
                href={(correctItem.values?.link.includes("http"))?correctItem.values?.link:`https://${correctItem.values?.link}`}
              >
                {(correctItem.values?.title)?
                  <TextareaAutosize
                    className='section4x1mTitle'
                    readOnly
                    maxLength="94"
                    spellCheck={false}
                    value={correctItem.values?.title || ''}
                    style={{backgroundColor:"transparent"}}
                  />
                  :
                  <></>
                }
                {(correctItem.values?.desc)?
                  <TextareaAutosize
                    className='section4x1mDescription'
                    readOnly
                    maxLength="198"
                    spellCheck={false}
                    value={correctItem.values?.desc || ''}
                    style={{backgroundColor:"transparent"}}
                  />
                  :
                  <></>
                }
              </a>
              :
              <div className='section4x1mContainer'>
                {(correctItem.values?.title)?
                  <TextareaAutosize
                    className='section4x1mTitle'
                    readOnly
                    maxLength="94"
                    spellCheck={false}
                    value={correctItem.values?.title || ''}
                    style={{backgroundColor:"transparent"}}
                  />
                  :
                  <></>
                }
                {(correctItem.values?.desc)?
                  <TextareaAutosize
                    className='section4x1mDescription'
                    readOnly
                    maxLength="198"
                    spellCheck={false}
                    value={correctItem.values?.desc || ''}
                    style={{backgroundColor:"transparent"}}
                  />
                  :
                  <></>
                }
              </div>
            }
          </div>
        )
      } else if(item.i.includes("createDate4x1")){
        return (
          <div key={`${item.i}`} className='date4x1'>
            <div className='date4x1Date'>
              <input
                className='date4x1Day'
                readOnly
                maxLength="2"
                spellCheck={false}
                value={correctItem.values?.day || ''}
                style={{backgroundColor:"transparent"}}
              />
              <input
                className='date4x1Month'
                readOnly
                maxLength="3"
                spellCheck={false}
                value={correctItem.values?.month || ''}
                style={{backgroundColor:"transparent"}}
              />
            </div>
            {(correctItem.values?.link)?
              <a
                className='date4x1Container'
                target="_blank"
                rel="noreferrer"
                href={(correctItem.values?.link.includes("http"))?correctItem.values?.link:`https://${correctItem.values?.link}`}
              >
                {(correctItem.values?.title)?
                  <input
                    className='date4x1Title'
                    readOnly
                    maxLength="58"
                    spellCheck={false}
                    value={correctItem.values?.title || ''}
                    style={{backgroundColor:"transparent"}}
                  />
                  :
                  <></>
                }
                {(correctItem.values?.desc)?
                  <TextareaAutosize
                    className='date4x1Description'
                    readOnly
                    maxLength="140"
                    spellCheck={false}
                    value={correctItem.values?.desc || ''}
                    style={{backgroundColor:"transparent"}}
                  />
                  :
                  <></>
                }
              </a>
              :
              <div className='date4x1Container'>
                {(correctItem.values?.title)?
                  <input
                    className='date4x1Title'
                    readOnly
                    maxLength="58"
                    spellCheck={false}
                    value={correctItem.values?.title || ''}
                    style={{backgroundColor:"transparent"}}
                  />
                  :
                  <></>
                }
                {(correctItem.values?.desc)?
                  <TextareaAutosize
                    className='date4x1Description'
                    readOnly
                    maxLength="140"
                    spellCheck={false}
                    value={correctItem.values?.desc || ''}
                    style={{backgroundColor:"transparent"}}
                  />
                  :
                  <></>
                }
              </div>
            }
          </div>
        )
      } else if(item.i.includes("createSection2x2")){
        return (
          <div key={`${item.i}`} className='section2x2'>
            <div className='section2x2MediaCont'>
              {correctItem.values?.type==='img'?
                <img className="section2x2Video" alt="imagen" loading="lazy" src={correctItem.values?.src || profileDefaultImg}/>
                :
                <video className="section2x2Video" alt="video" preload='metadata' controls>
                  <source src={`${correctItem.values?.src}#t=0.01`}/>
                </video>
              }
            </div>
            {(correctItem.values?.link)?
              <a
                className='section2x2Container'
                target="_blank"
                rel='noreferrer'
                href={(correctItem.values?.link.includes("http"))?correctItem.values?.link:`https://${correctItem.values?.link}`}
              >
                <TextareaAutosize
                  className='section2x2Title'
                  readOnly
                  maxLength="99"
                  spellCheck={false}
                  value={correctItem.values?.title || ''}
                  style={{backgroundColor:"transparent"}}
                />
              </a>
              :
              <div className='section2x2Container'>
                <TextareaAutosize
                  className='section2x2Title'
                  readOnly
                  maxLength="99"
                  spellCheck={false}
                  value={correctItem.values?.title || ''}
                  style={{backgroundColor:"transparent"}}
                />
              </div>
            }
          </div>
        )
      } else if(item.i.includes("createSection4x1")){
        return (
          <div key={`${item.i}`} className='section4x1'>
            <div className='section4x1MediaCont'>
              {(correctItem.values?.type==='img'||!(correctItem.values?.type))?
                <img className="section4x1Img" alt="imagen" loading="lazy" src={correctItem.values?.src || profileDefaultImg}/>
                :
                <video className="section4x1Img" alt="video" preload='metadata' controls>
                  <source src={`${correctItem.values?.src}#t=0.01`}/>
                </video>
              }
            </div>
            {(correctItem.values?.link)?
              <a
                className='section4x1Container'
                target="_blank"
                rel='noreferrer'
                href={(correctItem.values?.link.includes("http"))?correctItem.values?.link:`https://${correctItem.values?.link}`}
              >
                {(correctItem.values?.title)?
                  <input
                    className='section4x1Title'
                    readOnly
                    maxLength="59"
                    spellCheck={false}
                    value={correctItem.values?.title || ''}
                    style={{backgroundColor:"transparent"}}
                  />
                  :
                  <></>
                }
                {(correctItem.values?.desc)?
                  <input
                    className='section4x1Description'
                    readOnly
                    maxLength="71"
                    spellCheck={false}
                    value={correctItem.values?.desc || ''}
                    style={{backgroundColor:"transparent"}}
                  />
                  :
                  <></>
                }
              </a>
              :
              <div className='section4x1Container'>
                {(correctItem.values?.title)?
                  <input
                    className='section4x1Title'
                    readOnly
                    maxLength="59"
                    spellCheck={false}
                    value={correctItem.values?.title || ''}
                    style={{backgroundColor:"transparent"}}
                  />
                  :
                  <></>
                }
                {(correctItem.values?.desc)?
                  <input
                    className='section4x1Description'
                    readOnly
                    maxLength="71"
                    spellCheck={false}
                    value={correctItem.values?.desc || ''}
                    style={{backgroundColor:"transparent"}}
                  />
                  :
                  <></>
                }
              </div>
            }
          </div>
        )
      } else if(item.i.includes("createEmbed4x1")){
        return (
          <div key={`${item.i}`} className='embed4x1'>
            {(correctItem.values?.link)?
              <a
                className='embed4x1Container'
                target="_blank"
                rel='noreferrer'
                href={(correctItem.values?.link.includes("http"))?correctItem.values?.link:`https://${correctItem.values?.link}`}
              >
                <input
                  className='embed4x1Title'
                  readOnly
                  maxLength="59"
                  spellCheck={false}
                  value={correctItem.values?.title || ''}
                  style={{backgroundColor:"transparent"}}
                />
              </a>
              :
              <div className='embed4x1Container'>
                <input
                  className='embed4x1Title'
                  readOnly
                  maxLength="80"
                  spellCheck={false}
                  value={correctItem.values?.title || ''}
                  style={{backgroundColor:"transparent"}}
                />
              </div>
            }
          </div>
        )
      } else if(item.i.includes("createSection2x3m")){
        return (
          <div key={`${item.i}`} className='section2x3m'>
            <div className='section2x3mMediaCont'>
              {correctItem.values?.type==='img'?
                <img className='section2x3mVideo' alt='imagen' loading="lazy" src={correctItem.values?.src || profileDefaultImg}/>
                :
                <video className='section2x3mVideo' alt="video" preload='metadata' controls>
                  <source src={`${correctItem.values?.src}#t=0.01`}/>
                </video>
              }
            </div>
            {(correctItem.values?.link)?
              <a
                className='section2x3mContainer'
                target="_blank"
                rel='noreferrer'
                href={(correctItem.values?.link.includes("http"))?correctItem.values?.link:`https://${correctItem.values?.link}`}
                style={{height:correctItem.values.price?"calc(100% - 178px - 45px)":"calc(100% - 178px)"}}
              >
                {(correctItem.values?.title)?
                  <TextareaAutosize
                    className='section2x3mTitle'
                    readOnly
                    spellCheck="false"
                    maxLength="66"
                    value={correctItem.values?.title || ''}
                    style={{backgroundColor:"transparent"}}
                  />
                  :
                  <></>
                }
                {(correctItem.values?.desc)?
                  <TextareaAutosize
                    className='section2x3mDescription'
                    readOnly
                    spellCheck="false"
                    maxLength="156"
                    value={correctItem.values?.desc || ''}
                    style={{backgroundColor:"transparent"}}
                  />
                  :
                  <></>
                }
              </a>
              :
              <div
                className='section2x3mContainer'
                style={{height:correctItem.values.price?"calc(100% - 178px - 45px)":"calc(100% - 178px)"}}
              >
                {(correctItem.values?.title)?
                  <TextareaAutosize
                    className='section2x3mTitle'
                    readOnly
                    spellCheck="false"
                    maxLength="66"
                    value={correctItem.values?.title || ''}
                    style={{backgroundColor:"transparent"}}
                  />
                  :
                  <></>
                }
                {(correctItem.values?.desc)?
                  <TextareaAutosize
                    className='section2x3mDescription'
                    readOnly
                    spellCheck="false"
                    maxLength="156"
                    value={correctItem.values?.desc || ''}
                    style={{backgroundColor:"transparent"}}
                  />
                  :
                  <></>
                }
              </div>
            }
            {(correctItem.values?.link)?
              <a
                style={{width:'100%'}}
                href={(correctItem.values?.link.includes("http"))?correctItem.values?.link:`https://${correctItem.values?.link}`}
                target='_blank'
                rel='noreferrer'
              >
                {(correctItem.values?.price)?
                  <input
                    className='section2x3mPrice'
                    spellCheck="false"
                    maxLength="27"
                    placeholder='S/'
                    value={correctItem.values?.price || ''}
                    style={{cursor:'pointer'}}
                  />
                  :
                  <></>
                }
              </a>
              :
              <div style={{width:'100%'}}>
                <input
                  className='section2x3mPrice'
                  spellCheck="false"
                  maxLength="27"
                  placeholder='S/'
                  value={correctItem.values?.price || ''}
                />
                :
                <></>
              </div>
            }
          </div>
        )
      } else if(item.i.includes("createSpotifyMini")){
        const spotifyId = (correctItem.values?.link)?(correctItem.values?.link.split(".com/")[1])?(correctItem.values?.link.split(".com/")[1].split("?")[0])?correctItem.values?.link.split(".com/")[1].split("?")[0].split("/"):"":"":""
        return ( 
          <div key={`${item.i}`} className='embedSpotifyMini'>
            <iframe
              title={item.i}
              style={{borderradius: "9px"}}
              src={`https://open.spotify.com/embed/${correctItem.values?.link.includes("/intl")?spotifyId[1]:spotifyId[0]}/${correctItem.values?.link.includes("/intl")?spotifyId[2]:spotifyId[1]}?`}
              width="100%"
              height="80px"
              frameBorder="0"
              allowFullScreen=""
              loading="lazy"
              allowtransparency="true"
              allow="encrypted-media"
            />  
          </div>
        )
      } else if(item.i.includes("createSpotify")){
        const spotifyId = (correctItem.values?.link)?(correctItem.values?.link.split(".com/")[1])?(correctItem.values?.link.split(".com/")[1].split("?")[0])?correctItem.values?.link.split(".com/")[1].split("?")[0].split("/"):"":"":""
        return (
          <div key={`${item.i}`} className='embedSpotify'>
            <iframe
              title={item.i}
              style={{borderRadius: "9px"}}
              src={`https://open.spotify.com/embed/${correctItem.values?.link.includes("/intl")?spotifyId[1]:spotifyId[0]}/${correctItem.values?.link.includes("/intl")?spotifyId[2]:spotifyId[1]}?`}
              width="100%"
              height={(correctItem.values?.link.includes("playlist") || correctItem.values?.link.includes("artist"))?"100%":"352"}
              frameBorder="0"
              allowFullScreen=""
              loading="lazy"
              allowtransparency="true"
              allow="encrypted-media"
            />
          </div>
        )
      } else if(item.i.includes("createYoutube")){
        const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|shorts\/)([^#\&\?]*).*/;
        const match = correctItem.values?.link.match(regExp)
        const youtubeId = (match && match[2].length===11)?match[2]:null;
        return (
          <div key={`${item.i}`} className='sectionYoutube'>
            <iframe
              width="100%"
              height="100%"
              src={`https://www.youtube.com/embed/${youtubeId}`}
              title="YouTube video player"
              frameBorder="0"
              allow="autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              loading="lazy"
            />
          </div>  
        )
      } else if(item.i.includes("createVimeo")){
        const vimeoId = (correctItem.values?.link)?correctItem.values?.link.split(".com/")[1].split("?")[0]:null
        return (
          <div key={`${item.i}`} className='sectionVimeo'>
            <iframe
              title={`${item.i}`}
              src={`https://player.vimeo.com/video/${vimeoId}`}
              width="100%"
              height="219px"
              frameBorder="0"
              allow='autoplay; fullscreen; picture-in-picture'
              allowFullScreen
              loading="lazy"
              borderRadius="9px"
            />
          </div>        
        )
      } else if(item.i.includes("createFilePdf")){
        return (
          <a
            key={`${item.i}`}
            className='filePdf'
            href={correctItem.values.src}
            target='_blank'
            rel='noreferrer'
          >
            <svg height="50px" width="50px"xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56">
              <g>
                <path fill="#E9E9E0" d="M36.985,0H7.963C7.155,0,6.5,0.655,6.5,1.926V55c0,0.345,0.655,1,1.463,1h40.074c0.808,0,1.463-0.655,1.463-1V12.978c0-0.696-0.093-0.92-0.257-1.085L37.607,0.257C37.442,0.093,37.218,0,36.985,0z"/>
                <polygon fill="#D9D7CA" points="37.5,0.151 37.5,12 49.349,12 	"/>
                <path fill={`${correctItem.values?.bg || '#CC4B4C'}`} d="M19.514,33.324L19.514,33.324c-0.348,0-0.682-0.113-0.967-0.326c-1.041-0.781-1.181-1.65-1.115-2.242c0.182-1.628,2.195-3.332,5.985-5.068c1.504-3.296,2.935-7.357,3.788-10.75c-0.998-2.172-1.968-4.99-1.261-6.643c0.248-0.579,0.557-1.023,1.134-1.215c0.228-0.076,0.804-0.172,1.016-0.172c0.504,0,0.947,0.649,1.261,1.049c0.295,0.376,0.964,1.173-0.373,6.802c1.348,2.784,3.258,5.62,5.088,7.562c1.311-0.237,2.439-0.358,3.358-0.358c1.566,0,2.515,0.365,2.902,1.117c0.32,0.622,0.189,1.349-0.39,2.16c-0.557,0.779-1.325,1.191-2.22,1.191c-1.216,0-2.632-0.768-4.211-2.285c-2.837,0.593-6.15,1.651-8.828,2.822c-0.836,1.774-1.637,3.203-2.383,4.251C21.273,32.654,20.389,33.324,19.514,33.324z M22.176,28.198c-2.137,1.201-3.008,2.188-3.071,2.744c-0.01,0.092-0.037,0.334,0.431,0.692C19.685,31.587,20.555,31.19,22.176,28.198z M35.813,23.756c0.815,0.627,1.014,0.944,1.547,0.944c0.234,0,0.901-0.01,1.21-0.441c0.149-0.209,0.207-0.343,0.23-0.415c-0.123-0.065-0.286-0.197-1.175-0.197C37.12,23.648,36.485,23.67,35.813,23.756z M28.343,17.174c-0.715,2.474-1.659,5.145-2.674,7.564c2.09-0.811,4.362-1.519,6.496-2.02C30.815,21.15,29.466,19.192,28.343,17.174z M27.736,8.712c-0.098,0.033-1.33,1.757,0.096,3.216C28.781,9.813,27.779,8.698,27.736,8.712z"/>
                <path fill={`${correctItem.values?.bg || '#CC4B4C'}`} d="M48.037,56H7.963C7.155,56,6.5,55.345,6.5,54.537V39h43v15.537C49.5,55.345,48.845,56,48.037,56z"/>
                <g>
                  <path fill={`${correctItem.values?.word || '#FFFFFF'}`} d="M17.385,53h-1.641V42.924h2.898c0.428,0,0.852,0.068,1.271,0.205c0.419,0.137,0.795,0.342,1.128,0.615c0.333,0.273,0.602,0.604,0.807,0.991s0.308,0.822,0.308,1.306c0,0.511-0.087,0.973-0.26,1.388c-0.173,0.415-0.415,0.764-0.725,1.046c-0.31,0.282-0.684,0.501-1.121,0.656s-0.921,0.232-1.449,0.232h-1.217V53z M17.385,44.168v3.992h1.504c0.2,0,0.398-0.034,0.595-0.103c0.196-0.068,0.376-0.18,0.54-0.335c0.164-0.155,0.296-0.371,0.396-0.649c0.1-0.278,0.15-0.622,0.15-1.032c0-0.164-0.023-0.354-0.068-0.567c-0.046-0.214-0.139-0.419-0.28-0.615c-0.142-0.196-0.34-0.36-0.595-0.492c-0.255-0.132-0.593-0.198-1.012-0.198H17.385z"/>
                  <path fill={`${correctItem.values?.word || '#FFFFFF'}`} d="M32.219,47.682c0,0.829-0.089,1.538-0.267,2.126s-0.403,1.08-0.677,1.477s-0.581,0.709-0.923,0.937s-0.672,0.398-0.991,0.513c-0.319,0.114-0.611,0.187-0.875,0.219C28.222,52.984,28.026,53,27.898,53h-3.814V42.924h3.035c0.848,0,1.593,0.135,2.235,0.403s1.176,0.627,1.6,1.073s0.74,0.955,0.95,1.524C32.114,46.494,32.219,47.08,32.219,47.682z M27.352,51.797c1.112,0,1.914-0.355,2.406-1.066s0.738-1.741,0.738-3.09c0-0.419-0.05-0.834-0.15-1.244c-0.101-0.41-0.294-0.781-0.581-1.114s-0.677-0.602-1.169-0.807s-1.13-0.308-1.914-0.308h-0.957v7.629H27.352z"/>
                  <path fill={`${correctItem.values?.word || '#FFFFFF'}`} d="M36.266,44.168v3.172h4.211v1.121h-4.211V53h-1.668V42.924H40.9v1.244H36.266z"/>
                </g>
              </g>
            </svg>
            <TextareaAutosize
              className='filePdfTitle'
              readOnly
              maxLength="94"
              spellCheck={false}
              value={correctItem.values?.title}
              style={{fontSize:`${correctItem.values?.size || 12}px`, backgroundColor:"transparent"}}
            />
          </a>
        )
      } else if(item.i.includes("createCarousel4x2m")){
        const carouselItems = []
        for(const section in correctItem.values){
          carouselItems.push(
            <SwiperSlide className='carousel4x2mContainer'>
              <div className='carousel4x2mMediaCont'>
                {(correctItem.values[section].type==='img')?
                  correctItem.values[section].link?
                    <a
                      className='carousel4x3mTextCont'
                      style={{cursor:"pointer", height:"100%", width:"100%", borderRadius:"inherit"}}
                      href={correctItem.values[section].link?.includes("https")?correctItem.values[section].link:`https://${correctItem.values[section].link}`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img className="carousel4x2mVideo" alt="Imagen" src={correctItem.values[section].src || profileDefaultImg}/>
                    </a>
                    :
                    <img className="carousel4x2mVideo" alt="Imagen" src={correctItem.values[section].src || profileDefaultImg}/>
                  :
                  <video className="carousel4x2mVideo" alt="video" preload="metadata" tabIndex="-1" type="video" controls>
                    <source src={`${correctItem.values[section].src}#t=0.01`}></source>
                  </video>
                }
              </div>
            </SwiperSlide>
          )
        }
        return (
          <div key={`${item.i}`}>
            <Swiper
              slidesPerView={2.75}
              spaceBetween={window.innerWidth>400?10:3}
              freeMode={true}
              pagination={{
                clickable: true,
              }}
              modules={[FreeMode]}
              className='carousel4x2m'
            >
              {carouselItems}
            </Swiper>
          </div>
        )
      } else if(item.i.includes("createCarousel4x1m")){
        const carouselItems = []
        for(const section in correctItem.values){
          carouselItems.push(
            <SwiperSlide className='carousel4x1mContainer'>
              <div className='carousel4x1mHead'>
                <div className='carousel4x1mMediaCont'>
                  <img className="carousel4x1mImg" alt="imagen" src={correctItem.values[section].src || profileDefaultImg}/>
                </div>
                <div className='carousel4x1mTitleStars'>
                  <input
                    className="carousel4x1mTitle"
                    spellCheck="false"
                    tabIndex="-1"
                    readOnly
                    maxLength="47"
                    value={correctItem.values[section].title || ''}
                    style={{backgroundColor:"transparent"}}
                  />
                  <div className='carousel4x1mStars'>
                    <button
                      className='carousel4x1mStar'
                      tabIndex="-1"
                      style={{color:correctItem.values[section].stars>=1?"#F3C531":"#D7D7D7"}}
                    >
                      ★
                    </button>
                    <button
                      className='carousel4x1mStar'
                      tabIndex="-1"
                      style={{color:correctItem.values[section].stars>=2?"#F3C531":"#D7D7D7"}}
                    >
                      ★
                    </button>
                    <button
                      className='carousel4x1mStar'
                      tabIndex="-1"
                      style={{color:correctItem.values[section].stars>=3?"#F3C531":"#D7D7D7"}}
                    >
                      ★
                    </button>
                    <button
                      className='carousel4x1mStar'
                      tabIndex="-1"
                      style={{color:correctItem.values[section].stars>=4?"#F3C531":"#D7D7D7"}}
                    >
                      ★
                    </button>
                    <button
                      className='carousel4x1mStar'
                      tabIndex="-1"
                      style={{color:correctItem.values[section].stars>=5?"#F3C531":"#D7D7D7"}}
                    >
                      ★
                    </button>
                  </div>
                </div>
              </div>
              <textarea
                className="carousel4x1mDescription"
                tabIndex="-1"
                readOnly
                spellCheck="false"
                maxLength="234"
                value={correctItem.values[section].desc || ''}
                style={{backgroundColor:"transparent"}}
              />
            </SwiperSlide>
          )
        }
        return (
          <div key={`${item.i}`}>
            <Swiper
              slidesPerView={1.5}
              spaceBetween={window.innerWidth>400?10:3}
              freeMode={true}
              pagination={{
                clickable: true,
              }}
              modules={[FreeMode]}
              className='carousel4x1m'
            >
              {carouselItems}
            </Swiper>
          </div>
        )
      } else if(item.i.includes("createCarousel4x1")){
        const carouselItems = []
        for(const section in correctItem.values){
          carouselItems.push(
            <SwiperSlide className='carousel4x1Container'>
              <div className='carousel4x1MediaCont'>
                {(correctItem.values[section].type==='img')?
                  correctItem.values[section].link?
                    <a
                      className='carousel4x3mTextCont'
                      style={{cursor:"pointer", height:"100%", width:"100%", borderRadius:"inherit"}}
                      href={correctItem.values[section].link?.includes("https")?correctItem.values[section].link:`https://${correctItem.values[section].link}`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img className="carousel4x1Video" alt="imagen" src={correctItem.values[section].src || profileDefaultImg}/>
                    </a>
                  :
                  <img className="carousel4x1Video" alt="imagen" src={correctItem.values[section].src || profileDefaultImg}/>
                  :
                  <video className="carousel4x1Video" alt="video" preload="metadata" tabIndex="-1" controls>
                    <source src={`${correctItem.values[section].src}#t=0.01`}></source>
                  </video>
                }
              </div>
            </SwiperSlide>
          )
        }
        return (
          <div key={`${item.i}`}>
            <Swiper
              slidesPerView={4}
              spaceBetween={window.innerWidth>400?10:3}
              freeMode={true}
              pagination={{
                clickable: true,
              }}
              modules={[FreeMode]}
              className='carousel4x2m'
            >
              {carouselItems}
            </Swiper>
          </div>
        )
      } else if(item.i.includes("createCarousel4x3m")){
        const carouselItems = []
        for(const section in correctItem.values){
          carouselItems.push(
            <SwiperSlide className='carousel4x3mObjContainer'>
              <div className='carousel4x3mContainer'>
                <div className='carousel4x3mMediaCont'>
                  {(correctItem.values[section].type === "img")?
                    correctItem.values[section].link?
                      <a
                        className='carousel4x3mTextCont'
                        style={{cursor:"pointer", height:"100%", width:"100%", borderRadius:"inherit"}}
                        href={correctItem.values[section].link?.includes("https")?correctItem.values[section].link:`https://${correctItem.values[section].link}`}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img className='carousel4x3mVideo' alt="imagen" src={correctItem.values[section].src}/>
                      </a>
                      :
                      <img className='carousel4x3mVideo' alt="imagen" src={correctItem.values[section].src}/>
                    
                    :
                    <video className="carousel4x3mVideo" alt="video" tabIndex="-1" preload="metadata" controls>
                      <source src={`${correctItem.values[section].src}#t=0.01`}></source>
                    </video>
                  }
                </div>
                {(correctItem.values[section].link)?
                  <a
                    className='carousel4x3mTextCont'
                    style={{cursor:"pointer", height:correctItem.values[section].price?"calc(100% - 167px - 45px)":"calc(100% - 167px)", width:"100%"}}
                    href={correctItem.values[section].link?.includes("https")?correctItem.values[section].link:`https://${correctItem.values[section].link}`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {(correctItem.values[section].title)?
                      <TextareaAutosize
                        className='carousel4x3mTitle'
                        tabIndex="-1"
                        readOnly
                        spellCheck="false"
                        maxLength="58"
                        value={correctItem.values[section].title || ''}
                        style={{backgroundColor:"transparent"}}
                      />
                      :
                      <></>
                    }
                    {(correctItem.values[section].desc)?
                      <TextareaAutosize
                        className='carousel4x3mDescription'
                        tabIndex="-1"
                        readOnly
                        spellCheck="false"
                        maxLength="138"
                        value={correctItem.values[section].desc || ''}
                        style={{backgroundColor:"transparent"}}
                      />
                      :
                      <></>
                    }
                  </a>
                  :
                  <div
                    className='carousel4x3mTextCont'
                    style={{height:correctItem.values[section].price?"calc(100% - 167px - 45px)":"calc(100% - 167px)"}}
                  >
                    {(correctItem.values[section].title)?
                      <TextareaAutosize
                        className='carousel4x3mTitle'
                        tabIndex="-1"
                        readOnly
                        spellCheck="false"
                        maxLength="58"
                        value={correctItem.values[section].title || ''}
                        style={{backgroundColor:"transparent"}}
                      />
                      :
                      <></>
                    }
                    {(correctItem.values[section].desc)?
                      <TextareaAutosize
                        className='carousel4x3mDescription'
                        tabIndex="-1"
                        readOnly
                        spellCheck="false"
                        maxLength="138"
                        value={correctItem.values[section].desc || ''}
                        style={{backgroundColor:"transparent"}}
                      />
                      :
                      <></>
                    }
                  </div>
                }
                {(correctItem.values[section].link)?
                  <a
                    style={{width:"100%"}}
                    href={correctItem.values[section].link?.includes("https")?correctItem.values[section].link:`https://${correctItem.values[section].link}`}
                    target='_blank'
                    rel='noreferrer'
                    >
                    {(correctItem.values[section].price)?
                      <input
                        className='carousel4x3mPrice'
                        tabIndex={-1}
                        spellCheck={false}
                        readOnly
                        value={correctItem.values[section].price || ''}
                        style={{cursor:"pointer"}}
                      />
                      :
                      <></>
                    }
                  </a>
                  :
                  <div style={{width:"100%"}}>
                    {(correctItem.values[section].price)?
                      <input
                        className='carousel4x3mPrice'
                        tabIndex={-1}
                        spellCheck={false}
                        readOnly
                        value={correctItem.values[section].price || ''}
                      />
                      :
                      <></>
                    }
                  </div>
                }
              </div>
            </SwiperSlide>
          )
        }
        return (
          <div key={`${item.i}`}>
            <Swiper
              slidesPerView={2.25}
              spaceBetween={window.innerWidth>400?10:3}
              freeMode={true}
              pagination={{
                clickable: true,
              }}
              modules={[FreeMode]}
              className='carousel4x3m'
            >
              {carouselItems}
            </Swiper> 
          </div>
        )
      } else if(item.i.includes("createCarousel4x2")){
        const carouselItems = []
        for(const section in correctItem.values){
          carouselItems.push(
            <SwiperSlide className='carousel4x2Container'>
              <div className='carousel4x2MediaCont'>
                {correctItem.values[section].type==='img'?
                  correctItem.values[section].link?
                    <a
                      className='carousel4x3mTextCont'
                      style={{cursor:"pointer", height:"100%", width:"100%", borderRadius:"inherit"}}
                      href={correctItem.values[section].link?.includes("https")?correctItem.values[section].link:`https://${correctItem.values[section].link}`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img className="carousel4x2Video" alt="imagen" src={correctItem.values[section].src || profileDefaultImg}/>
                    </a>
                    :
                    <img className="carousel4x2Video" alt="imagen" src={correctItem.values[section].src || profileDefaultImg}/>
                  :
                  <video className="carousel4x2Video" alt="video" preload="metadata" tabIndex="-1" controls>
                    <source src={`${correctItem.values[section].src}#t=0.01`}></source>
                  </video>
                }
              </div>
              {(correctItem.values[section].link)?
                <a
                  style={{width:"fit-content", height:"fit-content", cursor:"pointer"}}
                  href={correctItem.values[section].link.includes("https")?correctItem.values[section].link:`https://${correctItem.values[section].link}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  <textarea
                    className="carousel4x2Title"
                    tabIndex="-1"
                    readOnly
                    maxLength="86"
                    spellCheck="false"
                    value={correctItem.values[section].title || ''}
                    style={{backgroundColor:"transparent"}}
                  />
                </a>
                :
                <div style={{width:"fit-content", height:"fit-content", cursor:"pointer"}}>
                  <textarea
                    className="carousel4x2Title"
                    tabIndex="-1"
                    readOnly
                    maxLength="86"
                    spellCheck="false"
                    value={correctItem.values[section].title || ''}
                    style={{backgroundColor:"transparent"}}
                  />
                </div>
              }
            </SwiperSlide>
          )
        }
        return (
          <div key={`${item.i}`}>
            <Swiper
              slidesPerView={2.7}
              spaceBetween={window.innerWidth>400?10:3}
              freeMode={true}
              pagination={{
                clickable: true,
              }}
              modules={[FreeMode]}
              className='carousel4x2m'
            >
              {carouselItems}
            </Swiper>
          </div>
        )
      } else if(item.i.includes("createMap4x4")){
        return (
          <a
            key={`${item.i}`}
            className='map4x4'
            target='_blank'
            rel='noreferrer'
            href={/Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)?`geo:${correctItem.values.lat},${correctItem.values.lng}?q=${correctItem.values.lat},${correctItem.values.lng}`:/iPhone|iPad|iPod/i.test(navigator.userAgent)?`http://maps.apple.com/place?q=${correctItem.values.lat},${correctItem.values.lng}&ll=${correctItem.values.lat},${correctItem.values.lng}`:`https://www.google.com/maps/search/?api=1&query=${correctItem.values.lat},${correctItem.values.lng}`}
          >
            <MapboxStatic
              key={item.i}
              correctItem={correctItem}
            />
            <div style={{position:"absolute", top:"calc(50% - 25px)", left:"calc(50% - 25px)", pointerEvents:"none"}}>
              <l-ripples size="50" speed="2" color={correctItem.values?.pin || '#E8542F'}></l-ripples>
            </div>
          </a>
        )
      } else if(item.i.includes("createMap4x2")){
        return (
          <a
            key={`${item.i}`}
            className='map4x2'
            target='_blank'
            rel='noreferrer'
            href={/Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)?`geo:${correctItem.values.lat},${correctItem.values.lng}?q=${correctItem.values.lat},${correctItem.values.lng}`:/iPhone|iPad|iPod/i.test(navigator.userAgent)?`http://maps.apple.com/place?q=${correctItem.values.lat},${correctItem.values.lng}&ll=${correctItem.values.lat},${correctItem.values.lng}`:`https://www.google.com/maps/search/?api=1&query=${correctItem.values.lat},${correctItem.values.lng}`}
          >
            <MapboxStatic
              key={item.i}
              correctItem={correctItem}
            />
            <div style={{position:"absolute", top:"calc(50% - 25px)", left:"calc(50% - 25px)", pointerEvents:"none"}}>
              <l-ripples size="50" speed="2" color={correctItem.values?.pin || '#E8542F'}></l-ripples>
            </div>
          </a>
        )
      } else if(item.i.includes("createMap2x2")){
        return (
          <a
            key={`${item.i}`}
            className='map2x2'
            target='_blank'
            rel='noreferrer'
            href={/Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)?`geo:${correctItem.values.lat},${correctItem.values.lng}?q=${correctItem.values.lat},${correctItem.values.lng}`:/iPhone|iPad|iPod/i.test(navigator.userAgent)?`http://maps.apple.com/place?q=${correctItem.values.lat},${correctItem.values.lng}&ll=${correctItem.values.lat},${correctItem.values.lng}`:`https://www.google.com/maps/search/?api=1&query=${correctItem.values.lat},${correctItem.values.lng}`}
          >
            <MapboxStatic
              key={item.i}
              correctItem={correctItem}
            />
            <div style={{position:"absolute", top:"calc(50% - 25px)", left:"calc(50% - 25px)", pointerEvents:"none"}}>
              <l-ripples size="50" speed="2" color={correctItem.values?.pin || '#E8542F'}></l-ripples>
            </div>
          </a>
        )
      }
      // ↑ Seguir agregando Componentes desde aquí con 'else if(item.i.includes("")){}'
    
  }

  function convertToSocialLinks(arraySocialLinks){
    const newSocialLinks = [];
    const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
    for(const socialmedia in arraySocialLinks){
      let isEmail = emailRegex.test(arraySocialLinks[socialmedia].value)
      newSocialLinks.push(
        <div id={arraySocialLinks[socialmedia].value} key={arraySocialLinks[socialmedia].value} style={{display: "flex", position:"relative"}}>
          <a href={isEmail?`mailto:${arraySocialLinks[socialmedia].value}`:arraySocialLinks[socialmedia].value.includes("http")?arraySocialLinks[socialmedia].value:`https://${arraySocialLinks[socialmedia].value}`} className='profile-link-href' target='_blank' rel='noreferrer'>
            <img 
              src=
                {isEmail?linkIconEmail:
                arraySocialLinks[socialmedia].value.includes("amazon.")?linkIconAmazon:
                arraySocialLinks[socialmedia].value.includes("beacons.")?linkIconBeacons:
                arraySocialLinks[socialmedia].value.includes("behance.")?linkIconBehance:
                arraySocialLinks[socialmedia].value.includes("buymeacoffee.")?linkIconBuymeacoffee:
                arraySocialLinks[socialmedia].value.includes("deviantart.")?linkIconDeviantart:
                arraySocialLinks[socialmedia].value.includes("discord.")?linkIconDiscord:
                arraySocialLinks[socialmedia].value.includes("dribbble.")?linkIconDribbble:
                arraySocialLinks[socialmedia].value.includes("facebook.")?linkIconFacebook:
                arraySocialLinks[socialmedia].value.includes("github.")?linkIconGithub:
                arraySocialLinks[socialmedia].value.includes("instagram.")?linkIconInstagram:
                arraySocialLinks[socialmedia].value.includes("linkedin.")?linkIconLinkedin:
                arraySocialLinks[socialmedia].value.includes("linktr.ee")?linkIconLinktree:
                arraySocialLinks[socialmedia].value.includes("onlyfans.")?linkIconOnlyfans:
                arraySocialLinks[socialmedia].value.includes("patreon.")?linkIconPatreon:
                (arraySocialLinks[socialmedia].value.includes("pin.it")||arraySocialLinks[socialmedia].value.includes("pinterest."))?linkIconPinterest:
                arraySocialLinks[socialmedia].value.includes("ppol.")?linkIconPpol:
                arraySocialLinks[socialmedia].value.includes("reddit.")?linkIconReddit:
                arraySocialLinks[socialmedia].value.includes("snapchat.")?linkIconSnapchat:
                arraySocialLinks[socialmedia].value.includes("soundcloud.")?linkIconSoundcloud:
                arraySocialLinks[socialmedia].value.includes("spotify.")?linkIconSpotify:
                arraySocialLinks[socialmedia].value.includes("steam")?linkIconSteam:
                (arraySocialLinks[socialmedia].value.includes("telegram")||arraySocialLinks[socialmedia].value.includes("t.me"))?linkIconTelegram:
                arraySocialLinks[socialmedia].value.includes("tiktok.")?linkIconTiktok:
                arraySocialLinks[socialmedia].value.includes("twitch.")?linkIconTwitch:
                (arraySocialLinks[socialmedia].value.includes("twitter.")||arraySocialLinks[socialmedia].value.includes("https://t.co")||arraySocialLinks[socialmedia].value.includes("/x.com"))?linkIconTwitter:
                arraySocialLinks[socialmedia].value.includes("vimeo.")?linkIconVimeo:
                (arraySocialLinks[socialmedia].value.includes("wa.me")||arraySocialLinks[socialmedia].value.includes("whatsapp."))?linkIconWhatsapp:
                arraySocialLinks[socialmedia].value.includes("youtube.")||arraySocialLinks[socialmedia].value.includes("youtu.be/")?linkIconYoutube:
                arraySocialLinks[socialmedia].value.includes("kick.")?linkIconKick:
                arraySocialLinks[socialmedia].value.includes("weibo.")?linkIconWeibo:
                arraySocialLinks[socialmedia].value.includes("threads.net/")?linkIconThreads:
                arraySocialLinks[socialmedia].value.includes("wechat.")?linkIconWechat:
                arraySocialLinks[socialmedia].value.includes("vk.com/")?linkIconVk:
                linkIconWeb}
              alt={arraySocialLinks[socialmedia].value}
              loading="lazy"
            />
          </a>
        </div>
      )
    }
    setSocialLinks(newSocialLinks)
  }
  

  //Cambia estado de edición
  const toggleEditProfile = () => {
    navigate(`/${userId}/edit`)
  }

  const postContactInfo = async () => {
    const errorList = []
    if(!contactName){
      errorList.push("nombre(s)")
    }
    if(!contactLastname){
      errorList.push("apellido(s)")
    }
    if(!contactEmail && !contactPhone){
      errorList.push("correo o teléfono")
    }
    if(errorList.length===0){
      fetch('https://ipinfo.io/json')
      .then((response) => response.json())
      .then((info) => {
        setContactFormError(`${language.confirmSend}`)
        setOpenContactForm(false)
        makeRequest.post("/postUserContact.php",{
          "userName":data.name,
          "userlink":data.userlink,
          "userEmail":data.email,
          "userEmail2":data.email2,
          "name":contactName,
          "lastname":contactLastname,
          "profileEmailContactNotif":data.profileEmailContactNotif,
          "email":contactEmail,
          "phone":contactPhone,
          "company":contactCompany,
          "companyPosition":contactPosition,
          "contactMsg":contactMsg,
          "IP":info.ip,
          "country":info.country,
          "city":info.city,
          "geo":info.loc,
          "lang":navigator.language,
          "isMob":`${/Mobi|Android/i.test(navigator.userAgent)}`
        })
        .catch((err) => {
          console.log(err)
        })
      })
      .catch((err) => {
        console.error('Error al obtener la información de geolocalización:', err);
      })
    } else {
      setContactFormError(`Campo(s) inválido(s): ${errorList.join(", ")}.`)
    }
  }

  function getProfileLayout(){
    return profileLayout
  }

  function validatePhoneNumber(vcfData, phoneNumber) {
    if (phoneNumber) {
      const number = phoneNumber.split(' ');
      if (number.length > 1 && number[1] !== '' && number[1] !== "0") {
        vcfData += `\nTEL:${phoneNumber}`;
      } 
    }
    return vcfData;
  }

  const createVCard = () => {
    let vcfData = `BEGIN:VCARD
VERSION:3.0
EMAIL;CHARSET=UTF-8:${data.email}
TEL:${data.phone}
FN;CHARSET=UTF-8:${data.name} ${data.lastname}
N;CHARSET=UTF-8:${data.lastname};${data.name}
TITLE;CHARSET=UTF-8:${data.position || ""}
ORG;CHARSET=UTF-8:${data.company || ""}
URL;CHARSET=UTF-8:${data.profileURL? data.profileURL.toLowerCase().includes("http")? data.profileURL : `https://${data.profileURL}` : ""}
URL;CHARSET=UTF-8:https://www.ppol.io/u/${userlink}`;
vcfData = validatePhoneNumber(vcfData, data.phone2);
vcfData = validatePhoneNumber(vcfData, data.phone3);
if (data.email2) {
    vcfData += `\nEMAIL;CHARSET=UTF-8:${data.email2}`;
  }
  vcfData += `
END:VCARD`;

    const blob = new Blob([vcfData], {type:"text/vcard"});
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = `${userId}.vcf`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url)
  }

  const verifyKey = async () => {
    setPrivateCodeError(null)
    try{
        const response = makeRequest.get("findProfilePrivateCode.php",{params:{usercode:userlink, code:privateCode}})
        const result = await response;
        if(result.data.isValid){
          setIsPrivateProfile(false)
        }
    } catch {
      setPrivateCodeError("Clave incorrecta")
    }
}

  return (
    isLoading||!showInfo?(
      <Loading/>):(
      <div className="main" >
        <div className="dashboard" style={{background: "#141414"}}>
          <div className='profile' style={{width:window.innerWidth>440?"430px":window.innerWidth<=400?"350px":"390px", marginBottom:0}}>
            <div className="profile-info" key="profile-info">
              <div key="banner-photo" className='banner-photo'>
                <img id="banner-photo" className='banner-photoImg' src={profileImages.banner?profileImages.banner.includes("storage.googleapis") || profileImages.banner.includes("blob") || profileImages.banner.includes("ppol.io/media")?profileImages.banner:`https://ppol.io/media/${userlink}/${profileImages.banner}`:bannerDefaultImg} alt=""/>
                <img id="banner-photo-gradient" className='banner-photoImgGradient' src={profileBannerGradient} alt=""/>
                {currentUser?.userlink===data.userlink?<button className='editBtn' onClick={toggleEditProfile}><img loading="lazy" src={editTextBtn} alt="Editar Perfil"/></button>:null}
                <div id="profile-photo" className='profile-photo'>
                  <img id="profile-photoImg" className='profile-photoImg' src={profileImages.profile?profileImages.profile.includes("storage.googleapis") || profileImages.profile.includes("blob") || profileImages.profile.includes("ppol.io/media")?profileImages.profile:`https://ppol.io/media/${userlink}/${profileImages.profile}`:profileDefaultImg} alt=""/>
                </div>
              </div>
              <div key="profile-separation" className='profile-separationA' style={{display:"flex", alignItems:"center", justifyContent:"center", flexShrink:"0"}}>
                {verified?<img loading="lazy" src={verifiedIcon} alt="✓" style={{height:"24px", width:"24px", zIndex:"1", backgroundColor:"transparent", marginLeft:"25%"}}></img>:<></>}
              </div>
              <div key="profile-name" className='profile-name'>
                <input type='text' spellCheck="false" value={JSON.parse(data.profileBio).name || ""} readOnly maxLength="32" style={{backgroundColor:"transparent"}}></input>
              </div>
              <div key="profile-title" className='profile-title'>
                <input type='text' spellCheck="false" value={JSON.parse(data.profileBio).title || ""} readOnly maxLength="49" style={{backgroundColor:"transparent"}}></input>
              </div>
              <div key="profile-description" className={`profile-description `}>
                <TextareaAutosize className="textarea" spellCheck="false" value={JSON.parse(data.profileBio).description || ""} readOnly maxLength="200" style={{backgroundColor:"transparent"}}/>
              </div>
              <div key="profile-social-links" className='profile-social-links' id='profile-social-links'>
                {socialLinks}
                <div className='profile-social-link-temptext'>
                  <span>Linkea tus redes sociales</span>
                  <img alt="" loading="lazy" src={tempArrowIcon}/>
                </div>
              </div>
              <div key="profile-add-share" className='profile-add-share'>
                {profileContact==="phone"?
                  <button
                    className='profile-add-btn'
                    style={{width:profileSendInfo==="off"?"100%":"50%", marginRight:"0px", backgroundColor:showInfo?JSON.parse(data.profileColor).button:"white", color:showInfo?JSON.parse(data.profileColor).word:"black"}}
                    onClick={createVCard}
                  >
                    {profileContactText || language.btnContact}
                  </button>
                  :
                profileContact==="mail"?
                  <a
                    className='profile-add-btn'
                    style={{width:profileSendInfo==="off"?"100%":"50%", marginRight:"0px", backgroundColor:showBioInfo?JSON.parse(data.profileColor).button:"white", color:showBioInfo?JSON.parse(data.profileColor).word:"black"}} 
                    href={`mailto: ${data.email}`}
                  >
                    {profileContactText || language.btnContact}
                  </a>
                  :
                  <></>
                }
                {profileSendInfo==="on"?
                  <button
                    className='profile-share-btn'
                    style={{width:profileContact==="none"?"100%":"50%", backgroundColor:showBioInfo?JSON.parse(data.profileColor).infoButton||"#D7D7D7":"#D7D7D7", color:showBioInfo?JSON.parse(data.profileColor).infoWord||"black":"black"}}
                    onClick={()=>setOpenContactForm(true)}
                  >
                    {profileSendInfoText || language.btnInformation}
                  </button>
                  :
                  <></>
                }
                {(profileContact==="none" && profileSendInfo==="off")?
                  <div className='separation'>
                    <div
                      className='separationLine'
                      style={{backgroundColor:showInfo?JSON.parse(data.profileColor).button:"white" || "#34353A"}}
                    >
                    </div>
                  </div>
                  :
                  <></>
                }
              </div>
            </div>
            <GridLayout className="layout" layout={getProfileLayout()} cols={4} rowHeight={40} width={window.innerWidth>440?430:window.innerWidth<=400?350:390} containerPadding={window.innerWidth>440?[20,20]:[0,20]} static={false} isResizable={false} isDraggable={true} isBounded={false}>
              {profileLayout.map(item => renderGridItem(item))}
            </GridLayout>
            <div className="profile-created-with-ppol" key="profile-created-with-ppol">
              <span>Creado con</span>
              <a href='https://wa.me/51960525864?text=Hola,%20me%20interesa%20saber%20más%20de%20Ppol' target='_blank' rel='noreferrer'>
                <img loading="lazy" src={ppolLogo} alt="Ppol"></img>
              </a>
            </div>
          </div>
        </div>
        {openContactForm?
          <div className='floatInfoBg'>
            <div className='floatInfoCont'>
              <div className='floatInfoSectionCont'>
                <div className='floatInfoSectionMain'>
                  <div className='floatInfoSectionSplit'>
                    <p className='floatInfoSectionTitle'>{language.header}</p>
                    <button className='floatInfoCloseBtn' onClick={()=>setOpenContactForm(false)}>
                      <img className='floatInfoCloseBtnImg' src={closeCreationBarImg} alt='Cerrar'/>
                    </button>
                  </div>
                </div>
                <div className='floatInfoSectionMain'>
                  <div className='floatInfoSectionFull'>
                    <hr className='floatInfoSectionSeparation'/>
                  </div>
                </div>
                <div className='floatInfoSectionMain'>
                  <div className='floatInfoSectionFull'>
                    <input className='floatInfoSectionTextarea' id='contactFormInputName' placeholder={language.name} maxLength={80} value={contactName} onChange={(e)=>setContactName(e.target.value)}></input>
                  </div>
                </div>
                <div className='floatInfoSectionMain'>
                  <div className='floatInfoSectionFull'>
                    <input className='floatInfoSectionTextarea' id='contactFormInputLastname' placeholder={language.lastname} maxLength={80} value={contactLastname} onChange={(e)=>setContactLastname(e.target.value)}></input>
                  </div>
                </div>
                <div className='floatInfoSectionMain'>
                  <div className='floatInfoSectionFull'>
                    <input className='floatInfoSectionTextarea' id='contactFormInputEmail' placeholder={language.email} maxLength={80} value={contactEmail} onChange={(e)=>setContactEmail(e.target.value)}></input>
                  </div>
                </div>
                <div className='floatInfoSectionMain'>
                  <div className='floatInfoSectionFull'>
                    <input className='floatInfoSectionTextarea' id='contactFormInputPhone' placeholder={language.phone} maxLength={15} value={contactPhone} onChange={(e)=>setContactPhone(e.target.value)}></input>
                  </div>
                </div>
                <div className='floatInfoSectionMain'>
                  <div className='floatInfoSectionFull'>
                    <input className='floatInfoSectionTextarea' id='contactFormInputCompany' placeholder={language.company} maxLength={80} value={contactCompany} onChange={(e)=>setContactCompany(e.target.value)}></input>
                  </div>
                </div>
                <div className='floatInfoSectionMain'>
                  <div className='floatInfoSectionFull'>
                    <input className='floatInfoSectionTextarea' id='contactFormInputPosition' placeholder={language.position} maxLength={80} value={contactPosition} onChange={(e)=>setContactPosition(e.target.value)}></input>
                  </div>
                </div>
                <div className='floatInfoSectionMain'>
                  <div className='floatInfoSectionFull'>
                    <TextareaAutosize className='floatInfoSectionTextarea' id='contactFormInputMsg' placeholder={language.message} maxLength={250} value={contactMsg} onChange={(e)=>setContactMsg(e.target.value)}></TextareaAutosize>
                  </div>
                  <div className='floatInfoSectionSplit'>
                    <div/>
                    <div className='contactFormDomainCounter' style={{color:contactMsg.length>=250?"#e8542f":"#5A5A5A"}}>{contactMsg.length}/250</div>
                  </div>
                </div>
                {contactFormError?
                  <div className='floatInfoSectionMain'>
                    <div className='floatInfoSectionFull'>
                      <p style={{margin:"0"}}>{contactFormError}</p>
                    </div>
                  </div>
                  :
                  <></>
                }
                <div className='floatInfoSectionMain'>
                  <div className='floatInfoSectionFull'>
                    <button className='floatInfoSectionSplittedBtn floatInfoSaveChanges' onClick={()=>postContactInfo()}>{language.btnSend}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          :
          <></>
        }
        {isPrivateProfile==="1" && currentUser?.userlink!==userlink ?
          <div style={{width:"100%", height:"100vh", backgroundColor:"#141414", position:"fixed", zIndex:"10", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
            <img loading="lazy" className='profile-photo' src={profileImages.profile?profileImages.profile.includes("storage.googleapis") || profileImages.profile.includes("blob") || profileImages.profile.includes("ppol.io/media")?profileImages.profile:`https://ppol.io/media/${userlink}/${profileImages.profile}`:profileDefaultImg} alt="" style={{marginBottom:"20px", position:"initial"}}></img>
            <p className='share-metods-subtitle' style={{padding:"0", fontSize:"16px", margin:"20px 0px", maxWidth:"240px"}}>Ingresa el código de acceso para poder ver este perfil.</p>
            <input className='authFormInput' type='text' placeholder='Código' style={{maxWidth:"270px", marginBottom:"10px"}} value={privateCode} onChange={(e)=>setPrivateCode(e.target.value.toUpperCase())}/>
            <button className='authFormBtn' style={{maxWidth:"310px", marginTop:"10px"}} onClick={verifyKey}>Continuar</button>
            <p className='share-metods-subtitle' style={{padding:"0", fontSize:"12px", margin:"0px", marginTop:"20px" ,color:"#E8542F"}}>{privateCodeError}</p>
            <img loading="lazy" src={ppolLogo} alt="Ppol" style={{width:"80px", marginTop:"50px"}}></img>
          </div>
          :
          <></>
        }
      </div>
    )
  )
}

export default Profile