export function getCountryName(code) {
  const countries = {
    AD: "Andorra",
    AE: "Emiratos Árabes Unidos",
    AF: "Afganistán",
    AG: "Antigua y Barbuda",
    AI: "Anguila",
    AL: "Albania",
    AM: "Armenia",
    AO: "Angola",
    AQ: "Antártida",
    AR: "Argentina",
    AS: "Samoa Americana",
    AT: "Austria",
    AU: "Australia",
    AW: "Aruba",
    AX: "Islas Åland",
    AZ: "Azerbaiyán",
    BA: "Bosnia y Herzegovina",
    BB: "Barbados",
    BD: "Bangladés",
    BE: "Bélgica",
    BF: "Burkina Faso",
    BG: "Bulgaria",
    BH: "Baréin",
    BI: "Burundi",
    BJ: "Benín",
    BM: "Bermudas",
    BN: "Brunéi",
    BO: "Bolivia",
    BQ: "Caribe Neerlandés",
    BR: "Brasil",
    BS: "Bahamas",
    BT: "Bután",
    BV: "Isla Bouvet",
    BW: "Botsuana",
    BY: "Bielorrusia",
    BZ: "Belice",
    CA: "Canadá",
    CC: "Islas Cocos",
    CD: "Congo (Kinshasa)",
    CF: "República Centroafricana",
    CG: "Congo (Brazzaville)",
    CH: "Suiza",
    CI: "Costa de Marfil",
    CK: "Islas Cook",
    CL: "Chile",
    CM: "Camerún",
    CN: "China",
    CO: "Colombia",
    CR: "Costa Rica",
    CU: "Cuba",
    CV: "Cabo Verde",
    CW: "Curazao",
    CX: "Isla de Navidad",
    CY: "Chipre",
    CZ: "Chequia",
    DE: "Alemania",
    DJ: "Yibuti",
    DK: "Dinamarca",
    DM: "Dominica",
    DO: "República Dominicana",
    DZ: "Argelia",
    EC: "Ecuador",
    EE: "Estonia",
    EG: "Egipto",
    EH: "Sáhara Occidental",
    ER: "Eritrea",
    ES: "España",
    ET: "Etiopía",
    FI: "Finlandia",
    FJ: "Fiyi",
    FK: "Islas Malvinas",
    FM: "Micronesia",
    FO: "Islas Feroe",
    FR: "Francia",
    GA: "Gabón",
    GB: "Reino Unido",
    GD: "Granada",
    GE: "Georgia",
    GF: "Guayana Francesa",
    GG: "Guernsey",
    GH: "Ghana",
    GI: "Gibraltar",
    GL: "Groenlandia",
    GM: "Gambia",
    GN: "Guinea",
    GP: "Guadalupe",
        GQ: "Guinea Ecuatorial",
        GR: "Grecia",
        GS: "Islas Georgias del Sur y Sandwich del Sur",
        GT: "Guatemala",
        GU: "Guam",
        GW: "Guinea-Bisáu",
        GY: "Guyana",
        HK: "Hong Kong",
        HM: "Islas Heard y McDonald",
        HN: "Honduras",
        HR: "Croacia",
        HT: "Haití",
        HU: "Hungría",
        ID: "Indonesia",
        IE: "Irlanda",
        IL: "Israel",
        IM: "Isla de Man",
        IN: "India",
        IO: "Territorio Británico del Océano Índico",
        IQ: "Irak",
        IR: "Irán",
        IS: "Islandia",
        IT: "Italia",
        JE: "Jersey",
        JM: "Jamaica",
        JO: "Jordania",
        JP: "Japón",
        KE: "Kenia",
        KG: "Kirguistán",
        KH: "Camboya",
        KI: "Kiribati",
        KM: "Comoras",
        KN: "San Cristóbal y Nieves",
        KP: "Corea del Norte",
        KR: "Corea del Sur",
        KW: "Kuwait",
        KY: "Islas Caimán",
        KZ: "Kazajistán",
        LA: "Laos",
        LB: "Líbano",
        LC: "Santa Lucía",
        LI: "Liechtenstein",
        LK: "Sri Lanka",
        LR: "Liberia",
        LS: "Lesoto",
        LT: "Lituania",
        LU: "Luxemburgo",
        LV: "Letonia",
        LY: "Libia",
        MA: "Marruecos",
        MC: "Mónaco",
        MD: "Moldavia",
        ME: "Montenegro",
        MF: "San Martín (Francia)",
        MG: "Madagascar",
        MH: "Islas Marshall",
        MK: "Macedonia del Norte",
        ML: "Malí",
        MM: "Myanmar",
        MN: "Mongolia",
        MO: "Macao",
        MP: "Islas Marianas del Norte",
        MQ: "Martinica",
        MR: "Mauritania",
        MS: "Montserrat",
        MT: "Malta",
        MU: "Mauricio",
        MV: "Maldivas",
        MW: "Malaui",
        MX: "México",
        MY: "Malasia",
        MZ: "Mozambique",
        NA: "Namibia",
        NC: "Nueva Caledonia",
        NE: "Níger",
        NF: "Isla Norfolk",
        NG: "Nigeria",
        NI: "Nicaragua",
        NL: "Países Bajos",
        NO: "Noruega",
        NP: "Nepal",
        NR: "Nauru",
        NU: "Niue",
        NZ: "Nueva Zelanda",
        OM: "Omán",
        PA: "Panamá",
        PE: "Perú",
        PF: "Polinesia Francesa",
        PG: "Papúa Nueva Guinea",
        PH: "Filipinas",
        PK: "Pakistán",
        PL: "Polonia",
        PM: "San Pedro y Miquelón",
        PN: "Islas Pitcairn",
        PR: "Puerto Rico",
        PS: "Territorios Palestinos",
        PT: "Portugal",
        PW: "Palaos",
        PY: "Paraguay",
        QA: "Catar",
        RE: "Reunión",
        RO: "Rumania",
        RS: "Serbia",
        RU: "Rusia",
        RW: "Ruanda",
        SA: "Arabia Saudita",
        SB: "Islas Salomón",
        SC: "Seychelles",
        SD: "Sudán",
        SE: "Suecia",
        SG: "Singapur",
        SH: "Santa Elena, Ascensión y Tristán de Acuña",
        SI: "Eslovenia",
        SJ: "Svalbard y Jan Mayen",
        SK: "Eslovaquia",
        SL: "Sierra Leona",
        SM: "San Marino",
        SN: "Senegal",
        SO: "Somalia",
        SR: "Surinam",
        SS: "Sudán del Sur",
        ST: "Santo Tomé y Príncipe",
        SV: "El Salvador",
        SX: "Sint Maarten",
        SY: "Siria",
        SZ: "Esuatini",
        TC: "Islas Turcas y Caicos",
        TD: "Chad",
        TF: "Territorios Australes Franceses",
        TG: "Togo",
        TH: "Tailandia",
        TJ: "Tayikistán",
        TK: "Tokelau",
        TL: "Timor Oriental",
        TM: "Turkmenistán",
        TN: "Túnez",
        TO: "Tonga",
        TR: "Turquía",
        TT: "Trinidad y Tobago",
        TV: "Tuvalu",
        TW: "Taiwán",
        TZ: "Tanzania",
        UA: "Ucrania",
        UG: "Uganda",
        UM: "Islas menores alejadas de EE. UU.",
        US: "Estados Unidos",
        UY: "Uruguay",
        UZ: "Uzbekistán",
        VA: "Ciudad del Vaticano",
        VC: "San Vicente y las Granadinas",
        VE: "Venezuela",
        VG: "Islas Vírgenes Británicas",
        VI: "Islas Vírgenes de EE. UU.",
        VN: "Vietnam",
        VU: "Vanuatu",
        WF: "Wallis y Futuna",
        WS: "Samoa",
        YE: "Yemen",
        YT: "Mayotte",
        ZA: "Sudáfrica",
        ZM: "Zambia",
        ZW: "Zimbabue",
      };
  
    return countries[code] || "Desconocido";
  }

  export function getFlag(code){
    const flags = {
      AD: "🇦🇩",
      AE: "🇦🇪",
      AF: "🇦🇫",
      AG: "🇦🇬",
      AI: "🇦🇮",
      AL: "🇦🇱",
      AM: "🇦🇲",
      AO: "🇦🇴",
      AQ: "🇦🇶",
      AR: "🇦🇷",
      AS: "🇦🇸",
      AT: "🇦🇹",
      AU: "🇦🇺",
      AW: "🇦🇼",
      AX: "🇦🇽",
      AZ: "🇦🇿",
      BA: "🇧🇦",
      BB: "🇧🇧",
      BD: "🇧🇩",
      BE: "🇧🇪",
      BF: "🇧🇫",
      BG: "🇧🇬",
      BH: "🇧🇭",
      BI: "🇧🇮",
      BJ: "🇧🇯",
      BL: "🇧🇱",
      BM: "🇧🇲",
      BN: "🇧🇳",
      BO: "🇧🇴",
      BQ: "🇧🇶",
      BR: "🇧🇷",
      BS: "🇧🇸",
      BT: "🇧🇹",
      BV: "🇧🇻",
      BW: "🇧🇼",
      BY: "🇧🇾",
      BZ: "🇧🇿",
      CA: "🇨🇦",
      CC: "🇨🇨",
      CD: "🇨🇩",
      CF: "🇨🇫",
      CG: "🇨🇬",
      CH: "🇨🇭",
      CI: "🇨🇮",
      CK: "🇨🇰",
      CL: "🇨🇱",
      CM: "🇨🇲",
      CN: "🇨🇳",
      CO: "🇨🇴",
      CR: "🇨🇷",
      CU: "🇨🇺",
      CV: "🇨🇻",
      CW: "🇨🇼",
      CX: "🇨🇽",
      CY: "🇨🇾",
      CZ: "🇨🇿",
      DE: "🇩🇪",
      DJ: "🇩🇯",
      DK: "🇩🇰",
      DM: "🇩🇲",
      DO: "🇩🇴",
      DZ: "🇩🇿",
      EC: "🇪🇨",
      EE: "🇪🇪",
      EG: "🇪🇬",
      EH: "🇪🇭",
      ER: "🇪🇷",
      ES: "🇪🇸",
      ET: "🇪🇹",
      FI: "🇫🇮",
      FJ: "🇫🇯",
      FK: "🇫🇰",
      FM: "🇫🇲",
      FO: "🇫🇴",
      FR: "🇫🇷",
      GA: "🇬🇦",
      GB: "🇬🇧",
      GD: "🇬🇩",
      GE: "🇬🇪",
      GF: "🇬🇫",
      GG: "🇬🇬",
      GH: "🇬🇭",
      GI: "🇬🇮",
      GL: "🇬🇱",
      GM: "🇬🇲",
      GN: "🇬🇳",
      GP: "🇬🇵",
      GQ: "🇬🇶",
      GR: "🇬🇷",
      GS: "🇬🇸",
      GT: "🇬🇹",
      GU: "🇬🇺",
      GW: "🇬🇼",
      GY: "🇬🇾",
      HK: "🇭🇰",
      HM: "🇭🇲",
      HN: "🇭🇳",
      HR: "🇭🇷",
      HT: "🇭🇹",
      HU: "🇭🇺",
      ID: "🇮🇩",
      IE: "🇮🇪",
      IL: "🇮🇱",
      IM: "🇮🇲",
      IN: "🇮🇳",
      IO: "🇮🇴",
      IQ: "🇮🇶",
      IR: "🇮🇷",
      IS: "🇮🇸",
      IT: "🇮🇹",
      JE: "🇯🇪",
      JM: "🇯🇲",
      JO: "🇯🇴",
      JP: "🇯🇵",
      KE: "🇰🇪",
      KG: "🇰🇬",
      KH: "🇰🇭",
      KI: "🇰🇮",
      KM: "🇰🇲",
      KN: "🇰🇳",
      KP: "🇰🇵",
      KR: "🇰🇷",
      KW: "🇰🇼",
      KY: "🇰🇾",
      KZ: "🇰🇿",
      LA: "🇱🇦",
      LB: "🇱🇧",
      LC: "🇱🇨",
      LI: "🇱🇮",
      LK: "🇱🇰",
      LR: "🇱🇷",
      LS: "🇱🇸",
      LT: "🇱🇹",
      LU: "🇱🇺",
      LV: "🇱🇻",
      LY: "🇱🇾",
      MA: "🇲🇦",
      MC: "🇲🇨",
      MD: "🇲🇩",
      ME: "🇲🇪",
      MF: "🇲🇫",
      MG: "🇲🇬",
      MH: "🇲🇭",
      MK: "🇲🇰",
      ML: "🇲🇱",
      MM: "🇲🇲",
      MN: "🇲🇳",
      MO: "🇲🇴",
      MP: "🇲🇵",
      MQ: "🇲🇶",
      MR: "🇲🇷",
      MS: "🇲🇸",
      MT: "🇲🇹",
      MU: "🇲🇺",
      MV: "🇲🇻",
      MW: "🇲🇼",
      MX: "🇲🇽",
      MY: "🇲🇾",
      MZ: "🇲🇿",
      NA: "🇳🇦",
      NC: "🇳🇨",
      NE: "🇳🇪",
      NF: "🇳🇫",
      NG: "🇳🇬",
      NI: "🇳🇮",
      NL: "🇳🇱",
      NO: "🇳🇴",
      NP: "🇳🇵",
      NR: "🇳🇷",
      NU: "🇳🇺",
      NZ: "🇳🇿",
      OM: "🇴🇲",
      PA: "🇵🇦",
      PE: "🇵🇪",
      PF: "🇵🇫",
      PG: "🇵🇬",
      PH: "🇵🇭",
      PK: "🇵🇰",
      PL: "🇵🇱",
      PM: "🇵🇲",
      PN: "🇵🇳",
      PR: "🇵🇷",
      PS: "🇵🇸",
      PT: "🇵🇹",
      PW: "🇵🇼",
      PY: "🇵🇾",
      QA: "🇶🇦",
      RE: "🇷🇪",
      RO: "🇷🇴",
      RS: "🇷🇸",
      RU: "🇷🇺",
      RW: "🇷🇼",
      SA: "🇸🇦",
      SB: "🇸🇧",
      SC: "🇸🇨",
      SD: "🇸🇩",
      SE: "🇸🇪",
      SG: "🇸🇬",
      SH: "🇸🇭",
      SI: "🇸🇮",
      SJ: "🇸🇯",
      SK: "🇸🇰",
      SL: "🇸🇱",
      SM: "🇸🇲",
      SN: "🇸🇳",
      SO: "🇸🇴",
      SR: "🇸🇷",
      SS: "🇸🇸",
      ST: "🇸🇹",
      SV: "🇸🇻",
      SX: "🇸🇽",
      SY: "🇸🇾",
      SZ: "🇸🇿",
      TC: "🇹🇨",
      TD: "🇹🇩",
      TF: "🇹🇫",
      TG: "🇹🇬",
      TH: "🇹🇭",
      TJ: "🇹🇯",
      TK: "🇹🇰",
      TL: "🇹🇱",
      TM: "🇹🇲",
      TN: "🇹🇳",
      TO: "🇹🇴",
      TR: "🇹🇷",
      TT: "🇹🇹",
      TV: "🇹🇻",
      TW: "🇹🇼",
      TZ: "🇹🇿",
      UA: "🇺🇦",
      UG: "🇺🇬",
      UM: "🇺🇲",
      US: "🇺🇸",
      UY: "🇺🇾",
      UZ: "🇺🇿",
      VA: "🇻🇦",
      VC: "🇻🇨",
      VE: "🇻🇪",
      VG: "🇻🇬",
      VI: "🇻🇮",
      VN: "🇻🇳",
      VU: "🇻🇺",
      WF: "🇼🇫",
      WS: "🇼🇸",
      XK: "🇽🇰",
      YE: "🇾🇪",
      YT: "🇾🇹",
      ZA: "🇿🇦",
      ZM: "🇿🇲",
    }
  return flags[code]
}

export function getFlagEmoji(country) {
  const codePoints = country
    .toUpperCase()
    .split('')
    .map(char =>  127397 + char.charCodeAt());
  return String.fromCodePoint(...codePoints);
}
