import React, { useContext, useState, useEffect } from 'react'
import "../Dashboard/dashboard.css"
import { useQuery } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthContext } from '../../context/authContext'
import Navbar from '../Navbar/Navbar.jsx';
import NavbarMobile from '../Navbar/NavbarMobile.jsx'
import Loading from '../Loading/Loading.jsx';
import { makeRequest } from '../../axios'

function ProfileTutorial() {
    document.title = `Tutoriales | PPOL`
    const [tutorialInfo, setTutorialInfo] = useState([])
    const [tutorialFilteredVideos, setTutorialFilteredVideos] = useState([])
    const [tutorialSearch, setTutorialSearch] = useState("")
    const [tutorialFilter, setTutorialFilter] = useState("")
    let {currentUser} = useContext(AuthContext);
    const userId = useLocation().pathname.split("/")[1];
    const navigate = useNavigate()
    const replaceJSON = '[{"title": "¿Cómo cambiar mi configuración?","videoURL": "https://storage.googleapis.com/ppol-storage/69696969UYSPXwHlxavo0iLjX6AOdyqq0HLGRNBMuhBN9aPmWXK7ueSBTG.mp4","videoPreview": "https://ppol.io/media/69696969/65e5fbdbb67a4.webp","labels": ["A", "B", "C", "D"],"hl": true},{"title": "¿Cómo personalizar los botones de mi perfil?","videoURL": "https://storage.googleapis.com/ppol-storage/69696969tHQ0hM28sdqEHg1x1U2rTVaQHJlaviNYODD00afkDSceTQ7XLQ.mp4","videoPreview": "https://storage.googleapis.com/ppol-storage/69696969ZZ7HzrZLa3SOB6C95LpCectCcstif1saYtiRnlvgdr9mdinc2V.webp","labels": ["A", "C"],"hl": true},{"title": "¿Cómo cambiar mi foto de perfil y portada?","videoURL": "https://storage.googleapis.com/ppol-storage/69696969hpblsbYeorKodmJKpWiC2HjV3dup7WqDAsIJyyPT1SdAtbFFW9.mp4","videoPreview": "https://storage.googleapis.com/ppol-storage/69696969ZZ7HzrZLa3SOB6C95LpCectCcstif1saYtiRnlvgdr9mdinc2V.webp","labels": ["D"],"hl": true},{"title": "¿Cómo cambiar mi contraseña?","videoURL": "https://storage.googleapis.com/ppol-storage/69696969QgdzXkqM0hJ7A2tIlhfQ2ey2bvo2pGIz1Pjn4787WPWNeLZ1Jt.mp4","videoPreview": "https://storage.googleapis.com/ppol-storage/69696969ZZ7HzrZLa3SOB6C95LpCectCcstif1saYtiRnlvgdr9mdinc2V.webp","labels": ["B"],"hl": false},{"title": "¿Cómo entrar a mi cuenta?","videoURL": "https://storage.googleapis.com/ppol-storage/69696969tQtMY7L83XTo0RU2dbDP9xxDrakq3T3czectPNZk3OVF40IvC6.mp4","videoPreview": "https://storage.googleapis.com/ppol-storage/69696969ZZ7HzrZLa3SOB6C95LpCectCcstif1saYtiRnlvgdr9mdinc2V.webp","labels": ["C"],"hl": false}]'
    /*const {isLoading} = useQuery(["user"],() => 
        makeRequest.get("findUserBasicInfo.php", {params: {user: userId}})
        .then((res) => {
            if(currentUser.userlink === res.data.userlink){
                localStorage.setItem("profileImg", JSON.parse(res.data.profileImages).profile)
                makeRequest.get("getTutorialVideos.php", {params: {language: 'es'}})
                .then(({data})=>{
                    //Reemplazar "replaceJSON" por "data"
                    setTutorialInfo(JSON.parse(data))
                })
            } else {
                localStorage.clear()
                navigate("/404")
            }
        })
    )*/

    const { data, isFetching, isLoading } = useQuery(["data"],() => {
        return Promise.all([
            makeRequest.get("findUserBasicInfo.php", {params: {user: userId}}),
            makeRequest.get("getTutorialVideos.php", {params: {language: 'es'}})
        ]).then(([userInfoRes, tutorialVideosRes]) => {
            const userInfo = userInfoRes.data
            const tutorialVideos = JSON.parse(tutorialVideosRes.data)

            setTutorialInfo(tutorialVideos)
            localStorage.setItem("profileImg", JSON.parse(userInfo.profileImages).profile)
            return userInfo
        }).catch(()=>{
            localStorage.clear()
            navigate("/404")
        })
    })
    
    useEffect(()=>{
        if(tutorialFilter || tutorialSearch){
            let filtered = [];
            if(tutorialSearch && tutorialFilter){
                filtered = tutorialInfo.filter( video => video.labels.includes(tutorialFilter) && video.title.toLowerCase().includes(tutorialSearch.toLowerCase()))
            } else if (tutorialSearch){
                filtered = tutorialInfo.filter( video => video.title.toLowerCase().includes(tutorialSearch.toLowerCase()))
            } else if (tutorialFilter){
                filtered = tutorialInfo.filter( video => video.labels.includes(tutorialFilter))
            }
            if(filtered){
                //console.log(filtered)
                setTutorialFilteredVideos(filtered)
            }
        } else {   
            setTutorialFilteredVideos(tutorialInfo)
        }
    },[tutorialInfo, tutorialFilter, tutorialSearch])

    return (
        <div className="main">
            <Navbar activeBtn='tutorial'/>
            {isLoading?
                <Loading/>
                :
                <div className="configuration">
                    <NavbarMobile activeBtn={'tutorial'}/>
                    <section className='main-title-container'>
                        <h1 className='main-title'>Aprende a usar tu tarjeta y perfil PPOL</h1>
                    </section>
                    <section className='infoSectionContainer'>
                        <section className='tutorialSearchCont'>
                            <div className='tutorialSearchBarCont'>
                                <svg className='tutorialSearchBarIcon' width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.625 16.625L13.1812 13.1812M15.0417 8.70833C15.0417 12.2061 12.2061 15.0417 8.70833 15.0417C5.21053 15.0417 2.375 12.2061 2.375 8.70833C2.375 5.21053 5.21053 2.375 8.70833 2.375C12.2061 2.375 15.0417 5.21053 15.0417 8.70833Z" stroke="#141414" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                <input className='tutorialSearchBar' placeholder='Buscar' value={tutorialSearch} onChange={(e)=>setTutorialSearch(e.target.value)}/>
                            </div>
                            <div className='tutorialSearchBtns'>
                                <button className={`tutorialSearchBtn ${tutorialFilter==="profile"?'tutorialSearchBtnActive':''}`} onClick={()=>{tutorialFilter==="profile"?setTutorialFilter(""):setTutorialFilter("profile")}}>Perfil</button>
                                <button className={`tutorialSearchBtn ${tutorialFilter==="config"?'tutorialSearchBtnActive':''}`} onClick={()=>{tutorialFilter==="config"?setTutorialFilter(""):setTutorialFilter("config")}}>Configuración</button>
                                <button className={`tutorialSearchBtn ${tutorialFilter==="metrics"?'tutorialSearchBtnActive':''}`} onClick={()=>{tutorialFilter==="metrics"?setTutorialFilter(""):setTutorialFilter("metrics")}}>Métricas</button>
                                <button className={`tutorialSearchBtn ${tutorialFilter==="others"?'tutorialSearchBtnActive':''}`} onClick={()=>{tutorialFilter==="others"?setTutorialFilter(""):setTutorialFilter("others")}}>Otros</button>
                            </div>
                        </section>
                        <section className='tutorialVideosCont'>
                            {tutorialFilteredVideos.map((item)=>{
                                return(
                                    <div className={`tutorialVideo ${item.hl?'tutorialVideoBig':''}`} key={item.title}>
                                        <video className='tutorialVideoSrc' src={item.videoURL} poster={item.videoPreview} controls/>
                                        <p className='tutorialVideoTitle'>{item.title}</p>
                                    </div>
                                )
                            })}
                        </section>
                    </section>
                </div>
            }
        </div>
    )
}

export default ProfileTutorial