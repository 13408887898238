import React, { useContext, useEffect, useState } from 'react'
import "../Dashboard/dashboard.css"
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { AuthContext } from '../../context/authContext'
import { useQuery } from '@tanstack/react-query';
import { makeRequest } from '../../axios';
import Navbar from '../Navbar/Navbar';
import NavbarMobile from '../Navbar/NavbarMobile'
import Loading from '../Loading/Loading'
import { MapContainer, TileLayer, ZoomControl, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css'
import countriesGeoJSON from '../Utilities/countriesGeoJSON.json'

import { getCountryName, getFlagEmoji } from './countries'
import { getDate, getMonth, getMonthName } from './getDate';

import { ResponsiveBar } from '@nivo/bar'
import { ResponsivePie } from '@nivo/pie'
import { ResponsiveLine }from '@nivo/line'

import ppolLogo from "../../images/ppolLogo.png"
import loadingGif from "../../images/loadingGif.gif"
import profileDefaultImg from "../../images/profileDefaultImg.png"
import accountIcon from "../../images/accountIcon.png"
import beneficiosIcon from "../../images/beneficiosIcon.png"
import configIcon from "../../images/configIcon.png"
import contactsIcon from "../../images/contactsIcon.png"
import metricsIcon from "../../images/metricsIcon.png"
import supportIcon from "../../images/supportIcon.png"
import tutorialIcon from "../../images/tutorialIcon.png"
import profileDotsIcon from "../../images/profileDotsIcon.png"
import webIcon from "../../images/webIcon.png"
import logoutIcon from "../../images/logoutIcon.png"
import shareIcon from "../../images/shareIcon.png"
import ppolWhite from "../../images/ppolWhite.svg"
import metricsLinksIcon from "../../images/metricsLinksIcon.svg"
import metricsTapsIcon from "../../images/metricsTapsIcon.svg"
import metricsViewsIcon from "../../images/metricsViewsIcon.svg"

function ProfileMetrics() {
  document.title = `Métricas | PPOL`
  const [metricInfo, setMetricInfo] = useState({})
  const [tableData, setTableData] = useState([])
  const [pieCountry, setPieCountry] = useState([])
  const [pieCity, setPieCity] = useState([])
  const [barDate, setBarDate] = useState([])
  const [dateRange, setDateRange] = useState(null)
  const [profileImages, setProfileImages] = useState([])
  const [metricsData, setMetricsData] = useState([])
  const [showInfo, setShowInfo] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [contactsData, setContactsData] = useState([])
  const [contactDate, setContactDate] = useState([{
    'id': 'Contactos',
    'color': 'hsl(55, 70%, 50%)',
    'data': []
  }])

  let {currentUser} = useContext(AuthContext);
  const userId = useLocation().pathname.split("/")[1];

  const navigate = useNavigate()

  const onEachCountry = (country, layer) => {
    layer.on({
      click: () => {setSelectedCountry(country.properties.ISO_A2)},
      mouseover: (e) => {
        var feature = e.target;
        feature.setStyle({
          fillColor: '#E8542F',
          fillOpacity: 0.5
        })
      },
      mouseout: (e) => {
        var feature = e.target;
        feature.setStyle({
          fillColor: 'transparent',
          fillOpacity: 1
        })
      }
    })
  }

  const { isLoading, data } = useQuery(['user'], async () => {
    try {
        let resBasicInfo = await makeRequest.get('findUserBasicInfo.php', { params: { user: userId }})
        if(currentUser.userlink === resBasicInfo.data.userlink){
            localStorage.setItem('profileImg', JSON.parse(resBasicInfo.data.profileImages).profile)

            makeRequest.get('getUserContacts.php', { params: { code: resBasicInfo.data.userlink } })
            .then((res) => {
              setContactsData(res.data)
            })
            .catch(()=>{
              setContactsData([])
            })

            /*const resContacts = await makeRequest.get("getUserContacts.php", { params: { code: resBasicInfo.data.userlink } });
            if(resContacts){
              setContactsData(resContacts.data)
            } else {
              setContactsData([])
            }*/
            

            makeRequest.get('getMetrics.php', { params: {user: userId, code: currentUser.userlink }})
            .then((response) => {
                const data = response.data.reverse()
                setDateRange("all")
                let tableInfo = []
                const dataTableFirst = data.length>=100?100:data.length
                for(let i = 0; i<dataTableFirst; i++){
                  tableInfo.push(
                    <tr className='metricVisitorTableDataRowCont' key={data[i].datetime}>
                      <td className='metricVisitorTablePos metricVisitorTableDataRow'>{i+1}</td>
                      <td className='metricVisitorTableCity metricVisitorTableDataRow'>{data[i].city}</td>
                      <td className='metricVisitorTableCountry metricVisitorTableDataRow'>{getCountryName(data[i].country)}</td>
                      <td className='metricVisitorTableDate metricVisitorTableDataRow'>{getDate(data[i].datetime)}</td>
                    </tr>
                  )
                }
                setTableData(tableInfo)
                setMetricsData(data)
                setSelectedCountry('PE')
                setShowInfo(true)
            })
            .catch(() => {
              const data = []
              setDateRange("all")
              setSelectedCountry('PE')
              setTableData([])
              setMetricsData(data)
              setShowInfo(true)
            })
        } else {
            localStorage.clear()
            navigate('/404')
        }
        return resBasicInfo.data
    } catch (error){
        localStorage.clear()
        navigate('/404')
        throw error
    }
  })
  
  useEffect(()=>{
    let visitsNFC = 0
    let visitsLink = 0
    let visitsMobile = 0
    let visitsWeb = 0
    let localVisits = 0
    let filteredData = []
    let filteredContacts = []
    const countryCounter = {}
    const cityCounter = {}
    const barCounter = []
    const contactCounter = []
    const date = Date().split(" ")
    const dateParams = []
    switch(dateRange){
      case "month":
        filteredData = metricsData.filter((row) => row.datetime.includes(`${date[3]}-${getMonth(date[1])}`))
        filteredContacts = contactsData.filter((contact) => contact.datetime.includes(`${date[3]}-${getMonth(date[1])}`))

        for(let i=0; i<filteredData.length; i++){
          filteredData[i].usingCard === "true"?visitsNFC++:visitsLink++;
          filteredData[i].isMobile === "true"?visitsMobile++:visitsWeb++;
          if(filteredData[i].country===selectedCountry)localVisits++;
        }
        setMetricInfo({
          totalVisits: filteredData.length || 0,
          totalNFC: visitsNFC || 0,
          totalLink: visitsLink || 0,
          totalMobile: visitsMobile || 0,
          totalWeb: visitsWeb || 0,
          localVisits: localVisits || 0
        })
        //console.log(filteredData)
        //console.log(date)
        if(filteredData.length>0){
          filteredData.forEach((info)=>{
            const country = getCountryName(info.country);
            countryCounter[country]?countryCounter[country]++:countryCounter[country] = 1;
            const pieDataCountries = Object.keys(countryCounter).map((country)=>({
              "id":country,
              "label":country,
              "value": countryCounter[country],
              "total":filteredData.length
            }))
            pieDataCountries.sort((a, b) => b.value - a.value)
            if(pieDataCountries.length>5){
              const others = pieDataCountries.slice(5)
              const totalOthers = others.reduce((total, country) => total + country.value, 0)
              pieDataCountries.splice(5)
              pieDataCountries.push({id:"Otros","label":"Otros","value":totalOthers,"total":filteredData.length})
            }

            const city = info.city
            if(info.country === selectedCountry){
              cityCounter[city]?cityCounter[city]++:cityCounter[city] = 1
            }
            const pieDataCities = Object.keys(cityCounter).map((city)=>({
              "id":city,
              "label":city,
              "value":cityCounter[city],
              "total":metricInfo.localVisits
            }))
            pieDataCities.sort((a, b) => b.value - a.value)
            if(pieDataCities.length>5){
              const others = pieDataCities.slice(5)
              const totalOthers = others.reduce((total, city) => total + city.value, 0)
              pieDataCities.splice(5)
              pieDataCities.push({id:"Otros","label":"Otros","value":totalOthers})
            }

            const rowDateMonth = `${info.datetime.split(" ")[0].split("-")[2]}/${info.datetime.split(" ")[0].split("-")[1]}`
            if(!barCounter[rowDateMonth]){
              barCounter[rowDateMonth] = {
                "key":rowDateMonth,
                "NFC/QR":0,
                "Link":0
              }
            }
            info.usingCard === "true"?barCounter[rowDateMonth]["NFC/QR"]++:barCounter[rowDateMonth]["Link"]++;

            setPieCity(pieDataCities)
            setPieCountry(pieDataCountries)
          })
        } else {
          setPieCountry([])
          setPieCity([])
        }
        filteredContacts.forEach((info)=>{
          const rowDateMonth = `${info.datetime.split(" ")[0].split("-")[2]}/${info.datetime.split(" ")[0].split("-")[1]}`
          if(!contactCounter[rowDateMonth]){
            contactCounter[rowDateMonth] = {
              "x":rowDateMonth,
              "y":0
            }
          }
          contactCounter[rowDateMonth]['y']++
        })

        setBarDate(Object.values(barCounter).reverse())
        setContactDate([{
          'id': 'Contactos',
          'color': 'hsl(55, 70%, 50%)',
          'data': Object.values(contactCounter).reverse()
        }])
        break
      case "day":
        filteredData = metricsData.filter((row)=>row.datetime.includes(`${date[3]}-${getMonth(date[1])}-${date[2]}`))
        filteredContacts = contactsData.filter((row)=>row.datetime.includes(`${date[3]}-${getMonth(date[1])}-${date[2]}`))
        for(let i=0; i<filteredData.length; i++){
          filteredData[i].usingCard === "true"?visitsNFC++:visitsLink++;
          filteredData[i].isMobile === "true"?visitsMobile++:visitsWeb++;
          if(filteredData[i].country===selectedCountry)localVisits++;
        }
        setMetricInfo({
          totalVisits: filteredData.length || 0,
          totalNFC: visitsNFC || 0,
          totalLink: visitsLink || 0,
          totalMobile: visitsMobile || 0,
          totalWeb: visitsWeb || 0,
          localVisits: localVisits || 0
        })
        //console.log(filteredData)
        if(filteredData.length>0){
          filteredData.forEach((info)=>{
            const country = getCountryName(info.country);
            countryCounter[country]?countryCounter[country]++:countryCounter[country] = 1;
            const pieDataCountries = Object.keys(countryCounter).map((country)=>({
              "id":country,
              "label":country,
              "value": countryCounter[country],
              "total":filteredData.length
            }))
            pieDataCountries.sort((a, b) => b.value - a.value)
            if(pieDataCountries.length>5){
              const others = pieDataCountries.slice(5)
              const totalOthers = others.reduce((total, country) => total + country.value, 0)
              pieDataCountries.splice(5)
              pieDataCountries.push({id:"Otros","label":"Otros","value":totalOthers,"total":filteredData.length})
            }

            const city = info.city
            if(info.country === selectedCountry){
              cityCounter[city]?cityCounter[city]++:cityCounter[city] = 1
            }
            const pieDataCities = Object.keys(cityCounter).map((city)=>({
              "id":city,
              "label":city,
              "value":cityCounter[city],
              "total":metricInfo.localVisits
            }))
            pieDataCities.sort((a, b) => b.value - a.value)
            if(pieDataCities.length>5){
              const others = pieDataCities.slice(5)
              const totalOthers = others.reduce((total, city) => total + city.value, 0)
              pieDataCities.splice(5)
              pieDataCities.push({id:"Otros","label":"Otros","value":totalOthers})
            }

            const rowHour = `${info.datetime.split(" ")[1].split(":")[0]}:00`
            if(!barCounter[rowHour]){
              barCounter[rowHour] = {
                "key":rowHour,
                "NFC/QR":0,
                "Link":0
              }
            }
            info.usingCard === "true"?barCounter[rowHour]["NFC/QR"]++:barCounter[rowHour]["Link"]++;

            setPieCountry(pieDataCountries)
            setPieCity(pieDataCities)
          })
        } else {
          setPieCountry([])
          setPieCity([])
        }
        filteredContacts.forEach((info)=>{
          const rowDateDay = `${info.datetime.split(" ")[1].split(":")[0]}:00`
          if(!contactCounter[rowDateDay]){
            contactCounter[rowDateDay] = {
              "x":rowDateDay,
              "y":0
            }
          }
          contactCounter[rowDateDay]['y']++
        })
        setBarDate(Object.values(barCounter).reverse())
        setContactDate([{
          'id': 'Contactos',
          'color': 'hsl(55, 70%, 50%)',
          'data': Object.values(contactCounter)
        }])
        break
      case "week":
        for(let i=0; i<7; i++){
          const date = new Date()
          date.setDate(date.getDate() - i)
          const splitDate = date + ""
          dateParams.push(`${splitDate.split(" ")[3]}-${getMonth(splitDate.split(" ")[1])}-${splitDate.split(" ")[2]}`)
        }
        filteredData = metricsData.filter((row) => {
          // Verificar si algún elemento del arrayDeStrings está contenido en la propiedad 'value'
          return dateParams.some((dateParam) => row.datetime.includes(dateParam));
        });
        filteredContacts = contactsData.filter((row) => {
          return dateParams.some((dateParam) => row.datetime.includes(dateParam));
        })
        for(let i=0; i<filteredData.length; i++){
          filteredData[i].usingCard === "true"?visitsNFC++:visitsLink++;
          filteredData[i].isMobile === "true"?visitsMobile++:visitsWeb++;
          if(filteredData[i].country===selectedCountry)localVisits++;
        }
        setMetricInfo({
          totalVisits: filteredData.length || 0,
          totalNFC: visitsNFC || 0,
          totalLink: visitsLink || 0,
          totalMobile: visitsMobile || 0,
          totalWeb: visitsWeb || 0,
          localVisits: localVisits || 0
        })
        if(filteredData.length>0){
          filteredData.forEach((info)=>{
            const country = getCountryName(info.country);
            countryCounter[country]?countryCounter[country]++:countryCounter[country] = 1;
            const pieDataCountries = Object.keys(countryCounter).map((country)=>({
              "id":country,
              "label":country,
              "value": countryCounter[country],
              "total":filteredData.length
            }))
            pieDataCountries.sort((a, b) => b.value - a.value)
            if(pieDataCountries.length>5){
              const others = pieDataCountries.slice(5)
              const totalOthers = others.reduce((total, country) => total + country.value, 0)
              pieDataCountries.splice(5)
              pieDataCountries.push({id:"Otros","label":"Otros","value":totalOthers,"total":filteredData.length})
            }

            const city = info.city
            if(info.country === selectedCountry){
              cityCounter[city]?cityCounter[city]++:cityCounter[city] = 1
            }
            const pieDataCities = Object.keys(cityCounter).map((city)=>({
              "id":city,
              "label":city,
              "value":cityCounter[city],
              "total":metricInfo.localVisits
            }))
            pieDataCities.sort((a, b) => b.value - a.value)
            if(pieDataCities.length>5){
              const others = pieDataCities.slice(5)
              const totalOthers = others.reduce((total, city) => total + city.value, 0)
              pieDataCities.splice(5)
              pieDataCities.push({id:"Otros","label":"Otros","value":totalOthers})
            }

            const rowDay = `${info.datetime.split(" ")[0].split("-")[2]}/${getMonthName(info.datetime.split(" ")[0].split("-")[1])}`
            if(!barCounter[rowDay]){
              barCounter[rowDay] = {
                "key":rowDay,
                "NFC/QR":0,
                "Link":0
              }
            }
            info.usingCard === "true"?barCounter[rowDay]["NFC/QR"]++:barCounter[rowDay]["Link"]++;

            setPieCity(pieDataCities)
            setPieCountry(pieDataCountries)
          })
        } else {
          setPieCountry([])
          setPieCity([])
        }
        filteredContacts.forEach((info)=>{
          const rowDateWeek = `${info.datetime.split(" ")[0].split("-")[2]}/${getMonthName(info.datetime.split(" ")[0].split("-")[1])}`
          if(!contactCounter[rowDateWeek]){
            contactCounter[rowDateWeek] = {
              "x":rowDateWeek,
              "y":0
            }
          }
          contactCounter[rowDateWeek]['y']++
        })
        setBarDate(Object.values(barCounter).reverse())
        setContactDate([{
          'id': 'Contactos',
          'color': 'rgb(255,0,0)',
          'data': Object.values(contactCounter)
        }])
        break
      case "semester":
        for(let i=0; i<6; i++){
          const date = new Date()
          date.setMonth(date.getMonth() - i)
          const splitDate = date + ""
          dateParams.push(`${splitDate.split(" ")[3]}-${getMonth(splitDate.split(" ")[1])}-`)
        }
        filteredData = metricsData.filter((row) => {
          // Verificar si algún elemento del arrayDeStrings está contenido en la propiedad 'value'
          return dateParams.some((dateParam) => row.datetime.includes(dateParam));
        });
        filteredContacts = contactsData.filter((row) => {
          return dateParams.some((dateParam) => row.datetime.includes(dateParam));
        })
        for(let i=0; i<filteredData.length; i++){
          filteredData[i].usingCard === "true"?visitsNFC++:visitsLink++;
          filteredData[i].isMobile === "true"?visitsMobile++:visitsWeb++;
          if(filteredData[i].country===selectedCountry)localVisits++;
        }
        setMetricInfo({
          totalVisits: filteredData.length || 0,
          totalNFC: visitsNFC || 0,
          totalLink: visitsLink || 0,
          totalMobile: visitsMobile || 0,
          totalWeb: visitsWeb || 0,
          localVisits: localVisits || 0
        })
        if(filteredData.length>0){
          filteredData.forEach((info)=>{
            const country = getCountryName(info.country);
            countryCounter[country]?countryCounter[country]++:countryCounter[country] = 1;
            const pieDataCountries = Object.keys(countryCounter).map((country)=>({
              "id":country,
              "label":country,
              "value": countryCounter[country],
              "total":filteredData.length
            }))
            pieDataCountries.sort((a, b) => b.value - a.value)
            if(pieDataCountries.length>5){
              const others = pieDataCountries.slice(5)
              const totalOthers = others.reduce((total, country) => total + country.value, 0)
              pieDataCountries.splice(5)
              pieDataCountries.push({id:"Otros","label":"Otros","value":totalOthers,"total":filteredData.length})
            }

            const city = info.city
            if(info.country === selectedCountry){
              cityCounter[city]?cityCounter[city]++:cityCounter[city] = 1
            }
            const pieDataCities = Object.keys(cityCounter).map((city)=>({
              "id":city,
              "label":city,
              "value":cityCounter[city],
              "total":metricInfo.localVisits
            }))
            pieDataCities.sort((a, b) => b.value - a.value)
            if(pieDataCities.length>5){
              const others = pieDataCities.slice(5)
              const totalOthers = others.reduce((total, city) => total + city.value, 0)
              pieDataCities.splice(5)
              pieDataCities.push({id:"Otros","label":"Otros","value":totalOthers})
            }

            const rowMonth = getMonthName(info.datetime.split(" ")[0].split("-")[1])
            if(!barCounter[rowMonth]){
              barCounter[rowMonth] = {
                "key":rowMonth,
                "NFC/QR":0,
                "Link":0
              }
            }
            info.usingCard === "true"?barCounter[rowMonth]["NFC/QR"]++:barCounter[rowMonth]["Link"]++;

            setPieCity(pieDataCities)
            setPieCountry(pieDataCountries)
          })
        } else {
          setPieCountry([])
          setPieCity([])
        }
        filteredContacts.forEach((info)=>{
          const rowDateSemester = getMonthName(info.datetime.split(" ")[0].split("-")[1])
          if(!contactCounter[rowDateSemester]){
            contactCounter[rowDateSemester] = {
              "x":rowDateSemester,
              "y":0
            }
          }
          contactCounter[rowDateSemester]['y']++
        })
        setBarDate(Object.values(barCounter).reverse())
        setContactDate([{
          'id': 'Contactos',
          'color': 'rgb(255,0,0)',
          'data': Object.values(contactCounter)
        }])
        break
      case "all":
        filteredData = [...metricsData]
        filteredContacts = [...contactsData]
        for(let i=0; i<filteredData.length; i++){
          filteredData[i].usingCard === "true"?visitsNFC++:visitsLink++;
          filteredData[i].isMobile === "true"?visitsMobile++:visitsWeb++;
          if(filteredData[i].country===selectedCountry)localVisits++;
        }
        setMetricInfo({
          totalVisits: filteredData.length || 0,
          totalNFC: visitsNFC || 0,
          totalLink: visitsLink || 0,
          totalMobile: visitsMobile || 0,
          totalWeb: visitsWeb || 0,
          localVisits: localVisits || 0
        })
        if(filteredData.length>0){
          filteredData.forEach((info)=>{
            const country = getCountryName(info.country);
            countryCounter[country]?countryCounter[country]++:countryCounter[country] = 1;
            const pieDataCountries = Object.keys(countryCounter).map((country)=>({
              "id":country,
              "label":country,
              "value": countryCounter[country],
              "total":filteredData.length
            }))
            pieDataCountries.sort((a, b) => b.value - a.value)
            if(pieDataCountries.length>5){
              const others = pieDataCountries.slice(5)
              const totalOthers = others.reduce((total, country) => total + country.value, 0)
              pieDataCountries.splice(5)
              pieDataCountries.push({id:"Otros","label":"Otros","value":totalOthers,"total":filteredData.length})
            }

            const city = info.city
            if(info.country === selectedCountry){
              cityCounter[city]?cityCounter[city]++:cityCounter[city] = 1
            }
            const pieDataCities = Object.keys(cityCounter).map((city)=>({
              "id":city,
              "label":city,
              "value":cityCounter[city],
              "total":metricInfo.localVisits
            }))
            pieDataCities.sort((a, b) => b.value - a.value)
            if(pieDataCities.length>5){
              const others = pieDataCities.slice(5)
              const totalOthers = others.reduce((total, city) => total + city.value, 0)
              pieDataCities.splice(5)
              pieDataCities.push({id:"Otros","label":"Otros","value":totalOthers})
            }

            const rowYear = info.datetime.split(" ")[0].split("-")[0]
            if(!barCounter[rowYear]){
              barCounter[rowYear] = {
                "key":rowYear,
                "NFC/QR":0,
                "Link":0
              }
            }
            info.usingCard === "true"?barCounter[rowYear]["NFC/QR"]++:barCounter[rowYear]["Link"]++;

            setPieCity(pieDataCities)
            setPieCountry(pieDataCountries)
          })
        } else {
          setPieCountry([])
          setPieCity([])
        }
        filteredContacts.forEach((info)=>{
          const rowDateYear = info.datetime.split(" ")[0].split("-")[0]
          if(!contactCounter[rowDateYear]){
            contactCounter[rowDateYear] = {
              "x":rowDateYear,
              "y":0
            }
          }
          contactCounter[rowDateYear]['y']++
        })
        setBarDate(Object.values(barCounter))
        setContactDate([{
          'id': 'Contactos',
          'color': 'hsl(55, 70%, 50%)',
          'data': Object.values(contactCounter)
        }])
        break
      default:
        console.log("Felicitaciones! Encontraste un mensaje oculto :)")
      }
  },[dateRange, selectedCountry])

  return (
    <div className="main">
      <Navbar activeBtn={'metrics'}/>
        {isLoading || !showInfo?
          <Loading/>
          :
          <div className='configuration'>
            <NavbarMobile activeBtn={'metrics'}/>
            <section className='main-title-container'>
              <h1 className='main-title'>Métricas</h1>
            </section>
            <section className='metricsSectionCont'>
              <section className="metricsFilterBtnCont">
                <button className={`metricsFilterBtn ${dateRange==="month"?'metricsFilterBtnSelected':''}`} onClick={()=>setDateRange("month")}>
                  Este mes
                </button>
                <button className={`metricsFilterBtn ${dateRange==="day"?'metricsFilterBtnSelected':''}`} onClick={()=>setDateRange("day")}>
                  Hoy
                </button>
                <button className={`metricsFilterBtn ${dateRange==="week"?'metricsFilterBtnSelected':''}`} onClick={()=>setDateRange("week")}>
                  Últimos 7 días
                </button>
                <button className={`metricsFilterBtn ${dateRange==="semester"?'metricsFilterBtnSelected':''}`} onClick={()=>setDateRange("semester")}>
                  Últimos 6 meses
                </button>
                <button className={`metricsFilterBtn ${dateRange==="all"?'metricsFilterBtnSelected':''}`} onClick={()=>setDateRange("all")}>
                  Total
                </button>
              </section>
              <section className='metricsCounterSection'>
                <div className='metricsCounterCont'>
                  <h3 className='metricsCounterTitle'>Vistas totales</h3>
                  <p className='metricsCounterDesc'>{metricInfo.totalVisits}</p>
                  <div className='metricsCounterIcon metricsCounterIconOrange'>
                    <svg width="21" height="26" viewBox="0 0 21 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M1.7781 0.333496C1.16445 0.333496 0.666992 0.842264 0.666992 1.46986V24.1971C0.666992 24.8247 1.16445 25.3335 1.7781 25.3335C2.39175 25.3335 2.88922 24.8247 2.88922 24.1971V14.6498C3.40701 14.3862 4.22559 14.0186 5.10311 13.7943C6.67711 13.3919 7.9403 13.5404 8.63139 14.6005C9.92145 16.5794 12.385 16.7537 14.2809 16.5605C16.2818 16.3567 18.2613 15.6866 19.2619 15.3081C20.1412 14.9755 20.667 14.1217 20.667 13.2208V5.70097C20.667 3.938 18.8424 2.90876 17.3881 3.58328C16.2087 4.13029 14.6418 4.74116 13.226 4.94293C11.7447 5.15403 10.8843 4.86838 10.4804 4.24877C9.02265 2.01262 6.52875 1.74788 4.74137 1.90022C4.05522 1.9587 3.41765 2.0814 2.88922 2.21133V1.46986C2.88922 0.842263 2.39175 0.333496 1.7781 0.333496ZM2.88922 4.56198V12.157C3.37902 11.9525 3.95161 11.746 4.56418 11.5894C6.32352 11.1396 8.94922 10.9911 10.4804 13.3398C11.0122 14.1555 12.2375 14.4847 14.0607 14.299C15.7513 14.1268 17.4917 13.5518 18.4448 13.1939V5.70097C18.4448 5.64933 18.3427 5.63604 18.3059 5.65311C17.0642 6.229 15.2666 6.94682 13.5327 7.19391C11.8643 7.43167 9.78969 7.28634 8.63139 5.50949C7.86688 4.33672 6.47188 4.03333 4.92592 4.16509C4.13816 4.23223 3.40507 4.40879 2.88922 4.56198Z" fill="#F5F5F5"/>
                    </svg>
                  </div>
                </div>
                <div className='metricsCounterCont'>
                  <h3 className='metricsCounterTitle'>Taps & QR</h3>
                  <p className='metricsCounterDesc'>{metricInfo.totalNFC}</p>
                  <div className='metricsCounterIcon metricsCounterIconBlue'>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.83301 0.833496C1.90002 0.833496 0.333017 2.42406 0.333015 4.38613L0.333008 16.2282C0.333007 18.1903 1.90001 19.7809 3.83301 19.7809H7.333C7.97734 19.7809 8.49967 19.2507 8.49967 18.5967C8.49967 17.9426 7.97734 17.4124 7.333 17.4124H3.833C3.18867 17.4124 2.66634 16.8823 2.66634 16.2282V4.38613C2.66634 3.73211 3.18867 3.20192 3.833 3.20192L20.1663 3.20192C20.8107 3.20192 21.333 3.73211 21.333 4.38613L21.333 16.2282C21.333 16.8823 20.8107 17.4124 20.1663 17.4124H14.3361L15.8288 15.892C16.2853 15.4271 16.2853 14.6734 15.8288 14.2085C15.3724 13.7436 14.6324 13.7436 14.176 14.2085L11.5257 16.9079L11.5103 16.9237C10.6043 17.8535 10.6064 19.3552 11.5166 20.2823L14.1701 22.985C14.6262 23.4496 15.3658 23.4496 15.8219 22.985C16.2781 22.5204 16.2781 21.7672 15.8219 21.3026L14.3279 19.7809H20.1663C22.0993 19.7809 23.6663 18.1903 23.6663 16.2282L23.6663 4.38613C23.6663 2.42407 22.0993 0.833497 20.1663 0.833496L3.83301 0.833496Z" fill="#F5F5F5"/>
                    </svg>
                  </div>
                </div>
                <div className='metricsCounterCont'>
                  <h3 className='metricsCounterTitle'>Links</h3>
                  <p className='metricsCounterDesc'>{metricInfo.totalLink}</p>
                  <div className='metricsCounterIcon metricsCounterIconYellow'>
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18.9209 14.6917C19.388 15.1589 20.1454 15.1589 20.6126 14.6917L23.573 11.7313C26.1423 9.16196 26.1423 4.9963 23.573 2.427C21.0037 -0.142298 16.8381 -0.142298 14.2688 2.427L11.3083 5.38745C10.8412 5.8546 10.8412 6.61199 11.3083 7.07913C11.7755 7.54628 12.5328 7.54628 13 7.07913L15.9604 4.11869C17.5954 2.48368 20.2463 2.48368 21.8813 4.11869C23.5163 5.7537 23.5163 8.40457 21.8813 10.0396L18.9209 13C18.4537 13.4672 18.4537 14.2246 18.9209 14.6917Z" fill="#141414"/>
                      <path d="M5.38741 11.3083C5.85456 10.8412 6.61195 10.8412 7.0791 11.3083C7.54624 11.7755 7.54624 12.5329 7.0791 13L4.11865 15.9605C2.48364 17.5955 2.48364 20.2464 4.11865 21.8814C5.75366 23.5164 8.40453 23.5164 10.0395 21.8814L13 18.9209C13.4671 18.4538 14.2245 18.4538 14.6917 18.9209C15.1588 19.3881 15.1588 20.1455 14.6917 20.6126L11.7312 23.5731C9.16193 26.1424 4.99627 26.1424 2.42697 23.5731C-0.142334 21.0038 -0.142334 16.8381 2.42697 14.2688L5.38741 11.3083Z" fill="#141414"/>
                      <path d="M9.1937 16.8063C8.72656 16.3392 8.72656 15.5818 9.1937 15.1146L15.1146 9.19374C15.5817 8.72659 16.3391 8.72659 16.8063 9.19374C17.2734 9.66088 17.2734 10.4183 16.8063 10.8854L10.8854 16.8063C10.4182 17.2735 9.66085 17.2735 9.1937 16.8063Z" fill="#141414"/>
                    </svg>
                  </div>
                </div>
                <div className='metricsCounterCont'>
                  <h3 className='metricsCounterTitle'>Contactos</h3>
                  <p className='metricsCounterDesc'>{contactsData.length}</p>
                  <div className='metricsCounterIcon metricsCounterIconGreen'>
                    <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.8189 4.88889C12.8189 7.58895 10.7036 9.77778 8.09433 9.77778C5.48505 9.77778 3.3698 7.58895 3.3698 4.88889C3.3698 2.18883 5.48505 0 8.09433 0C10.7036 0 12.8189 2.18883 12.8189 4.88889ZM5.70508 4.88889C5.70508 6.25434 6.77479 7.36125 8.09433 7.36125C9.41388 7.36125 10.4836 6.25434 10.4836 4.88889C10.4836 3.52344 9.41388 2.41652 8.09433 2.41652C6.77479 2.41652 5.70508 3.52344 5.70508 4.88889Z" fill="#F5F5F5"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M2.6276 13.7351C4.012 12.6971 5.84724 12.2222 8.09433 12.2222C10.3414 12.2222 12.1767 12.6971 13.5611 13.7351C14.9671 14.7894 15.7615 16.3138 16.1202 18.0986C16.5762 20.3679 14.6742 22 12.8189 22H3.3698C1.51451 22 -0.387522 20.3679 0.0684933 18.0986C0.427133 16.3138 1.22155 14.7894 2.6276 13.7351ZM4.01366 15.7146C3.1914 16.3311 2.65017 17.2581 2.38129 18.5962C2.33543 18.8245 2.39677 19.0335 2.5646 19.2162C2.74429 19.4118 3.03574 19.5556 3.3698 19.5556H12.8189C13.1529 19.5556 13.4444 19.4118 13.6241 19.2162C13.7919 19.0335 13.8532 18.8245 13.8074 18.5962C13.5385 17.2581 12.9973 16.3311 12.175 15.7146C11.3311 15.0818 10.0454 14.6667 8.09433 14.6667C6.14328 14.6667 4.85756 15.0818 4.01366 15.7146Z" fill="#F5F5F5"/>
                      <path d="M24.6302 4.88889C24.6302 7.58895 22.5149 9.77778 19.9057 9.77778C17.2964 9.77778 15.1811 7.58895 15.1811 4.88889C15.1811 2.18883 17.2964 0 19.9057 0C22.5149 0 24.6302 2.18883 24.6302 4.88889ZM17.5164 4.88889C17.5164 6.25434 18.5861 7.36125 19.9057 7.36125C21.2252 7.36125 22.2949 6.25434 22.2949 4.88889C22.2949 3.52344 21.2252 2.41652 19.9057 2.41652C18.5861 2.41652 17.5164 3.52344 17.5164 4.88889Z" fill="#F5F5F5"/>
                      <path d="M16.9954 12.5509C16.2851 12.7312 16.0372 13.5872 16.4387 14.2198L16.4892 14.2994C16.7866 14.768 17.3533 14.9588 17.8848 14.8501C18.4631 14.7319 19.1321 14.6667 19.9057 14.6667C21.8567 14.6667 23.1424 15.0818 23.9863 15.7146C24.8086 16.3311 25.3498 17.2581 25.6187 18.5962C25.6646 18.8245 25.6032 19.0335 25.4354 19.2162C25.2557 19.4118 24.9643 19.5556 24.6302 19.5556H18.7246C18.0722 19.5556 17.5434 20.1028 17.5434 20.7778C17.5434 21.4528 18.0722 22 18.7246 22H24.6302C26.4855 22 28.3875 20.3679 27.9315 18.0986C27.5729 16.3138 26.7785 14.7894 25.3724 13.7351C23.988 12.6971 22.1528 12.2222 19.9057 12.2222C18.8419 12.2222 17.8704 12.3286 16.9954 12.5509Z" fill="#F5F5F5"/>
                    </svg>
                  </div>
                </div>
              </section>
              <section className='metricsDataSection'>

                <div className="metricContainer metricContainerBar">
                  <p className='metricContainerTitle'>Conoce el impacto de tu perfil</p>
                  <p className='metricContainerSubtitle'>Descubre el detalle de las visitas que has tenido {dateRange==="month"?"este mes.":dateRange==="day"?"hoy.":dateRange==="week"?"esta semana.":dateRange==="semester"?"en estos últimos 6 meses.":"en total."}</p>
                  {barDate.length>0?
                    <ResponsiveBar
                      data={barDate}
                      height={330}
                      keys={[
                          'NFC/QR',
                          'Link'
                      ]}
                      indexBy="key"
                      margin={{ top: 30, right: 100, bottom: 20, left: 40 }}
                      padding={0.5}
                      valueScale={{ type: 'linear' }}
                      indexScale={{ type: 'band', round: true }}
                      //colors={['#5A5A5a', '#e8542f']}
                      colors={['#ffd3c2', '#ff6b35']}
                      borderRadius={6}
                      axisTop={null}
                      axisRight={null}
                      axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: 32
                      }}
                      axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: -40
                      }}
                      labelSkipHeight={20}
                      labelTextColor={{
                        from: 'color',
                        modifiers: [
                          [
                            'darker',
                            '3'
                          ]
                        ]
                      }}
                      legends={[
                        {
                          dataFrom: 'keys',
                          anchor: 'bottom-right',
                          direction: 'column',
                          justify: false,
                          translateX: 120,
                          translateY: 0,
                          itemsSpacing: 2,
                          itemWidth: 100,
                          itemHeight: 20,
                          itemDirection: 'left-to-right',
                          itemOpacity: 0.85,
                          symbolSize: 20,
                          effects: [
                            {
                              on: 'hover',
                              style: {
                                itemOpacity: 1
                              }
                            }
                          ]
                        }
                      ]}
                      theme={{
                        "textColor":"#868686"
                      }}
                      tooltip={( data ) => {
                        return (
                          <div className='tooltipContainer'>
                            <div style={{height:"10px", width:"10px", borderRadius:"5px", backgroundColor:data.color}}></div>
                            <div>{`${data.indexValue} - ${data.id}: ${data.value}`}</div>
                          </div>
                        )
                      }}
                    />:
                    <p className='metricContainerError'>No hay datos</p>
                  }
                </div>

                <div className="metricContainer metricContainerBarMobile">
                  <p className='metricContainerTitle'>Conoce el impacto de tu perfil</p>
                  <p className='metricContainerSubtitle'>Descubre el detalle de las visitas que has tenido {dateRange==="month"?"este mes.":dateRange==="day"?"hoy.":dateRange==="week"?"esta semana.":dateRange==="semester"?"en estos últimos 6 meses.":"en total."}</p>
                  {barDate.length>0?
                    <ResponsiveBar
                      data={barDate}
                      height={650}
                      keys={[
                          'NFC/QR',
                          'Link'
                      ]}
                      layout='horizontal'
                      indexBy="key"
                      margin={{ top: 10, right: 20, bottom: 60, left: 50 }}
                      padding={0.5}
                      valueScale={{ type: 'linear' }}
                      indexScale={{ type: 'band', round: true }}
                      //colors={['#5A5A5a', '#e8542f']}
                      colors={['#ffd3c2', '#ff6b35']}
                      borderRadius={6}
                      axisTop={null}
                      axisRight={null}
                      axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: 32
                      }}
                      enableGridX={true}
                      enableGridY={false}
                      axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: -40
                      }}
                      labelSkipWidth={30}
                      labelTextColor={{
                        from: 'color',
                        modifiers: [
                          [
                            'darker',
                            '3'
                          ]
                        ]
                      }}
                      legends={[
                        {
                          dataFrom: 'keys',
                          anchor: 'bottom',
                          direction: 'row',
                          justify: false,
                          translateX: 0,
                          translateY: 60,
                          itemsSpacing: 2,
                          itemWidth: 100,
                          itemHeight: 20,
                          itemDirection: 'left-to-right',
                          itemOpacity: 0.85,
                          symbolSize: 20,
                          effects: [
                            {
                              on: 'hover',
                              style: {
                                itemOpacity: 1
                              }
                            }
                          ]
                        }
                      ]}
                      theme={{
                        "textColor":"#868686"
                      }}
                      tooltip={( data ) => {
                        return (
                          <div className='tooltipContainer'>
                            <div style={{height:"10px", width:"10px", borderRadius:"5px", backgroundColor:data.color}}></div>
                            <div>{`${data.indexValue} - ${data.id}: ${data.value}`}</div>
                          </div>
                        )
                      }}
                    />:
                    <p className='metricContainerError'>No hay datos</p>
                  }
                </div>

                <div className='metricContainer metricMap'>
                  <p className='metricContainerTitle'>Conoce el impacto de tu perfil</p>
                  <p className='metricContainerSubtitle'>Descubre el detalle de las visitas que has tenido {dateRange==="month"?"este mes.":dateRange==="day"?"hoy.":dateRange==="week"?"esta semana.":dateRange==="semester"?"en estos últimos 6 meses.":"en total."}</p>
                  <MapContainer
                    style={{width:"100%", height:"100%", borderRadius:"inherit"}}
                    center={[0,0]}
                    zoom={2}
                    zoomControl={false}
                    maxBounds={[[-90,-180],[90,180]]}
                    maxBoundsViscosity={1.0}
                    minZoom={1.5}
                    >
                      <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        />
                      <GeoJSON
                        data={countriesGeoJSON}
                        style={() => ({
                          color: 'transparent',
                          weight: 0.5,
                          fillColor: 'transparent',
                          fillOpacity: 1,
                        })}
                        onEachFeature={onEachCountry}
                      />
                  </MapContainer>
                </div>

                <div className='metricContainer metricPie'>
                  <p className='metricContainerTitle'>Impacto local</p>
                  <p className='metricContainerSubtitle'>{`${getFlagEmoji(selectedCountry)} ${getCountryName(selectedCountry)}`}</p>
                    {/*pieCountry.length>0?
                      <ResponsivePie
                        data={pieCountry}
                        height={200}
                        margin={{ top: 35, right: 50, bottom: 35, left: 50 }}
                        innerRadius={0.7}
                        padAngle={1}
                        cornerRadius={2}
                        activeOuterRadiusOffset={5}
                        //colors={['#ed6713', '#e53c42', '#c91f61', '#9d2176', '#652b7c', '#1f2e73']}
                        colors={['#a30000', '#c93c20', '#e44f38', '#ff884d', '#ffaf7d', '#f3e5d5']}
                        borderWidth={1}
                        borderColor={{
                          from: 'color',
                          modifiers: [['darker', 0.2]]
                        }}
                        arcLinkLabel={e=>e.id}
                        arcLinkLabelsTextColor="#5A5A5A"
                        arcLinkLabelsThickness={2}
                        arcLinkLabelsColor={{ from: 'color', modifiers: [] }}
                        arcLabelsSkipAngle={10}
                        arcLabelsTextColor={{ theme: 'background' }}
                        arcLinkLabelsSkipAngle={10}
                        arcLinkLabelsDiagonalLength={16}
                        arcLinkLabelsStraightLength={16}
                        isInteractive={true}
                        tooltip={( {datum} ) => {
                          //console.log(datum)
                          return (
                            <div className='tooltipContainer'>
                              <div style={{height:"10px", width:"10px", borderRadius:"5px", backgroundColor:datum.color}}></div>
                              <div>{datum.id}:</div>
                              <div>{datum.value}/{datum.data.total}</div>
                              <div>{`(${((100*datum.value)/datum.data.total).toFixed(2)}%)`}</div>
                            </div>
                          )
                        }}
                      />
                      :
                      <p className='metricContainerError'>No hay datos</p>
                      */}
                    {pieCity.length>0?
                      <ResponsivePie
                        data={pieCity}
                        height={130}
                        margin={{ top: 10, right:10, bottom: 10, left: 160 }}
                        innerRadius={0.7}
                        padAngle={1}
                        cornerRadius={3}
                        activeOuterRadiusOffset={5}
                        //colors={['#a30000', '#c93c20', '#e44f38', '#ff884d', '#ffaf7d', '#f3e5d5']}
                        colors={['#E8542F', '#F06A50', '#F27F72', '#F4968F', '#F5ADA9', '#F7C4C2']}
                        borderWidth={1}
                        borderColor={{
                          from: 'color',
                          modifiers: [['darker', 0.2]]
                        }}
                        enableArcLabels={false}
                        enableArcLinkLabels={false}
                        //arcLinkLabel={e=>e.id+" ("+e.value+")"}
                        arcLinkLabel={e=>e.id}
                        arcLinkLabelsTextColor="#5A5A5A"
                        arcLinkLabelsThickness={2}
                        arcLinkLabelsColor={{ from: 'color', modifiers: [] }}
                        arcLabelsSkipAngle={10}
                        arcLabelsTextColor={{ theme: 'background' }}
                        arcLinkLabelsSkipAngle={10}
                        arcLinkLabelsDiagonalLength={16}
                        arcLinkLabelsStraightLength={16}
                        isInteractive={true}
                        tooltip={( {datum} ) => {
                          return (
                            <div className='tooltipContainer'>
                              <div style={{height:"10px", width:"10px", borderRadius:"5px", backgroundColor:datum.color}}></div>
                              <div>{datum.id}:</div>
                              <div>{datum.value}/{metricInfo.localVisits}</div>
                              <div>{`(${((100*datum.value)/metricInfo.localVisits).toFixed(2)}%)`}</div>
                            </div>
                          )
                        }}
                        legends={[
                          {
                            anchor: 'left',
                            direction: 'column',
                            justify: false,
                            translateX: 0,
                            itemsSpacing: 3,
                            itemWidth: 100,
                            itemHeight: 15,
                            itemTextColor: '#A0A0A0',
                            itemDirection: 'left-to-right',
                            symbolSize: 10,
                            symbolShape: 'circle',
                            translateX: -160,
                            effects:[{
                              on: 'hover',
                              style:{
                                itemTextColor: "#141414"
                              }
                            }]
                          }
                        ]}
                      />:
                      <p className='metricContainerError'>No hay datos</p>
                      }
                </div>

                <div className='metricContainer metricSingleBar'>
                  <p className='metricContainerTitle'>Dispositivos</p>
                  <p className='metricContainerSubtitle'>conectados</p>
                  {metricInfo.totalVisits?
                    <div className='metricComparitionBarCont'>
                      <div className='metricCompatitionTextCont'>
                        <p className='metricComparitionTitle'>Mobile</p>
                        <p className='metricComparitionTitle'>Web</p>
                      </div>
                      <div className='metricCompatitionTextCont'>
                        <p className='metricComparitionDesc'>{metricInfo.totalMobile}</p>
                        <p className='metricComparitionDesc'>{metricInfo.totalWeb}</p>
                      </div>
                      <div className='metricComparitionBar'>
                        <div className='metricComparitionBarLeft' style={{width:`${(100*metricInfo.totalMobile)/metricInfo.totalVisits}%`}}></div>
                        <div className='metricTypeWebMobileBarRight' style={{width:`${(100*metricInfo.totalWeb)/metricInfo.totalVisits}%`}}></div>
                      </div>
                    </div>
                    :
                    <p className='metricContainerError'>No hay datos</p>
                  }
                </div>

                <div className='metricContainer metricVisitorTableSection'>
                  <p className='metricContainerTitle'>Últimas 100</p>
                  <p className='metricContainerSubtitle'>visitas a tu perfil</p>
                  <div className='metricVisitorTableCont'>
                    <table className='metricVisitorTable'>
                      <tbody>
                        <tr>
                          <td className="metricVisitorTablePos metricVisitorTableTitleRow">#</td>
                          <td className="metricVisitorTableCity metricVisitorTableTitleRow">Ciudad</td>
                          <td className="metricVisitorTableCountry metricVisitorTableTitleRow">País</td>
                          <td className="metricVisitorTableDate metricVisitorTableTitleRow">Fecha</td>
                        </tr>
                        {tableData}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className='metricContainer metricContactLine'>
                  <p className='metricContainerTitle'>Contactos</p>
                  <p className='metricContainerSubtitle'>Descubre el detalle de los contactos que has tenido {dateRange==="month"?"este mes.":dateRange==="day"?"hoy.":dateRange==="week"?"esta semana.":dateRange==="semester"?"en estos últimos 6 meses.":"en total."}</p>
                  {contactDate[0].data.length>0?
                    <ResponsiveLine
                      data={contactDate}
                      height={240}
                      margin={{ top: 20, right: 20, bottom: 30, left: 35 }}
                      xScale={{ type: 'point' }}
                      yScale={{
                        type: 'linear',
                        min: 'auto',
                        max: 'auto',
                        stacked: true,
                        reverse: false
                      }}
                      yFormat=" >-.2f"
                      curve='monotoneX'
                      axisTop={null}
                      axisRight={null}
                      axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'transportation',
                        legendOffset: 36,
                        legendPosition: 'middle',
                        truncateTickAt: 0
                      }}
                      colors={['#E8542F', '#F06A50', '#F27F72', '#F4968F', '#F5ADA9', '#F7C4C2']}
                      axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'count',
                        legendOffset: -40,
                        legendPosition: 'middle',
                        truncateTickAt: 0
                      }}
                      enableGridX={true}
                      enableGridY={true}
                      fill={false}
                      pointSize={10}
                      pointColor={{ theme: 'background'}}
                      pointBorderWidth={2}
                      pointBorderColor={{ from: 'serieColor' }}
                      pointLabelYOffset={-12}
                      enableCrosshair={false}
                      enableTouchCrosshair={true}
                      useMesh={true}
                      enablePoints={true}
                      enablePointLabel={false}
                      tooltip={( {point} ) => {
                        return (
                          <div className='tooltipContainer'>
                            <div style={{height:"10px", width:"10px", borderRadius:"5px", backgroundColor:point.serieColor}}></div>
                            <div>{`${point.data.x}: ${point.data.yStacked}`}</div>
                          </div>
                        )
                      }}
                    />:
                    <p className='metricContainerError'>No hay datos</p>
                  }
                </div>

              </section>
            </section>
          </div>
        }
    </div>
  )
}

export default ProfileMetrics