import React, { useState, useContext, useEffect } from 'react'
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { makeRequest } from '../../axios';
import "../Dashboard/dashboard.css"
import { AuthContext } from '../../context/authContext';
import CountryDataJson from '../../countryData';
import QRCode from 'qrcode-generator';
import Navbar from '../Navbar/Navbar.jsx'
import NavbarMobile from '../Navbar/NavbarMobile.jsx'
import Loading from '../Loading/Loading.jsx';

import ppolLogo from "../../images/ppolLogo.png"
import loadingGif from "../../images/loadingGif.gif"
import profileDefaultImg from "../../images/profileDefaultImg.png"
import accountIcon from "../../images/accountIcon.png"
import beneficiosIcon from "../../images/beneficiosIcon.png"
import configIcon from "../../images/configIcon.png"
import contactsIcon from "../../images/contactsIcon.png"
import metricsIcon from "../../images/metricsIcon.png"
import supportIcon from "../../images/supportIcon.png"
import tutorialIcon from "../../images/tutorialIcon.png"
import profileDotsIcon from "../../images/profileDotsIcon.png"
import webIcon from "../../images/webIcon.png"
import logoutIcon from "../../images/logoutIcon.png"
import shareIcon from "../../images/shareIcon.png"
import configImg1 from "../../images/configImg1.png"
import configImg2 from "../../images/configImg2.png"
import ppolWhite from "../../images/ppolWhite.svg"
import swapIcon from "../../images/swapIcon.svg"
import closeCreationBarImg from "../../images/closeCreationBar.svg"
import passwordHide from "../../images/passwordHide.svg";
import passwordShow from "../../images/passwordShow.svg"

function ProfileConfiguration() {
    document.title = `Configuración | PPOL`
    let {currentUser, logout, changeUsername} = useContext(AuthContext);
    const userId = useLocation().pathname.split("/")[1];

    const navigate = useNavigate()

    const [selectedConfigType, setSelectedConfigType] = useState('config')

    const [userlink, setUserlink] = useState("")
    const [username, setUsername] = useState("")
    const [name, setName] = useState("")
    const [lastname, setLastname] = useState("")
    const [email, setEmail] = useState("")
    const [email2, setEmail2] = useState("")
    const [password, setPassword] = useState("")
    const [color, setColor] = useState({word:"#"})
    const [company, setCompany] = useState("")
    const [position, setPosition] = useState("")
    const [phone1, setPhone1] = useState("")
    const [phone2, setPhone2] = useState("")
    const [phone3, setPhone3] = useState("")
    const [profileContact, setProfileContact] = useState("")
    const [profileSendInfo, setProfileSendInfo] = useState("")
    const [profileImages, setProfileImages] = useState([])
    const [errorLine, setErrorLine] = useState(null)
    const [openPasswordForm, setOpenPasswordForm] = useState(false)
    const [passwordFormError, setPasswordFormError] = useState(false)
    const [newPassword, setNewPassword] = useState("")
    const [newPasswordConf, setNewPasswordConf] = useState("")
    const [watchPassword, setWatchPassword] = useState(false)
    const [watchNewPassword, setWatchNewPassword] = useState(false)
    const [watchNewPasswordConf, setWatchNewPasswordConf] = useState(false)
    const [saveContactBtnText, setSaveContactBtnText] = useState("")
    const [sendInfoBtnText, setSendInfoBtnText] = useState("")
    const [selectCountry, setSelectCountry] = useState('')
    const [selectCountry2, setSelectCountry2] = useState('')
    const [selectCountry3, setSelectCountry3] = useState('')
    const [phoneNumber, setPhoneNumber] = useState("")
    const [phoneNumber2, setPhoneNumber2] = useState("")
    const [phoneNumber3, setPhoneNumber3] = useState("")
    const [contactEmailNotif1, setContactEmailNotif1] = useState(false)
    const [contactEmailNotif2, setContactEmailNotif2] = useState(false)
    const [profileURL, setProfileURL] = useState("")
    const [profileSettings, setProfileSettings] = useState({})
    const [isPrivateProfile, setIsPrivateProfile] = useState(false)
    const [openPrivateCode, setOpenPrivateCode] = useState(false)
    const [privateCodesData, setPrivateCodesData] = useState([])
    const [newKey, setNewKey] = useState("")
    const [newKeyDate, setNewKeyDate] = useState("")

    const peruData = CountryDataJson.find(item => item.name_es === 'Perú');
    const restOfCountries = CountryDataJson
    .filter(item => item.name_es !== 'Perú')
    .sort((a, b) => (a.name_es || '').localeCompare(b.name_es || ''));

    const CountryData = [peruData, ...restOfCountries];
    //console.log(phone1)

    const fetchPrivateCodesData = async () => {
        try{
            const response = makeRequest.get("getProfilePrivateCodes.php",{params:{user:userlink}});
            const data = await response;
            console.log(data.data)
            setPrivateCodesData(data.data)
        } catch (error) {
            console.error("Error al obtener los datos:", error)
        }
    }

    const handleAddKey = async () => {
        try{
            const response = makeRequest.post("addProfilePrivateCode.php", {
                "usercode":userlink,
                "code":newKey,
                "datetime":newKeyDate
              })
            const result = await response;
            if(result.data.success){
                fetchPrivateCodesData()
                setNewKey("")
                setNewKeyDate("")
            }
        } catch (error){
            console.error("Error al agregar la clave:" , error)
        }
    }

    const handleDeleteKey = async (id) => {
        try{
            const response = makeRequest.post("deleteProfilePrivateCode.php", {
                "id":id,
              })
            const result = await response
            if(result.data.success){
                fetchPrivateCodesData()
            }
        } catch (error){
            console.error("Error al eliminar al clave:", error)
        }
    }

    useEffect(()=>{
        if(openPrivateCode){
            fetchPrivateCodesData()
        }
    },[openPrivateCode])
    
    const currentCountry = () => {
        const arrayData = CountryData.filter(item => item.phone+'' === phone1.split(' ')[0]);
        const arrayData2 = CountryData.filter(item => item.phone+'' === phone2.split(' ')[0]);
        const arrayData3 = CountryData.filter(item => item.phone+'' === phone3.split(' ')[0]);
        const selectedCountry = arrayData[0] || peruData;
        const selectedCountry2 = arrayData2[0] || peruData;
        const selectedCountry3 = arrayData3[0] || peruData;
        setSelectCountry(selectedCountry.phone || peruData.phone);
        setPhoneNumber(phone1.includes('+') ? phone1.split(' ').slice(1).join(' ') : phone1);
        setSelectCountry2(selectedCountry2.phone || peruData.phone);
        setPhoneNumber2(phone2.includes('+') ? phone2.split(' ').slice(1).join(' ') : phone2);
        setSelectCountry3(selectedCountry3.phone || peruData.phone);
        setPhoneNumber3(phone3.includes('+') ? phone3.split(' ').slice(1).join(' ') : phone3);
    };

    useEffect(() => {
        if(!!phone1){
            currentCountry();
        }
    }, [phone1]);

    const {isLoading, data} = useQuery(["user"],() => 
        //makeRequest.get("/config/" + userId)
        makeRequest.get("findUserBasicInfo.php", {params: {user: userId}})
        .then((res) => {
            if(currentUser.userlink===res.data.userlink){
                setProfileImages(JSON.parse(res.data.profileImages))
                localStorage.setItem("profileImg", JSON.parse(res.data.profileImages).profile)
                setProfileSettings(JSON.parse(res.data.profileSettings))
                makeRequest.get("findUserConfig.php", {params: {user: userId}})
                .then((res) => {
                    setUserlink(res.data.userlink)
                    setUsername(res.data.username)
                    setName(res.data.name)
                    setLastname(res.data.lastname)
                    setEmail(res.data.email)
                    setEmail2(res.data.email2 || "")
                    setColor(JSON.parse(res.data.profileColor))
                    setCompany(res.data.company)
                    setPosition(res.data.position)
                    setPhone1(res.data.phone || "")
                    setPhone2(res.data.phone2 || "")
                    setPhone3(res.data.phone3 || "")
                    setProfileURL(res.data.profileURL)
                    setProfileContact(res.data.profileContact)
                    setProfileSendInfo(res.data.profileSendInfo)
                    setIsPrivateProfile(res.data.isPrivate==="0"?false:true)
                    setSaveContactBtnText(res.data.profileContactText || "")
                    setSendInfoBtnText(res.data.profileSendInfoText || "")
                    setContactEmailNotif1(res.data.profileEmailContactNotif==="1" || res.data.profileEmailContactNotif==="3")
                    setContactEmailNotif2(res.data.profileEmailContactNotif==="2" || res.data.profileEmailContactNotif==="3")
                })
                .catch (err => {
                    throw(err)
                })
            } else {
                localStorage.clear()
                navigate("/404")
            }
            console.log(isLoading)
            return res.data
        })
    )
    
    const generateQR = async (userlink) => {

        /*QRCode.toDataURL(`https://www.ppol.io/u/${res.data.userlink}`, { errorCorrectionLevel: 'H', type:"image/jpeg", quality:1, margin: 2, color: {dark:"#141414", light:"#FAFAFA"}}, function (err, url) {
            if (err) throw err
            var img = document.getElementById('QRImg')
            var container = document.getElementById('QRImgContainer')
            img.src = url
            container.href = url
            container.download = `${res.data.username}_ppol_QR.jpeg`
        })*/

        const qr = QRCode(0, 'H');
        qr.addData(`https://ppol.io/u/${userlink}`)
        qr.make()

        const svg = qr.createSvgTag({scalable: true, cellSize: 16, margin: 1})
        const blob = new Blob([svg], {type:'image/svg+xml'});
        const url = URL.createObjectURL(blob)

        const img = document.getElementById('QRImg')
        if(img){
            img.src = url
        }
    }

    useEffect(()=>{
        if(selectedConfigType==='qr'){
            generateQR(data.userlink)
        }
    },[selectedConfigType])

    const downloadQR = () => {
        const qr = QRCode(0, 'H');
        qr.addData(`https://ppol.io/u/${data.userlink}`)
        qr.make()

        const svg = qr.createSvgTag({scalable: true, cellSize: 2, margin: 8})
        const blobsvg = new Blob([svg], {type:'image/svg+xml'});
        const urlsvg = URL.createObjectURL(blobsvg)
        
        const png = qr.createImgTag(25)
        const img = png.split('"')[1]
        
        const linkElement = document.createElement('a');
        
        linkElement.href = urlsvg;
        linkElement.download = `${data.userlink}.svg`
        linkElement.click()

        linkElement.href = img;
        linkElement.download = `${data.userlink}.gif`
        linkElement.click()
        
        URL.revokeObjectURL(urlsvg)
    }

    const handleConfig = async(e) => {
        e.preventDefault()
        const colorToText = JSON.stringify(color)
        const phone = `${selectCountry} ${phoneNumber}`
        const phone2 = `${selectCountry2} ${phoneNumber2}`
        const phone3 = `${selectCountry3} ${phoneNumber3}`
        const profileEmailContactNotif = contactEmailNotif1?contactEmailNotif2?"3":"1":contactEmailNotif2?"2":"0"
        const privateProfile = isPrivateProfile?"1":"0"

        try{
            await changeUsername({password, newPassword, newPasswordConf, userlink,username,name,lastname,email,email2,phone,phone2,phone3,company,position,colorToText,profileContact,profileSendInfo,saveContactBtnText,sendInfoBtnText,profileEmailContactNotif,profileURL,privateProfile})
            navigate(`/${username}/edit`)
        }catch(error){
            setErrorLine(error.response.data.error)
        }
    }

    function onChangeUsername(e) {
        setUsername(e.target.value.replace(/[^a-z._\d]/, ''));
    }

    const postPasswordInfo = async () => {
        const errorList = []
        if(!password || !newPassword || !newPasswordConf){
          errorList.push("Por favor, rellene todos los campos")
        }
        if(newPassword !== newPasswordConf){
            errorList.push("Las contraseñas no coindicen")
        }
        if(errorList.length===0){
            makeRequest.post("/changeUserPassword.php",{
                "userlink":data.userlink,
                "password":password,
                "newPassword":newPassword
            })
            .then(()=>{
                navigate(`/${username}/edit`)
            })
            .catch(({response}) => {
                setErrorLine(response.data.message)
            })
        } else {
          setErrorLine(`${errorList.join(". ")}.`)
        }
    }

    return (
        <div className="main">
                <Navbar activeBtn={'config'}/>
                {isLoading?
                    <Loading/>
                    :
                    <div className="configuration">
                        <NavbarMobile activeBtn='config'/>
                        <section className='main-title-container'>
                            <h1 className='main-title'>Configuración</h1>
                        </section>
                        <section className='configSectionCont'>
                            <section className='configTypeBtnCont'>
                                <button
                                    className={`configTypeBtn ${selectedConfigType==='config'?'configTypeBtnSelect':''}`}
                                    onClick={()=>setSelectedConfigType('config')}>Configuración</button>
                                <button
                                    className={`configTypeBtn ${selectedConfigType==='button'?'configTypeBtnSelect':''}`}
                                    onClick={()=>setSelectedConfigType('button')}>Botones</button>
                                <button
                                    className={`configTypeBtn ${selectedConfigType==='qr'?'configTypeBtnSelect':''}`}
                                    onClick={()=>setSelectedConfigType('qr')}>QR</button>
                                <button
                                    className={`configTypeBtn ${selectedConfigType==='password'?'configTypeBtnSelect':''}`}
                                    onClick={()=>setSelectedConfigType('password')}>Contraseña</button>
                            </section>

                            {selectedConfigType==='config'?
                                <section className='configTypeSectionCont'>
                                    <h3 className='configTypeTitle'>Configuración de la cuenta</h3>
                                    <div className='configTypeSection'>
                                        <div className='configTypeInputCont'>
                                            <span className='configTypeInputTitle'>Dominio</span>
                                            <span className='configTypeInputFloatText'>{"ppol.io/"}</span>
                                            <input className='configTypeInput configTypeInputFloat' placeholder='Usuario' maxLength="30" type='text' value={username} onChange={(e) => onChangeUsername(e)} readOnly={!profileSettings.canChangeUsername}/>
                                        </div>
                                        <div className='configTypeInputCont'>
                                            <span className='configTypeInputTitle'>Nombre</span>
                                            <input className='configTypeInput' placeholder='Nombre' maxLength="90" type='text' value={name} onChange={(e) => setName(e.target.value)} readOnly={!profileSettings.canChangeName}/>
                                        </div>
                                        <div className='configTypeInputCont'>
                                            <span className='configTypeInputTitle'>Apellido</span>
                                            <input className='configTypeInput' placeholder='Apellido' maxLength="90" type='text' value={lastname} onChange={(e) => setLastname(e.target.value)} readOnly={!profileSettings.canChangeLastname}/>
                                        </div>
                                    </div>

                                    <div className='configTypeSectionSeparator'/>

                                    <div className='configTypeSection'>
                                        <div className='configTypeInputCont'>
                                            <span className='configTypeInputTitle'>Correo 1</span>
                                            <span
                                                className='configTypeCheckCont'
                                            >
                                                <input
                                                    type="checkbox"
                                                    checked={contactEmailNotif1}
                                                    onChange={()=>{
                                                        if(email){
                                                            setContactEmailNotif1(!contactEmailNotif1)
                                                        }
                                                    }}
                                                    disabled={!profileSettings.canChangeEmails}
                                                />
                                                Deseo recibir las notificaciones de "Nuevo contacto" a este correo.
                                            </span>
                                            <input
                                                className='configTypeInput'
                                                placeholder='Correo 1'
                                                maxLength="90"
                                                type='email'
                                                value={email}
                                                onChange={(e) => {
                                                    setEmail(e.target.value)
                                                    if(e.target.value.length===0){
                                                        setContactEmailNotif1(false)
                                                    }
                                                }}
                                                readOnly={!profileSettings.canChangeEmails}
                                            />
                                        </div>
                                        <div className='configTypeInputCont'>
                                            <span className='configTypeInputTitle'>Correo 2</span>
                                            <span
                                                className='configTypeCheckCont'
                                            >
                                                <input
                                                    type="checkbox"
                                                    checked={contactEmailNotif2}
                                                    onChange={()=>{
                                                        if(email2){
                                                            setContactEmailNotif2(!contactEmailNotif2)
                                                        }
                                                    }}
                                                    disabled={!profileSettings.canChangeEmails}
                                                />
                                                Deseo recibir las notificaciones de "Nuevo contacto" a este correo.
                                            </span>
                                            <input
                                                className='configTypeInput'
                                                placeholder='Correo2'
                                                maxLength="90"
                                                type='email'
                                                value={email2}
                                                onChange={(e) => {
                                                    setEmail2(e.target.value)
                                                    if(e.target.value.length===0){
                                                        setContactEmailNotif2(false)
                                                    }
                                                }}
                                                readOnly={!profileSettings.canChangeEmails}
                                            />
                                        </div>

                                    </div>

                                    <div className='configTypeSectionSeparator'/>

                                    <div className='configTypeSection'>
                                        <div className='configTypeInputCont'>
                                            <span className='configTypeInputTitle'>Teléfono 1</span>
                                            <div className='configTypeDoubleInput'>
                                                <select className='configTypeInput configTypeSelect' name="cod-phone" value={selectCountry} onChange={(e)=>setSelectCountry(e.target.value)} disabled={!profileSettings.canChangePhones}>
                                                    {CountryData.map((item)=>{
                                                        return(
                                                            <option className='configTypeSelect' value={item.phone} key={item.id}>{item.emoji} {item.name_es} {item.phone}</option>
                                                        )
                                                    })}
                                                </select>
                                                <input className='configTypeInput configTypeInputNumber' placeholder='Teléfono' maxLength="20" type='text' value={phoneNumber} readOnly={!profileSettings.canChangePhones}
                                                    onChange={(e) => {
                                                        const value = e.target.value.replace(/\D/g, ''); 
                                                        setPhoneNumber(value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className='configTypeInputCont'>
                                            <span className='configTypeInputTitle'>Teléfono 2</span>
                                            <div className='configTypeDoubleInput'>
                                                <select className='configTypeInput configTypeSelect' name="cod-phone" value={selectCountry2} onChange={(e)=>setSelectCountry2(e.target.value)} disabled={!profileSettings.canChangePhones}>
                                                    {CountryData.map((item)=>{
                                                        return(
                                                            <option className='configTypeSelect' value={item.phone} key={item.id}>{item.emoji} {item.name_es} {item.phone}</option>
                                                        )
                                                    })}
                                                </select>
                                                <input className='configTypeInput configTypeInputNumber' placeholder='Teléfono' maxLength="20" type='text' value={phoneNumber2} readOnly={!profileSettings.canChangePhones}
                                                    onChange={(e) => {
                                                        const value = e.target.value.replace(/\D/g, ''); 
                                                        setPhoneNumber2(value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className='configTypeInputCont'>
                                            <span className='configTypeInputTitle'>Teléfono 3</span>
                                            <div className='configTypeDoubleInput'>
                                                <select className='configTypeInput configTypeSelect' name="cod-phone" value={selectCountry3} onChange={(e)=>setSelectCountry3(e.target.value)} disabled={!profileSettings.canChangePhones}>
                                                    {CountryData.map((item)=>{
                                                        return(
                                                            <option className='configTypeSelect' value={item.phone} key={item.id}>{item.emoji} {item.name_es} {item.phone}</option>
                                                        )
                                                    })}
                                                </select>
                                                <input className='configTypeInput configTypeInputNumber' placeholder='Teléfono' maxLength="20" type='text' value={phoneNumber3} readOnly={!profileSettings.canChangePhones}
                                                    onChange={(e) => {
                                                        const value = e.target.value.replace(/\D/g, ''); 
                                                        setPhoneNumber3(value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='configTypeSectionSeparator'/>

                                    <div className='configTypeSection'>
                                        <div className='configTypeInputCont'>
                                            <span className='configTypeInputTitle'>Compañía</span>
                                            <input className='configTypeInput' placeholder='Compañía' maxLength="80" type='text' value={company} onChange={(e) => setCompany(e.target.value)} readOnly={!profileSettings.canChangeCompany}/>
                                        </div>
                                        <div className='configTypeInputCont'>
                                            <span className='configTypeInputTitle'>Posición</span>
                                            <input className='configTypeInput' placeholder='Posición' maxLength="80" type='text' value={position} onChange={(e) => setPosition(e.target.value)} readOnly={!profileSettings.canChangePosition}/>
                                        </div>
                                        <div className='configTypeInputCont'>
                                            <span className='configTypeInputTitle'>Sitio web</span>
                                            <span className='configTypeInputDesc'>Coloca el link que deseas que vean las personas que guarden tu contacto.</span>
                                            <input className='configTypeInput' placeholder='Web' maxLength="500" type='text' value={profileURL} onChange={(e) => setProfileURL(e.target.value)} readOnly={!profileSettings.canChangeURL}/>
                                        </div>
                                    </div>

                                    <div className='configTypeSectionSeparator'/>

                                    <div className='configTypeSection'>
                                        <div className='configTypeInputCont'>
                                            <span className='configTypeInputTitle'>Privacidad</span>
                                            <span
                                                className='configTypeCheckCont'
                                            >
                                                <input
                                                    type="checkbox"
                                                    checked={isPrivateProfile}
                                                    onChange={()=>{
                                                        setIsPrivateProfile(!isPrivateProfile)
                                                    }}
                                                    disabled={!profileSettings.canChangePassword}
                                                />
                                                Perfil privado - Ingresa una contraseña para que puedan ver tu perfil
                                            </span>
                                        </div>
                                        {isPrivateProfile?
                                            <div className='configTypeInputCont'>
                                                <span className='configTypeInputDesc'>Gestiona tus claves de acceso <span style={{textDecoration:"underline", cursor:"pointer"}} onClick={()=>setOpenPrivateCode(true)}>AQUÍ</span></span>
                                            </div>
                                            :
                                            <></>
                                        }
                                    </div>

                                </section>
                                :
                            selectedConfigType==='button'?
                                <section className='configTypeSectionCont'>
                                    <h3 className='configTypeTitle'>Botones del perfil</h3>
                                    <div className='configTypeSection'>
                                        <div className='configTypeInputCont'>
                                            <span className='configTypeInputTitle'>Vista Previa</span>
                                            <div className='configTypeBlackCont'>
                                                {(profileContact==="phone" || profileContact==="mail") && <div className='configTypeConfigBtn' style={{width:profileSendInfo==="off"?"100%":"50%", backgroundColor:color.button, color:color.word}}>{saveContactBtnText || "Guardar contacto"}</div>}
                                                {profileSendInfo==="on" && <div className='configTypeConfigBtn' style={{width:profileContact==="none"?"100%":"50%", backgroundColor:color.infoButton || "#D7D7D7", color:color.infoWord || "#000000"}}>{sendInfoBtnText || "Envía tu información"}</div>}
                                            </div>
                                            <div className='configTypeSectionRow'>
                                                <span className='configTypeCheckCont'>Intercambiar colores</span>
                                                <button
                                                    className='configChangeBtn'
                                                    disabled={!profileSettings.canChangeButtonsConfig}
                                                    onClick={()=>{
                                                        const btn = color.button;
                                                        const wrd = color.word;
                                                        const infoBtn = color.infoButton || "#D7D7D7"
                                                        const infoWrd = color.infoWord || "#000000"
                                                        setColor({...color, button:infoBtn, word:infoWrd, infoButton:btn, infoWord:wrd})
                                                    }}
                                                >
                                                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M9.37502 9.375V11.25H11.6805C11.1565 11.8412 10.5128 12.3141 9.79203 12.6374C9.07126 12.9606 8.28996 13.1269 7.50002 13.125C6.00868 13.1234 4.57889 12.5302 3.52435 11.4757C2.46981 10.4211 1.87665 8.99134 1.87501 7.5H1.47596e-05C-0.00301298 9.02502 0.459855 10.5146 1.32666 11.7693C2.19347 13.024 3.42286 13.9841 4.85028 14.5209C6.2777 15.0577 7.83503 15.1457 9.31384 14.7731C10.7926 14.4005 12.1224 13.5851 13.125 12.436V15H15V9.375H9.37502Z" fill="#8D8D8D"/>
                                                        <path d="M7.5 0C6.43444 0.00548835 5.38216 0.236843 4.41256 0.678801C3.44296 1.12076 2.57809 1.76328 1.875 2.56397V0H0V5.625H5.625V3.75H3.3195C3.84347 3.15884 4.48721 2.68592 5.20798 2.36264C5.92875 2.03937 6.71006 1.87314 7.5 1.875C8.99134 1.87664 10.4211 2.4698 11.4757 3.52433C12.5302 4.57887 13.1234 6.00866 13.125 7.5H15C14.9977 5.51158 14.2068 3.60525 12.8008 2.19922C11.3948 0.793192 9.48842 0.00228275 7.5 0Z" fill="#8D8D8D"/>
                                                    </svg>
                                                </button>
                                            </div>
                                            
                                        </div>
                                        <div className='configTypeSectionSeparator'/>
                                        <div className='configTypeInputCont'>
                                            <span className='configTypeInputTitle'>Botón 1</span>
                                            <span className='configTypeCheckCont'>
                                                Configura el botón que permite a las personas guardar tu contacto
                                            </span>
                                            <input className='configTypeInput' placeholder='Guardar contacto' maxLength="25" type='text' value={saveContactBtnText} onChange={(e) => setSaveContactBtnText(e.target.value)} disabled={!profileSettings.canChangeButtonsConfig}/>
                                            <div className='configTypeColorSelectCont'>
                                                <div className='configTypeColorSelectContOverflow' style={{outlineColor:color.button}}>
                                                    <input className='configTypeColorSelect' type='color' value={color.button} onChange={(e) => setColor({...color, button:e.target.value})} disabled={!profileSettings.canChangeButtonsConfig}/>
                                                </div>
                                                <div className='configTypeColorSelectContOverflow' style={{outlineColor:color.word}}>
                                                    <input className='configTypeColorSelect' type='color' value={color.word} onChange={(e) => setColor({...color, word:e.target.value})} disabled={!profileSettings.canChangeButtonsConfig}/>
                                                </div>
                                            </div>
                                            <div className='configTypeSectionRow'>
                                                <span className='configTypeCheckCont'>Intercambiar colores</span>
                                                <button
                                                    className='configChangeBtn'
                                                    onClick={()=>{
                                                        const btn = color.button;
                                                        const wrd = color.word;
                                                        setColor({...color, button:wrd, word:btn})
                                                    }}
                                                    disabled={!profileSettings.canChangeButtonsConfig}
                                                >
                                                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M9.37502 9.375V11.25H11.6805C11.1565 11.8412 10.5128 12.3141 9.79203 12.6374C9.07126 12.9606 8.28996 13.1269 7.50002 13.125C6.00868 13.1234 4.57889 12.5302 3.52435 11.4757C2.46981 10.4211 1.87665 8.99134 1.87501 7.5H1.47596e-05C-0.00301298 9.02502 0.459855 10.5146 1.32666 11.7693C2.19347 13.024 3.42286 13.9841 4.85028 14.5209C6.2777 15.0577 7.83503 15.1457 9.31384 14.7731C10.7926 14.4005 12.1224 13.5851 13.125 12.436V15H15V9.375H9.37502Z" fill="#8D8D8D"/>
                                                        <path d="M7.5 0C6.43444 0.00548835 5.38216 0.236843 4.41256 0.678801C3.44296 1.12076 2.57809 1.76328 1.875 2.56397V0H0V5.625H5.625V3.75H3.3195C3.84347 3.15884 4.48721 2.68592 5.20798 2.36264C5.92875 2.03937 6.71006 1.87314 7.5 1.875C8.99134 1.87664 10.4211 2.4698 11.4757 3.52433C12.5302 4.57887 13.1234 6.00866 13.125 7.5H15C14.9977 5.51158 14.2068 3.60525 12.8008 2.19922C11.3948 0.793192 9.48842 0.00228275 7.5 0Z" fill="#8D8D8D"/>
                                                    </svg>
                                                </button>
                                            </div>
                                            <div className='configTypeSectionRow'>
                                                <span className='configTypeCheckCont'>Medio de contacto</span>
                                                <div className='configTypeChangeBtnCont'>
                                                    <button className={`configChangeBtn ${profileContact==='phone'?'configChangeBtnSelected':''}`} onClick={()=>setProfileContact("phone")} disabled={!profileSettings.canChangeButtonsConfig}>
                                                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M16.2904 11.7118L14.7725 10.1939C14.32 9.74499 13.7092 9.49192 13.0719 9.48921C12.4345 9.4865 11.8216 9.73436 11.3654 10.1794C11.1219 10.4242 10.8007 10.5766 10.457 10.6102C10.1133 10.6438 9.76871 10.5566 9.48239 10.3636C8.35234 9.60941 7.38154 8.64047 6.62518 7.51187C6.43555 7.22236 6.3515 6.87634 6.38719 6.5321C6.42288 6.18785 6.57614 5.86644 6.82114 5.62199C7.2621 5.16527 7.50676 4.55408 7.50277 3.91923C7.49877 3.28438 7.24643 2.67632 6.79975 2.22518L5.28179 0.70721C4.82733 0.254307 4.21189 0 3.57029 0C2.92869 0 2.31325 0.254307 1.85879 0.70721L1.42203 1.14466C-0.854911 3.42162 -0.684486 8.20876 4.05293 12.9435C6.90945 15.8007 9.78461 16.9971 12.1161 16.9971C12.8024 17.02 13.4863 16.906 14.1282 16.6619C14.77 16.4177 15.3568 16.0483 15.8544 15.5751L16.2918 15.1376C16.7456 14.6829 17.0003 14.0668 17 13.4244C16.9997 12.7821 16.7445 12.1661 16.2904 11.7118ZM15.3148 14.162L14.8774 14.5994C13.0834 16.3934 9.1236 16.0656 5.02718 11.9685C0.930762 7.87135 0.60233 3.90875 2.39628 2.11479L2.83028 1.67802C3.02569 1.48332 3.2903 1.374 3.56615 1.374C3.842 1.374 4.1066 1.48332 4.30201 1.67802L5.81997 3.196C6.01183 3.38882 6.12073 3.64899 6.12344 3.92099C6.12615 4.19298 6.02244 4.45527 5.83446 4.65187C5.36822 5.12108 5.07739 5.73627 5.01069 6.39437C4.94399 7.05247 5.10547 7.7135 5.46808 8.26672C6.32749 9.55279 7.43263 10.6563 8.71997 11.5138C9.27152 11.8765 9.93082 12.0389 10.5877 11.974C11.2446 11.9091 11.8594 11.6207 12.3293 11.1571C12.5255 10.9669 12.7885 10.8612 13.0618 10.8629C13.3351 10.8646 13.5968 10.9734 13.7906 11.166L15.3086 12.684C15.4064 12.7804 15.4842 12.8953 15.5375 13.0219C15.5908 13.1485 15.6186 13.2843 15.6191 13.4217C15.6197 13.5591 15.5931 13.6952 15.5409 13.8222C15.4887 13.9493 15.4118 14.0647 15.3148 14.162Z" fill={profileContact==='phone'?'#E8542F':'#8D8D8D'}/>
                                                        </svg>
                                                    </button>
                                                    <button className={`configChangeBtn ${profileContact==='mail'?'configChangeBtnSelected':''}`} onClick={()=>setProfileContact("mail")} disabled={!profileSettings.canChangeButtonsConfig}>
                                                        <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M16.2424 0.0167526L8.99647 0.0335052L1.75059 0H1.74706C0.772941 0 0 0.867783 0 1.95335V11.0466C0 12.1322 0.772941 13 1.74706 13H16.2529C17.2271 13 18 12.1322 18 11.0466V1.95335C18 0.867783 17.2341 0.0167526 16.2424 0.0167526ZM15.6741 1.35696L9.20118 6.97577L9.17647 6.99588L9.15529 7.01933C9.07765 7.09974 8.98588 7.10979 8.93647 7.10979C8.88706 7.10979 8.79529 7.09639 8.72118 7.01263L8.7 6.98918L2.51294 1.33015L8.99647 1.37036L15.6741 1.35696ZM16.2529 11.6598H1.74706C1.62353 11.6598 1.41176 11.4387 1.41176 11.0466V2.16778L7.68 7.91727C7.99765 8.25232 8.44588 8.44665 8.92235 8.45335H8.94353C9.41294 8.45335 9.85059 8.26907 10.1718 7.94742L16.5953 2.36546V11.0466C16.5882 11.4387 16.3765 11.6598 16.2529 11.6598Z" fill={profileContact==='mail'?'#E8542F':'#8D8D8D'}/>
                                                        </svg>
                                                    </button>
                                                    <button className={`configChangeBtn ${profileContact==='none'?'configChangeBtnSelected':''}`} onClick={()=>setProfileContact("none")} disabled={!profileSettings.canChangeButtonsConfig}>
                                                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M8.5 0C6.22133 0 4.15381 0.907228 2.62581 2.37264C2.52063 2.43377 2.43296 2.52096 2.37126 2.62581C0.906225 4.15374 0 6.22167 0 8.5C0 13.186 3.81397 17 8.5 17C10.7738 17 12.8375 16.0969 14.3645 14.637C14.4781 14.5733 14.5719 14.4795 14.6357 14.3659C16.0963 12.8387 17 10.7745 17 8.5C17 3.81397 13.186 0 8.5 0ZM8.5 1.41667C12.4204 1.41667 15.5833 4.57959 15.5833 8.5C15.5833 10.2045 14.9849 11.7648 13.9868 12.9852L4.01481 3.01318C5.23525 2.01509 6.79549 1.41667 8.5 1.41667ZM3.01318 4.01481L12.9852 13.9868C11.7648 14.9849 10.2045 15.5833 8.5 15.5833C4.57959 15.5833 1.41667 12.4204 1.41667 8.5C1.41667 6.79549 2.01509 5.23525 3.01318 4.01481Z" fill={profileContact==='none'?'#E8542F':'#8D8D8D'}/>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='configTypeSectionSeparator'/>
                                        <div className='configTypeInputCont'>
                                            <span className='configTypeInputTitle'>Botón 2</span>
                                            <span className='configTypeCheckCont'>
                                                Configura el botón que permite a las personas enviarte información
                                            </span>
                                            <input className='configTypeInput' placeholder='Envía tu información' maxLength="25" type='text' value={sendInfoBtnText} onChange={(e) => setSendInfoBtnText(e.target.value)} disabled={!profileSettings.canChangeButtonsConfig}/>
                                            <div className='configTypeColorSelectCont'>
                                                <div className='configTypeColorSelectContOverflow' style={{outlineColor:color.infoButton || "#D7D7D7"}}>
                                                    <input className='configTypeColorSelect' placeholder='' type='color' value={color.infoButton || "#D7D7D7"} onChange={(e) => setColor({...color, infoButton:e.target.value})} disabled={!profileSettings.canChangeButtonsConfig}/>
                                                </div>
                                                <div className='configTypeColorSelectContOverflow' style={{outlineColor:color.infoWord || "#000000"}}>
                                                    <input className='configTypeColorSelect' placeholder='' type='color' value={color.infoWord || "#000000"} onChange={(e) => setColor({...color, infoWord:e.target.value})} disabled={!profileSettings.canChangeButtonsConfig}/>
                                                </div>
                                            </div>
                                            <div className='configTypeSectionRow'>
                                                <span className='configTypeCheckCont'>Intercambiar colores</span>
                                                <button
                                                    className='configChangeBtn'
                                                    onClick={()=>{
                                                        const btn = color.infoButton || "#D7D7D7";
                                                        const wrd = color.infoWord || "#000000";
                                                        setColor({...color, infoButton:wrd, infoWord:btn})
                                                    }}
                                                    disabled={!profileSettings.canChangeButtonsConfig}
                                                >
                                                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M9.37502 9.375V11.25H11.6805C11.1565 11.8412 10.5128 12.3141 9.79203 12.6374C9.07126 12.9606 8.28996 13.1269 7.50002 13.125C6.00868 13.1234 4.57889 12.5302 3.52435 11.4757C2.46981 10.4211 1.87665 8.99134 1.87501 7.5H1.47596e-05C-0.00301298 9.02502 0.459855 10.5146 1.32666 11.7693C2.19347 13.024 3.42286 13.9841 4.85028 14.5209C6.2777 15.0577 7.83503 15.1457 9.31384 14.7731C10.7926 14.4005 12.1224 13.5851 13.125 12.436V15H15V9.375H9.37502Z" fill="#8D8D8D"/>
                                                        <path d="M7.5 0C6.43444 0.00548835 5.38216 0.236843 4.41256 0.678801C3.44296 1.12076 2.57809 1.76328 1.875 2.56397V0H0V5.625H5.625V3.75H3.3195C3.84347 3.15884 4.48721 2.68592 5.20798 2.36264C5.92875 2.03937 6.71006 1.87314 7.5 1.875C8.99134 1.87664 10.4211 2.4698 11.4757 3.52433C12.5302 4.57887 13.1234 6.00866 13.125 7.5H15C14.9977 5.51158 14.2068 3.60525 12.8008 2.19922C11.3948 0.793192 9.48842 0.00228275 7.5 0Z" fill="#8D8D8D"/>
                                                    </svg>
                                                </button>
                                            </div>
                                            <div className='configTypeSectionRow'>
                                                <span className='configTypeCheckCont'>Mostrar botón</span>
                                                <button
                                                    className='configChangeBtn'
                                                    onClick={()=>setProfileSendInfo(profileSendInfo==='off'?'on':'off')}
                                                    disabled={!profileSettings.canChangeButtonsConfig}
                                                >
                                                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M9.37502 9.375V11.25H11.6805C11.1565 11.8412 10.5128 12.3141 9.79203 12.6374C9.07126 12.9606 8.28996 13.1269 7.50002 13.125C6.00868 13.1234 4.57889 12.5302 3.52435 11.4757C2.46981 10.4211 1.87665 8.99134 1.87501 7.5H1.47596e-05C-0.00301298 9.02502 0.459855 10.5146 1.32666 11.7693C2.19347 13.024 3.42286 13.9841 4.85028 14.5209C6.2777 15.0577 7.83503 15.1457 9.31384 14.7731C10.7926 14.4005 12.1224 13.5851 13.125 12.436V15H15V9.375H9.37502Z" fill="#8D8D8D"/>
                                                        <path d="M7.5 0C6.43444 0.00548835 5.38216 0.236843 4.41256 0.678801C3.44296 1.12076 2.57809 1.76328 1.875 2.56397V0H0V5.625H5.625V3.75H3.3195C3.84347 3.15884 4.48721 2.68592 5.20798 2.36264C5.92875 2.03937 6.71006 1.87314 7.5 1.875C8.99134 1.87664 10.4211 2.4698 11.4757 3.52433C12.5302 4.57887 13.1234 6.00866 13.125 7.5H15C14.9977 5.51158 14.2068 3.60525 12.8008 2.19922C11.3948 0.793192 9.48842 0.00228275 7.5 0Z" fill="#8D8D8D"/>
                                                    </svg>
                                                </button>
                                            </div>
                                            <span className='configTypeCheckCont' style={{fontSize:'10px'}}>
                                                *Si decides ocultar el botón de "Envía tu información" la sección de contactos no contará con ningún registro.
                                            </span>
                                        </div>
                                    </div>
                                </section>
                                :
                            selectedConfigType==='qr'?
                                <section className='configTypeSectionCont'>
                                    <h3 className='configTypeTitle'>QR del perfil</h3>
                                    <div className='configTypeSection'>
                                        <div className='configTypeInputCont'>
                                            <span className='configTypeInputTitle'>Úsalo como prefieras</span>
                                            <div className='configQRSectionCont'>
                                                <div className='configQRSectionTextCont'>
                                                    <p className='configQRSectionText'>Descarga el qr de tu perfil ppol y úsalo en las piezas físicas y digitales que prefieras</p>
                                                    <p className='configQRSectionText'>Formatos png y svg</p>
                                                </div>
                                                <img loading="lazy" id="QRImg" className='configQRSectionQR' alt="QR" onClick={downloadQR}/>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                :
                                <section className='configTypeSectionCont'>
                                    <h3 className='configTypeTitle'>Cambio de contraseña</h3>
                                    <div className='configTypeSection'>
                                        <div className='configTypeInputCont'>
                                            <span className='configTypeInputTitle'>Contraseña</span>
                                            <input className='configTypeInput configTypeInputPw' type={watchPassword?"text":"password"} autoComplete="false" placeholder='Contraseña' maxLength={50} value={password} onChange={(e)=>setPassword(e.target.value)} disabled={!profileSettings.canChangePassword}/>
                                            <button className='watchPassWordConfig' onClick={()=>setWatchPassword(!watchPassword)}><img src={watchPassword?passwordHide:passwordShow} loading="lazy" alt="Ver contraseña"/></button>
                                        </div>
                                        <div className='configTypeInputCont'>
                                            <span className='configTypeInputTitle'>Nueva contraseña</span>
                                            <input className='configTypeInput configTypeInputPw' type={watchNewPassword?"text":"password"} placeholder='Nueva contraseña' maxLength={50} value={newPassword} onChange={(e)=>setNewPassword(e.target.value)} disabled={!profileSettings.canChangePassword}/>
                                            <button className='watchPassWordConfig' onClick={()=>setWatchNewPassword(!watchNewPassword)}><img src={watchNewPassword?passwordHide:passwordShow} loading="lazy" alt="Ver contraseña"/></button>
                                        </div>
                                        <div className='configTypeInputCont'>
                                            <span className='configTypeInputTitle'>Confirmar nueva contraseña</span>
                                            <input className='configTypeInput configTypeInputPw' type={watchNewPasswordConf?"text":"password"} placeholder='Confirmar nueva contraseña' maxLength={50} value={newPasswordConf} onChange={(e)=>setNewPasswordConf(e.target.value)} disabled={!profileSettings.canChangePassword}/>
                                            <button className='watchPassWordConfig' onClick={()=>setWatchNewPasswordConf(!watchNewPasswordConf)}><img src={watchNewPasswordConf?passwordHide:passwordShow} loading="lazy" alt="Ver contraseña"/></button>
                                        </div>
                                    </div>
                                    <a className='configTypeInputTextMiddle' target='_blank' rel='noreferrer' href='https://wa.me/51960525864?text=Hola,%20olvidé%20la%20contraseña%20de%20mi%20perfil%20Ppol'>¿Has olvidado tu contraseña?</a>
                                </section>
                            }
                            <button className='configConfirmBtn' onClick={handleConfig}>Guardar</button>
                            <p style={{color:'purple'}}>{errorLine}</p>
                        </section>

                        {openPasswordForm?
                            <div className='floatInfoBg'>
                                <div className='floatInfoCont'>
                                    <div className='floatInfoSectionCont'>
                                        <div className='floatInfoSectionMain'>
                                            <div className='floatInfoSectionSplit'>
                                                <p className='floatInfoSectionTitle'>Cambiar contraseña</p>
                                                <button
                                                    className='floatInfoCloseBtn'
                                                    onClick={
                                                        ()=>{
                                                            setOpenPasswordForm(false)
                                                            setPassword("")
                                                            setNewPassword("")
                                                            setNewPasswordConf("")
                                                            setPasswordFormError(null)
                                                            setWatchPassword(false)
                                                            setWatchNewPassword(false)
                                                            setWatchNewPasswordConf(false)
                                                        }
                                                    }
                                                >
                                                    <img className='floatInfoCloseBtnImg' src={closeCreationBarImg} alt='Cerrar'/>
                                                </button>
                                            </div>
                                        </div>
                                        <div className='floatInfoSectionMain'>
                                            <div className='floatInfoSectionFull'>
                                                <hr className='floatInfoSectionSeparation'/>
                                            </div>
                                        </div>
                                        <div className='floatInfoSectionMain'>
                                            <div className='floatInfoSectionFull'>
                                                <input className='floatInfoSectionTextarea' style={{padding:"15px 50px 15px 15px"}} type={watchPassword?"text":"password"} autoComplete="false" placeholder='Contraseña' maxLength={50} value={password} onChange={(e)=>setPassword(e.target.value)}/>
                                                <button className='watchPassWord' style={{top:"0", right:"0"}} onClick={()=>setWatchPassword(!watchPassword)}><img src={watchPassword?passwordHide:passwordShow} loading="lazy" alt="Ver contraseña"/></button>
                                            </div>
                                            <div className='floatInfoSectionSplit'>
                                                <div></div>
                                                <div className='floatInfoSectionCounter' style={{color:password.length>=50?"#e8542f":"#5A5A5A"}}>{password.length}/50</div>
                                            </div>
                                        </div>
                                        <div className='floatInfoSectionMain'>
                                            <div className='floatInfoSectionFull'>
                                                <input className='floatInfoSectionTextarea' style={{padding:"15px 50px 15px 15px"}} type={watchNewPassword?"text":"password"} placeholder='Nueva contraseña' maxLength={50} value={newPassword} onChange={(e)=>setNewPassword(e.target.value)}/>
                                                <button className='watchPassWord' style={{top:"0", right:"0"}} onClick={()=>setWatchNewPassword(!watchNewPassword)}><img src={watchNewPassword?passwordHide:passwordShow} loading="lazy" alt="Ver contraseña"/></button>
                                            </div>
                                            <div className='floatInfoSectionSplit'>
                                                <div></div>
                                                <div className='floatInfoSectionCounter' style={{color:newPassword.length>=50?"#e8542f":"#5A5A5A"}}>{newPassword.length}/50</div>
                                            </div>
                                        </div>
                                        <div className='floatInfoSectionMain'>
                                            <div className='floatInfoSectionFull'>
                                                <input className='floatInfoSectionTextarea' astyle={{pdding:"15px 50px 15px 15px"}} type={watchNewPasswordConf?"text":"password"} placeholder='Confirmar nueva contraseña' maxLength={50} value={newPasswordConf} onChange={(e)=>setNewPasswordConf(e.target.value)}/>
                                                <button className='watchPassWord' style={{top:"0", right:"0"}} onClick={()=>setWatchNewPasswordConf(!watchNewPasswordConf)}><img src={watchNewPasswordConf?passwordHide:passwordShow} loading="lazy" alt="Ver contraseña"/></button>
                                            </div>
                                            <div className='floatInfoSectionSplit'>
                                                <div/>
                                                <div className='floatInfoSectionCounter' style={{color:newPasswordConf.length>=50?"#e8542f":"#5A5A5A"}}>{newPasswordConf.length}/50</div>
                                            </div>
                                        </div>
                                        {passwordFormError?
                                            <div className='floatInfoSectionMain'>
                                                <div className='floatInfoSectionFull'>
                                                <p style={{margin:"0"}}>{passwordFormError}</p>
                                                </div>
                                            </div>
                                            :
                                            <></>
                                        }
                                        <div className='floatInfoSectionMain'>
                                            <div className='floatInfoSectionSplit'>
                                                <button
                                                    className='floatInfoSectionSplittedBtn floatInfoCancelChanges'
                                                    onClick={
                                                        ()=>{
                                                            setOpenPasswordForm(false)
                                                            setPassword("")
                                                            setNewPassword("")
                                                            setNewPasswordConf("")
                                                            setPasswordFormError(null)
                                                            setWatchPassword(false)
                                                            setWatchNewPassword(false)
                                                            setWatchNewPasswordConf(false)
                                                        }
                                                    }
                                                >
                                                    Cancelar
                                                </button>
                                                <button className='floatInfoSectionSplittedBtn floatInfoSaveChanges' onClick={postPasswordInfo}>Guardar</button>
                                            </div>
                                        </div>
                                        <div className='floatInfoSectionMain'>
                                            <div className='floatInfoSectionFull'>
                                                <p style={{margin:"0"}}>¿Olvidaste tu contraseña? <a style={{color:"#E8542F"}} target='_blank' rel='noreferrer' href='https://wa.me/51960525864?text=Hola,%20olvidé%20la%20contraseña%20de%20mi%20perfil%20Ppol'>Haz click aquí.</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <></>
                        }

                        {openPrivateCode?
                            <div className='floatInfoBg'>
                            <div className='floatInfoCont'>
                                <div className='floatInfoSectionCont'>
                                    <div className='floatInfoSectionMain'>
                                        <div className='floatInfoSectionSplit'>
                                            <p className='floatInfoSectionTitle'>Claves de acceso</p>
                                            <button
                                                className='floatInfoCloseBtn'
                                                onClick={()=>{
                                                    setOpenPrivateCode(false)
                                                    setNewKey("")
                                                    setNewKeyDate("")
                                                }}
                                            >
                                                <img className='floatInfoCloseBtnImg' src={closeCreationBarImg} alt='Cerrar'/>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='floatInfoSectionMain'>
                                        <div className='floatInfoSectionFull'>
                                            <hr className='floatInfoSectionSeparation'/>
                                        </div>
                                    </div>


                                    <table style={{width:"100%"}}>
                                        <tr>
                                            <td style={{fontWeight:"bold"}}>Clave</td>
                                            <td style={{fontWeight:"bold"}}>Fecha de vencimiento</td>
                                            <td></td>
                                        </tr>
                                        {privateCodesData.map(key=>(
                                            <tr key={key.id}>
                                                <td>{key.code}</td>
                                                <td>{key.datetime}</td>
                                                <td>
                                                    <button
                                                        onClick={() => handleDeleteKey(key.id)}
                                                        className='floatInfoCloseBtn'
                                                        style={{width:"25px", height:"25px"}}
                                                    >
                                                        <img className='floatInfoCloseBtnImg' src={closeCreationBarImg} style={{width:"20px"}}/>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </table>

                                    <hr className='floatInfoSectionSeparation'/>

                                    <h3 style={{fontSize:"20px", margin:"0", padding:"0"}} className='share-metods-subtitle'>Agregar nueva clave</h3>
                                    <div style={{marginTop:"10px", display:"flex", flexDirection:"row", gap:"5px", width:"calc(100% - 10px)"}}>
                                        <input
                                            style={{width:"50%", height:"25px", borderRadius:"12px", border:"1px solid #888888", fontFamily:"interRegular", padding:"5px 5px"}}
                                            type='text'
                                            placeholder='Código'
                                            value={newKey}
                                            onChange={(e)=> setNewKey(e.target.value.toUpperCase())}
                                            maxLength={25}
                                        />
                                        <input
                                            style={{width:"50%", height:"25px", borderRadius:"12px", border:"1px solid #888888", fontFamily:"interRegular", padding:"5px 5px"}}
                                            type='date'
                                            placeholder='Fecha de vencimiento'
                                            value={newKeyDate}
                                            onChange={(e)=>setNewKeyDate(e.target.value)}
                                        />
                                    </div>
                                    <button
                                        className='floatInfoSectionSplittedBtn floatInfoSaveChanges'
                                        onClick={()=>{
                                            if(newKey!=="" && newKeyDate!==""){
                                                handleAddKey()
                                            }
                                        }}
                                    >Agregar Clave</button>
                                </div>
                            </div>
                        </div>
                            :
                            <></>
                        }
                    </div>
                }
            </div>
            
        )
    
}

export default ProfileConfiguration