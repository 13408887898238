export function getDate(date) {
    const day = date.split(" ")[0].split("-").reverse().join("/")
    const hour = date.split(" ")[1].split(":")
    hour.pop()
    return `${day} ${hour.join(":")}`
}

export function getMonth(month){
    const months = {
        Jan: "01",
        Feb: "02",
        Mar: "03",
        Apr: "04",
        May: "05",
        Jun: "06",
        Jul: "07",
        Aug: "08",
        Sep: "09",
        Oct: "10",
        Nov: "11",
        Dec: "12"
    }
    return months[month]
}

export function getMonthName(month){
    const months = {
        "01": "Ene",
        "02": "Feb",
        "03": "Mar",
        "04": "Abr",
        "05": "May",
        "06": "Jun",
        "07": "Jul",
        "08": "Ago",
        "09": "Sep",
        "10": "Oct",
        "11": "Nov",
        "12": "Dic"
    }
    return months[month]
}