import React, { useState, useEffect } from 'react';

import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, FreeMode, Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'mapbox-gl/dist/mapbox-gl.css'
import 'swiper/css/navigation';

const Metrics = () => {

  return (
            <section className="metrics-section">
                    <div className="metrics-slide">
                        <div className="slide-item">
                            <div><b>+160,000</b> vistas de perfiles</div>
                            <div><b>+28,500</b> taps de tarjetas</div>
                        </div>
                        <div className="slide-item">
                            <div><b>+160,000</b> vistas de perfiles</div>
                            <div><b>+28,500</b> taps de tarjetas</div>
                        </div>
                    </div>
                    <Swiper
                        className="metrics-slide"
                        spaceBetween={0}
                        slidesPerView={window.innerWidth < 500? 1.5: window.innerWidth < 1500? 2 : window.innerWidth < 2500? 3 : 5}
                        loop={true}
                        autoplay={{
                            delay: 0,
                            disableOnInteraction: false,
                            reverseDirection: true,
                        }}
                        speed={10000}
                        navigation={false}
                        modules={[FreeMode, Pagination, Autoplay]}
                    >
                        <SwiperSlide className="slide-item-2">
                            <div><b>+1,800&nbsp;</b> usuarios en&nbsp;<b>+10</b>&nbsp;países</div>
                        </SwiperSlide>
                        <SwiperSlide className="slide-item-2">
                            <div>
                                <svg width="42" height="46" viewBox="0 0 42 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.9631 0H0.23698C0.0331394 0 -0.0755717 0.248307 0.0603219 0.40005L20.9879 22.927H41.7389C41.9427 22.927 42.0514 22.6787 41.9156 22.527L22.3876 1.48984C21.5043 0.537999 20.2677 0 18.9767 0H18.9631Z" fill="#141414"/>
                                <path d="M20.9879 22.927H0.23698C0.0331394 22.927 -0.0755717 23.1753 0.0603219 23.3271L19.5882 44.3642C20.4715 45.316 21.7082 45.854 22.9992 45.854H41.7253C41.9291 45.854 42.0379 45.6057 41.902 45.454L20.9743 22.927H20.9879Z" fill="#141414"/>
                                </svg>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="slide-item-2">
                        <div><b>+1,800&nbsp;</b> usuarios en&nbsp;<b>+10</b>&nbsp;países</div>
                        </SwiperSlide>
                        <SwiperSlide className="slide-item-2">
                            <div>
                                <svg width="42" height="46" viewBox="0 0 42 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.9631 0H0.23698C0.0331394 0 -0.0755717 0.248307 0.0603219 0.40005L20.9879 22.927H41.7389C41.9427 22.927 42.0514 22.6787 41.9156 22.527L22.3876 1.48984C21.5043 0.537999 20.2677 0 18.9767 0H18.9631Z" fill="#141414"/>
                                <path d="M20.9879 22.927H0.23698C0.0331394 22.927 -0.0755717 23.1753 0.0603219 23.3271L19.5882 44.3642C20.4715 45.316 21.7082 45.854 22.9992 45.854H41.7253C41.9291 45.854 42.0379 45.6057 41.902 45.454L20.9743 22.927H20.9879Z" fill="#141414"/>
                                </svg>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="slide-item-2">
                        <div><b>+1,800&nbsp;</b> usuarios en&nbsp;<b>+10</b>&nbsp;países</div>
                        </SwiperSlide>
                        <SwiperSlide className="slide-item-2">
                            <div>
                                <svg width="42" height="46" viewBox="0 0 42 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.9631 0H0.23698C0.0331394 0 -0.0755717 0.248307 0.0603219 0.40005L20.9879 22.927H41.7389C41.9427 22.927 42.0514 22.6787 41.9156 22.527L22.3876 1.48984C21.5043 0.537999 20.2677 0 18.9767 0H18.9631Z" fill="#141414"/>
                                <path d="M20.9879 22.927H0.23698C0.0331394 22.927 -0.0755717 23.1753 0.0603219 23.3271L19.5882 44.3642C20.4715 45.316 21.7082 45.854 22.9992 45.854H41.7253C41.9291 45.854 42.0379 45.6057 41.902 45.454L20.9743 22.927H20.9879Z" fill="#141414"/>
                                </svg>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="slide-item-2">
                        <div><b>+1,800&nbsp;</b> usuarios en&nbsp;<b>+10</b>&nbsp;países</div>
                        </SwiperSlide>
                        <SwiperSlide className="slide-item-2">
                            <div>
                                <svg width="42" height="46" viewBox="0 0 42 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.9631 0H0.23698C0.0331394 0 -0.0755717 0.248307 0.0603219 0.40005L20.9879 22.927H41.7389C41.9427 22.927 42.0514 22.6787 41.9156 22.527L22.3876 1.48984C21.5043 0.537999 20.2677 0 18.9767 0H18.9631Z" fill="#141414"/>
                                <path d="M20.9879 22.927H0.23698C0.0331394 22.927 -0.0755717 23.1753 0.0603219 23.3271L19.5882 44.3642C20.4715 45.316 21.7082 45.854 22.9992 45.854H41.7253C41.9291 45.854 42.0379 45.6057 41.902 45.454L20.9743 22.927H20.9879Z" fill="#141414"/>
                                </svg>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="slide-item-2">
                        <div><b>+1,800&nbsp;</b> usuarios en&nbsp;<b>+10</b>&nbsp;países</div>
                        </SwiperSlide>
                        <SwiperSlide className="slide-item-2">
                            <div>
                                <svg width="42" height="46" viewBox="0 0 42 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.9631 0H0.23698C0.0331394 0 -0.0755717 0.248307 0.0603219 0.40005L20.9879 22.927H41.7389C41.9427 22.927 42.0514 22.6787 41.9156 22.527L22.3876 1.48984C21.5043 0.537999 20.2677 0 18.9767 0H18.9631Z" fill="#141414"/>
                                <path d="M20.9879 22.927H0.23698C0.0331394 22.927 -0.0755717 23.1753 0.0603219 23.3271L19.5882 44.3642C20.4715 45.316 21.7082 45.854 22.9992 45.854H41.7253C41.9291 45.854 42.0379 45.6057 41.902 45.454L20.9743 22.927H20.9879Z" fill="#141414"/>
                                </svg>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                    <div className="metrics-slide">
                        <div className="slide-item">
                            <div><b>+2,100</b> tarjetas vendidas</div>
                            <div><b>+4,000</b> conexiones hechas</div>
                        </div>
                        <div className="slide-item">
                            <div><b>+2,100</b> tarjetas vendidas</div>
                            <div><b>+4,000</b> conexiones hechas</div>
                        </div>
                    </div>
            </section>
  )
}

export default Metrics