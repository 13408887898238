import annieImg from './annievillaran.webp'
import totemImg from './totembrand.webp'
import fortunaImg from './fortunastudio.webp'
import chirinosImg from './luchochirinos.webp'
import ppuImg from './milagrosppu.webp'
import hotelesImg from './hotelesydestinos.webp'

export const dataTestimonials = [
    {
        id: 1,
        image: fortunaImg,
        name: 'José María Denegri',
        position: 'Fortuna Studio',
        testimony: 'Gracias a Ppol, hemos podido establecer una red de contactos más sólida y muchas más personas nos han podido conocer, no solo como las manos y mentes detrás de Fortuna Studio, sino también desde un lado mucho más personal a través de nuestro setlist musical y otros datos que hemos ido adhiriendo a nuestro perfil Ppol.',
    },
    {
        id: 2,
        image: totemImg,
        name: 'Genaro Bernilla',
        position: 'Totem brand',
        testimony: 'PPOL nos ha dado la oportunidad de llegar a muchas más personas, nos alegra tener a la mano un punto de inicio con nuestros clientes, socios y proveedores. ¡Nos encanta!',
    },
    {
        id: 3,
        image: annieImg,
        name: 'Annie Villaran',
        position: 'Hola Group',
        testimony: '¡Hola! Quiero compartir mi experiencia positiva con Ppol. Esta plataforma ha transformado la forma en que presento mi negocio. Crear mi tarjeta de presentación virtual fue súper fácil, y ahora puedo compartir información clave de mi empresa de manera eficiente. ¡Ppol ha simplificado la conexión con clientes potenciales y ha mejorado mi presencia online de una manera increíble!',
    },
    {
        id: 4,
        image: hotelesImg,
        name: 'José Muñoz',
        position: 'Hoteles y Destinos',
        testimony: 'Tanto la tarjeta como el perfil me parecen muy prácticos, funcionales y  fáciles de editar. PPOL ha sido un buen complemento a la página web y en este momento estamos trabajando para sacarle un mejor provecho aprovechando todas sus ventajas.',
    },
    {
        id: 5,
        image: ppuImg,
        name: 'María Milagros Castaños',
        position: 'PPU Legal',
        testimony: 'La innovación de lo que ofrece PPOL es super útil, novedosa y a la vanguardia de lo que buscamos. Estuvieron siempre disponibles en todo el proceso buscando que estemos contentos. Las entregas de las tarjetas fueron en tiempo record.',
    },
    {
        id: 6,
        image: chirinosImg,
        name: 'Lucho Chirinos',
        position: 'Hola Group',
        testimony: `I'm thrilled with Ppol. It's amazing how this platform has elevated my business presence. Creating my virtual business card was straightforward and effective. What fascinates me the most is the option to simply tap on someone's phone for my profile to appear directly on their device. Ppol has streamlined business connections in an innovative and efficient way!`,
    }
 ];