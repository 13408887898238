import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { makeRequest } from '../../axios';
import axios from 'axios';

import ppolLogo from "../../images/ppolLogo.png"
import loadingGif from "../../images/loadingGif.gif"

const pageURL = process.env.REACT_APP_PPOL_URL==="PRODUCTION"?"https://ppol.io/backend":process.env.REACT_APP_PPOL_LOCAL_URL

function CardProfile() {
    const userLink = useLocation().pathname.split("/")[2];
    const navigate = useNavigate()
    const card = true

    const [username, setUsername] = useState(null)

    const {isLoading} = useQuery(["user"],() => 
        makeRequest.get(`${pageURL}/getCardCounter.php`,{params:{userlink: userLink}})
        //makeRequest.get("/u/" + userLink)
        .then((res) => {
            //console.log(res.data)
            const cardCounter = parseInt(res.data.cardCounter, 10) + 1
            setUsername(res.data.username)
            handleProfile(res.data.username, cardCounter)
            return res.data
        })
        .catch (() => {
            navigate("/404")
        })
    )

    const handleProfile = async(goTo, cardCounter) => {
        try{
            axios.post(`${pageURL}/postCardCounter.php`, {params: {userlink: userLink, cardCounter: cardCounter}})
            navigate(`/${goTo}`, {state:{card}})
        }catch(error){
            console.log(error)
        }
    }

    return (
        isLoading?
        (
            <div style={{height:"100vh", display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column"}}>
              <img src={ppolLogo} alt="" loading="lazy" />
              <img src={loadingGif} alt="" style={{height:"80px", marginTop:"40px"}} loading="lazy" />
            </div>
        ):(
            <div style={{height:"100vh", display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column"}}>
              <img src={ppolLogo} alt="" loading="lazy" />
              <img src={loadingGif} alt="" style={{height:"80px", marginTop:"40px"}} loading="lazy" />
              <p>{username}</p>
            </div>
        )
    )
}

export default CardProfile