import React from 'react'

import BenefitsVideo from './Seccion6.mp4'
import BenefitsImg from './Seccion6Mobile.webp'

const Benefits = () => {
  return (
    <section className="benefits-section-container">
                <div className="benefits-section">
                    <h1>Descubre la inmensa variedad que existe en PPOL</h1>
                    <div className='benefits-picture-container'>
                        <video autoPlay muted loop controls={false} preload="metadata" playsInline >
                         {/*<source src="https://storage.googleapis.com/ppol-storage/15021998I8dlEsEMDgznTiy14jlDQApEIV1qaoCsQHmpbJ1i4tLGWOIWpt.mp4"/>*/}
                         <source src={BenefitsVideo} type="video/mp4"/>
                        </video>
                        <img src={BenefitsImg} alt="#" />
                    </div>
                </div>
    </section>
  )
}

export default Benefits