import React from 'react'
//import {useState} from "react"
import "./landing.css"

import ppolLogo from "../../images/ppolLogo.png"
import facebookLogo from "../../images/facebookLogo.png"
import instagramLogo from "../../images/instagramLogo.png"
import linkedinLogo from "../../images/linkedinLogo.png"
import tiktokLogo from "../../images/tiktokLogo.png"
import youtubeLogo from "../../images/youtubeLogo.png"
import profilesImg from "../../images/profilesImg.png"

function ThanksSuscription() {

    //const [name, setName] = useState("");
    //const [email, setEmail] = useState("");

    /*const changeName = (e) => {
        setName(e.target.value)
    }*/

    /*const changeEmail = (e) => {
        setEmail(e.target.email)
    }*/

    const closeNavbar = () => {
        document.querySelector("#menu-toggle").checked = false
    }

    return(
        
        <div className="landingBG">
            <nav className="navbar">
                <div className="navbarMini">
                    <a href="#top" onClick={closeNavbar}>
                        <img className="ppolLogoMini" loading="lazy" src={ppolLogo} alt="Ppol Logo"/>
                    </a>
                    <input id="menu-toggle" type="checkbox"/>
                    <label className="menu-button-container" htmlFor="menu-toggle">
                        <div className="menu-button"></div>
                    </label>
                    <ul className="menu"> 
                
                    <li className="ppolLogoNavLi">
                        <a href="/signin">
                            <button id="navBtnMini1" className="navBtnMini">
                                Iniciar sesión
                            </button>
                        </a>
                        <a href="/signup">
                            <button id="navBtnMini2" className="navBtnMini">
                                Crear cuenta
                            </button>
                        </a>
                    </li>
                    <li className="ppolLogoNavLi">
                        <a href="/#custom-Section" onClick={closeNavbar}>Producto</a>
                    </li>
                    {/*<li className="ppolLogoNavLi">
                        <a href="/#communitySection" onClick={closeNavbar}>Comunidad</a>
                    </li>*/}
                    <li className="ppolLogoNavLi">
                        <a href="/#ppolMeUs" onClick={closeNavbar}>Públicos</a>
                    </li>
                    <li className="ppolLogoNavLi">
                        <a href="/#faqSection" onClick={closeNavbar}>FAQ</a>
                    </li>
                    <li className="ppolLogoNavLi">
                        <a href="/#suscriptionSection" onClick={closeNavbar}>Suscríbete</a>
                    </li>
                    <li id="navCopyright">
                        ®2023 Ppol. Tecnología patentada
                    </li>
                
            </ul>
                </div>



                <ul className="navLeft">
                    <li className="ppolLogoContainer">
                        <a href="/">
                            <img className="ppolLogo" loading="lazy" src={ppolLogo} alt="Ppol Logo"/>
                            
                        </a>
                    </li>
                    <li className="ppolLogoNavLi">
                        <a href="/#custom-Section">Producto</a>
                    </li>
                    {/*<li className="ppolLogoNavLi">
                        <a href="/#communitySection">Comunidad</a>
                    </li>*/}
                    <li className="ppolLogoNavLi">
                        <a href="/#ppolMeUs">Públicos</a>
                    </li>
                    <li className="ppolLogoNavLi">
                        <a href="/#faqSection">FAQ</a>
                    </li>
                    <li className="ppolLogoNavLi">
                        <a href="/#suscriptionSection">Suscríbete</a>
                    </li>
                </ul>
                <ul className="navRight">
                    <li className="ppolLogoNavLi">
                        <a href="/signin">
                            <button id="btnLogin" className="btnNav">Iniciar sesión</button>
                        </a>
                    </li>
                    <li className="ppolLogoNavLi">
                        <a href="/signup">
                            <button id="btnCreate" className="btnNav">Crear cuenta</button>
                        </a>
                    </li>
                </ul>
            </nav>

            <section className='launch-date'>
                <span>¡Gracias por <span>suscribirte!</span></span>
                <div className='thanks-separation-top'></div>
                <h1>Lanzamiento</h1>
                <h2>12.06.23</h2>
                <img alt="" loading="lazy" src={profilesImg}/>
                <div className='thanks-separation'></div>
            </section>
           
            
            <footer className="foot">
                <p className="footP1">Conecta con nosotros</p>
                <div className="footImgContainer">
                    <a href="https://www.facebook.com/profile.php?id=100089400941802" target="_blank" rel="noreferrer">
                        <img loading="lazy" src={facebookLogo} alt="Facebook Ppol"/>
                    </a>
                    <a href="https://www.instagram.com/somosppol/" target="_blank" rel="noreferrer">
                        <img loading="lazy" src={instagramLogo} alt="Instagram Ppol"/>
                    </a>
                    <a href="https://www.linkedin.com/company/somosppol/" target="_blank" rel="noreferrer">
                        <img loading="lazy" src={linkedinLogo} alt="LinkedIn Ppol"/>
                    </a>
                    <a href="https://www.tiktok.com/@somosppol" target="_blank" rel="noreferrer">
                        <img loading="lazy" src={tiktokLogo} alt="TikTok Ppol"/>
                    </a>
                    <a href="https://www.youtube.com/@somosppol" target="_blank" rel="noreferrer">
                        <img loading="lazy" src={youtubeLogo} alt="Youtube Ppol"/>
                    </a>
                    {/*<a href="https://twitter.com/somosppol" target="_blank" rel="noreferrer">
                        <img loading="lazy" src={youtubeLogo} alt="Twitter Ppol"/>
                    </a>
                    <a href="https://www.pinterest.es/somosppol/" target="_blank" rel="noreferrer">
                        <img loading="lazy" src={youtubeLogo} alt="Pinterest Ppol"/>
                    </a>
                    <a href="https://open.spotify.com/user/31nq66sd45n6ab3twe2wxmw32uge" target="_blank" rel="noreferrer">
                        <img loading="lazy" src={youtubeLogo} alt="Spotify Ppol"/>
                    </a>
                    <a href="https://discord.gg/Qrgqdkr5dU" target="_blank" rel="noreferrer">
                        <img loading="lazy" src={youtubeLogo} alt="Discord Ppol"/>
                    </a>*/}
                </div>
                <ul className="footLiContainer">
                    <a href="/#custom-Section">
                        <li>Producto</li>
                    </a>
                    {/*<a href="/#communitySection">
                        <li>Comunidad</li>
                    </a>*/}
                    <a href="/#ppolMeUs">
                        <li>Públicos</li>
                    </a>
                    <a href="/#faqSection">
                        <li>FAQ</li>
                    </a>
                    <a href="/#suscriptionSection">
                        <li>Suscríbete</li>
                    </a>
                </ul>
                <p className="footP2">®2023 Ppol. Tecnología patentada</p>
            </footer>
        </div>
        
    )
}

export default ThanksSuscription