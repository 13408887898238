import React from 'react'

import ShareVideo from './Seccion5.mp4'

const Share = () => {
  return (
    <section className="share-section-container">
                <div className="share-section">
                    <h1>Comparte tu información con el mundo en segundos</h1>
                    <main>
                        <div className="share-section-img">
                            <video autoPlay muted loop controls={false} preload="metadata" playsInline>
                                {/*<source src="https://storage.googleapis.com/ppol-storage/15021998ReSMTOjLwDwTRxHZCF9TS0YbDwhqxAwqZPOup9VbY8YFu7qh85.mp4"/>*/}
                                <source src={ShareVideo} type="video/mp4"/>
                            </video>
                        </div>
                        <div className="share-text">
                            <div>
                                <div className="share-text-title">Tecnología <h2>NFC</h2></div>
                                <p>Acerca tu tarjeta a un celular y comparte instantáneamente la información de tu perfil.</p>
                            </div>
                            <div>
                                <div className="share-text-title">Código <h2>QR</h2></div>
                                <p>Tienes un código QR único. Al escanearlo con la cámara de cualquier dispositivo se enviará la información de tu perfil.</p>
                            </div>
                            <div>
                                <div className="share-text-title"><h2>Link</h2> personalizado</div>
                                <p>Conecta con personas de todas partes del mundo, solo tienes que compartirlo por el medio que prefieras.</p>
                            </div>
                        </div>
                    </main>
                </div>
    </section>
  )
}

export default Share