import React, { useContext, useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { makeRequest } from '../../axios'
import { AuthContext } from '../../context/authContext'

import AdminMetrics from './AdminMetrics'
import AdminContacts from './AdminContacts'
import AdminUsers from './AdminUsers'

import Navbar from '../Navbar/Navbar'
import NavbarMobile from '../Navbar/NavbarMobile'
import Loading from '../Loading/Loading'

import './admin.css'
import AdminTemplates from './AdminTemplates'

function Admin() {

    const [adminPage, setAdminPage] = useState("users")
    const [showInfo, setShowInfo] = useState(false)
    const [userList, setUserList] = useState([])

    const navigate = useNavigate()
    const userId = useLocation().pathname.split("/")[1];
    let {currentUser, logout} = useContext(AuthContext);
    const userlink = currentUser.userlink || null

    useEffect(()=>{
        makeRequest.get('findUser.php', {params: {user: userId}})
        .then((res)=> {
            document.title = `${JSON.parse(res.data.profileBio).name || 'PPOL'} | Administrador | PPOL`
            makeRequest.get('findAdminUserList.php', {params: {user: userlink}})
            .then(({data})=>{
                setUserList(data.userList)
                setShowInfo(true)
            })
            .catch(()=>{
                localStorage.clear()
                navigate('/404')
            })
        })
        .catch(() => {
            localStorage.clear()
            navigate('/404')
        })
    },[])
    

    return (
        <div className="main">
            <Navbar activeBtn={'admin'} isAdmin={true}/>
            {!showInfo?
                <Loading/>
                :
                <div id="dashboard" className="dashboard">
                    <div className='adminMainContainer'>
                        <section className='adminBtnsCont'>
                            <button className='adminBtn' onClick={()=>setAdminPage('users')} style={{color:adminPage==='users'?'#E8542F':"#141414", borderColor:adminPage==='users'?'#E8542F':'#8D8D8D'}}>Usuarios</button>
                            <button className='adminBtn' onClick={()=>setAdminPage('metrics')} style={{color:adminPage==='metrics'?'#E8542F':"#141414", borderColor:adminPage==='metrics'?'#E8542F':'#8D8D8D'}}>Métricas</button>
                            <button className='adminBtn' onClick={()=>setAdminPage('templates')} style={{color:adminPage==='templates'?'#E8542F':"#141414", borderColor:adminPage==='templates'?'#E8542F':'#8D8D8D'}}>Plantillas</button>
                            <button className='adminBtn' onClick={()=>setAdminPage('contacts')} style={{color:adminPage==='contacts'?'#E8542F':"#141414", borderColor:adminPage==='contacts'?'#E8542F':'#8D8D8D'}}>Contactos</button>
                            <hr className='adminBtn' style={{width:"100%", flexShrink:"1"}}/>
                        </section>
                        {
                        adminPage==='metrics'?
                            <AdminMetrics adminList={userList}/>
                        :
                        adminPage==='users'?
                            <AdminUsers adminList={userList}/>
                        :
                        adminPage==='templates'?
                            <AdminTemplates adminList={userList}/>
                        :
                        adminPage==='contacts'?
                            <AdminContacts adminList={userList}/>
                        :
                            <Loading/>
                        }
                    </div>
                    
                </div>
            }
        </div>
    )
}

export default Admin