import React, { useContext } from 'react';
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import { makeRequest } from './axios.js';

//import Home from "./Components/Home/Home";
import Login from "./Components/Auth/SignIn"
import Register from "./Components/Auth/SignUp"
import Launch from "./Components/Landing/Launch"
import Profile from './Components/Dashboard/Profile';

//import Landing from './Components/Landing/Landing';
import Dashboard from './Components/Dashboard/Dashboard';
import AdminDashboard from './Components/Dashboard/AdminDashboard'
import ProfileConfiguration from './Components/ProfileConfiguration/ProfileConfiguration';
import AdminProfileConfiguration from './Components/ProfileConfiguration/AdminProfileConfiguration';
import ProfileSupport from './Components/ProfileSupport/ProfileSupport';
import ProfileContacts from "./Components/ProfileContacts/ProfileContacts"
import AdminProfileContacts from "./Components/ProfileContacts/AdminProfileContacts"
import ProfileMetrics from "./Components/ProfileMetrics/ProfileMetrics"
import AdminProfileMetrics from "./Components/ProfileMetrics/AdminProfileMetrics"
import ProfileBenefits from "./Components/ProfileBenefits/ProfileBenefits"
import ProfileTutorial from "./Components/ProfileTutorial/ProfileTutorial"
import CardProfile from "./Components/CardProfile/CardProfile"
import AProfile from "./Components/CardProfile/AProfile.jsx"
import QRGenerator from "./Components/Utilities/QRGenerator"
import CodeGenerator from "./Components/Utilities/CodeGenerator"
import UserCreator from "./Components/Utilities/UserCreator"
import PasswordReset from './Components/Utilities/PasswordReset.jsx'
import FindUser from "./Components/Utilities/FindUser.jsx"
import Landing2 from './Components/Landing2/Landing2';
import AddCards2 from './Components/Shopping/AddCards2';
import ProfileTBA from './Components/profileTBA/ProfileTBA'
import Admin from './Components/Admin/Admin'

import ThanksSuscription from "./Components/Landing/ThanksSubscription"
import PpolDynamicQR from './Components/Utilities/PpolDynamicQR'
import { AuthContext } from "./context/authContext" 
import { QueryClient, QueryClientProvider} from "@tanstack/react-query"
import NotFoundPage from './Components/NotFoundPage/NotFoundPage';
//import AddCards from './Components/Shopping/AddCards';
//import FormTest from './Components/Shopping/FormTest.jsx';
import TyC from './Components/TyC/TyC';
import PaymentRejected from './Components/Shopping/PaymentRejected';

//import ProfileTest from './Components/Dashboard/ProfileTest';

function App() {
  document.title = 'Ppol'
  let {currentUser} = useContext(AuthContext)
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: true,
        refetchOnReconnect: true,
        retry: false,
      }
    }
  }) 

  const ProtectedRoute = ({children}) => {
    if(!currentUser){
      return <Navigate to="/login"/>
    }
    return children
  }

  const LoggedInRoute = ({children}) => {
    if(currentUser){
      return <Navigate to={`/${currentUser.user}/edit`}/>
    }
    return children
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Landing2/>
    },
    {
      path: "/:id",
      element: (
        <QueryClientProvider client={queryClient}>
          <Profile/>
        </QueryClientProvider>)
    },
    {
      path: "/u/:id",
      element: (
        <QueryClientProvider client={queryClient}>
          <CardProfile/>
        </QueryClientProvider>)
    },
    {
      path: "/a/:id",
      element: (
        <QueryClientProvider client={queryClient}>
          <AProfile/>
        </QueryClientProvider>
      )
    },
    {
      path: "/:id/edit",
      element: (
        <ProtectedRoute>
          <QueryClientProvider client={queryClient}>
            <Dashboard/>
          </QueryClientProvider>
        </ProtectedRoute>
      )
    },
    {
      path: "/:id/admin",
      element: (
        <ProtectedRoute>
          <QueryClientProvider client={queryClient}>
            <Admin/>
          </QueryClientProvider>
        </ProtectedRoute>
      )
    },
    {
      path: "/:id/config",
      element: (
        <ProtectedRoute>
          <QueryClientProvider client={queryClient}>
            <ProfileConfiguration/>
          </QueryClientProvider>
        </ProtectedRoute>
      )
    },
    /*{
      path: "/:id/admin",
      element : (
        <ProtectedRoute>
          <QueryClientProvider client={queryClient}>
            <ProfileAdmin/>
          </QueryClientProvider>
        </ProtectedRoute>
      )
    },*/
    {
      path: "/:id/support",
      element: (
        <ProtectedRoute>
          <QueryClientProvider client={queryClient}>
            <ProfileSupport/>
          </QueryClientProvider>
        </ProtectedRoute>
      )
    },
    {
      path: "/:id/contacts",
      element: (
        <ProtectedRoute>
          <QueryClientProvider client={queryClient}>
            <ProfileContacts/>
          </QueryClientProvider>
        </ProtectedRoute>
      )
    },
    {
      path: "/:id/metrics",
      element: (
        <ProtectedRoute>
          <QueryClientProvider client={queryClient}>
            <ProfileMetrics/>
          </QueryClientProvider>
        </ProtectedRoute>
      )
    },
    {
      path: "/:id/benefits",
      element: (
        <ProtectedRoute>
          <QueryClientProvider client={queryClient}>
            <ProfileBenefits/>
          </QueryClientProvider>
        </ProtectedRoute>
      )
    },
    {
      path: "/:id/tutorial",
      element: (
        <ProtectedRoute>
          <QueryClientProvider client={queryClient}>
            <ProfileTutorial/>
          </QueryClientProvider>
        </ProtectedRoute>
      )
    },
    {
      path: '/:id/tba',
      element: (
        <ProtectedRoute>
          <QueryClientProvider client={queryClient}>
            <ProfileTBA/>
          </QueryClientProvider>
        </ProtectedRoute>
      )
    },
    {
      path: '/:id/:id2/edit',
      element: (
        <ProtectedRoute>
          <QueryClientProvider client={queryClient}>
            <AdminDashboard/>
          </QueryClientProvider>
        </ProtectedRoute>
      )
    },
    {
      path: '/:id/:id2/config',
      element: (
        <ProtectedRoute>
          <QueryClientProvider client={queryClient}>
            <AdminProfileConfiguration/>
          </QueryClientProvider>
        </ProtectedRoute>
      )
    },
    {
      path: '/:id/:id2/metrics',
      element: (
        <ProtectedRoute>
          <QueryClientProvider client={queryClient}>
            <AdminProfileMetrics/>
          </QueryClientProvider>
        </ProtectedRoute>
      )
    },
    {
      path: '/:id/:id2/contacts',
      element: (
        <ProtectedRoute>
          <QueryClientProvider client={queryClient}>
            <AdminProfileContacts/>
          </QueryClientProvider>
        </ProtectedRoute>
      )
    },
    {
      path: "/login",
      element: (
        <LoggedInRoute>
          <Login/>
        </LoggedInRoute>
      )
    },
    {
      path: "/register",
      element: <Register/>
    },
    {
      path: "/launch",
      element: <Launch/>
    },
    {
      path: "/thank-you",
      element: <ThanksSuscription/>
    },
    {
      path: "/404",
      element: <NotFoundPage/>
    },
    {
      path: "/utilities/qrgenerator",
      element: <QRGenerator/>
    },
    {
      path: "/utilities/codegenerator",
      element: <CodeGenerator/>
    },
    {
      path: "/utilities/usercreator",
      element: <UserCreator/>
    },
    {
      path: "/buy/ppol",
      element: <AddCards2/>
    },
    {
      path: "/tyc-privacidad",
      element: <TyC/>
    },
    {
      path: "/payment/rejected",
      element: <PaymentRejected/>
    },
    {
      path: "/utilities/userpassword",
      element: <PasswordReset/>
    },
    {
      path: "/utilities/user",
      element: <FindUser/>
    },
    {
      path: '/bienvenida',
      element: <PpolDynamicQR/>
    }
  ])
  
  return (
      <div>
        <RouterProvider router={router}/>
      </div>
  );
}

export default App;