import React, { useState, useEffect } from "react";
import { featuresData } from "./featuresData";


const Features = () => {

    const [isFlipped, setIsFlipped] = useState(false);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setIsFlipped(!isFlipped);
        }, 10000); 

        return () => clearInterval(intervalId);
    }, [isFlipped]);

  return (
    <section className="features-section-container">
            <div className="features-section">
                <h1>Todo lo que quieres en un solo lugar</h1>
                <div className="features-article-container">
                    {featuresData.map((data, index) => (
                        <div key={index} className={`card ${isFlipped ? 'is-flipped' : ''}`}>
                            <article className="features-article-item card__face card__face--front">
                                <video autoPlay muted loop controls={false} preload="metadata" playsInline>
                                    <source src={data.frontVideo} type="video/mp4"/>
                                </video>
                                <p>{data.frontText}</p>
                            </article>
                            <article className="features-article-item card__face card__face--back">
                                <video autoPlay muted loop controls={false} preload="metadata" playsInline>
                                    <source src={data.backVideo} type="video/mp4"/>
                                </video>
                                <p>{data.backText}</p>
                            </article>
                        </div>
                    ))}
                </div>
            </div>
        </section>
  )
}

export default Features