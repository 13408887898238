import React, { useState } from 'react'
import "./landing.css"

import ppolLogo from "../../images/ppolLogo.png"
import facebookLogo from "../../images/facebookLogo.png"
import instagramLogo from "../../images/instagramLogo.png"
import linkedinLogo from "../../images/linkedinLogo.png"
import tiktokLogo from "../../images/tiktokLogo.png"
import youtubeLogo from "../../images/youtubeLogo.png"
import profilesImg from "../../images/profilesImg.png"

function Launch() {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");

    const changeName = (e) => {
        setName(e.target.value)
    }

    const changeEmail = (e) => {
        setEmail(e.target.email)
    }

    const closeNavbar = () => {
        document.querySelector("#menu-toggle").checked = false
    }

    return(
        
        <div className="landingBG">
            <nav className="navbar">
                <div className="navbarMini">
                    <a href="/" onClick={closeNavbar}>
                        <img className="ppolLogoMini" loading="lazy" src={ppolLogo} alt="Ppol Logo"/>
                    </a>
                    <input id="menu-toggle" type="checkbox"/>
                    <label className="menu-button-container" htmlFor="menu-toggle">
                        <div className="menu-button"></div>
                    </label>
                    <ul className="menu"> 
                
                    <li className="ppolLogoNavLi">
                        <a href="/launch">
                            <button id="navBtnMini1" className="navBtnMini">
                                Iniciar sesión
                            </button>
                        </a>
                        <a href="/launch">
                            <button id="navBtnMini2" className="navBtnMini">
                                Crear cuenta
                            </button>
                        </a>
                    </li>
                    <li className="ppolLogoNavLi">
                        <a href="/#custom-Section" onClick={closeNavbar}>Producto</a>
                    </li>
                    {/*<li className="ppolLogoNavLi">
                        <a href="/#communitySection" onClick={closeNavbar}>Comunidad</a>
                    </li>*/}
                    <li className="ppolLogoNavLi">
                        <a href="/#ppolMeUs" onClick={closeNavbar}>Públicos</a>
                    </li>
                    <li className="ppolLogoNavLi">
                        <a href="/#faqSection" onClick={closeNavbar}>FAQ</a>
                    </li>
                    <li className="ppolLogoNavLi">
                        <a href="/#suscriptionSection" onClick={closeNavbar}>Suscríbete</a>
                    </li>
                    <li id="navCopyright">
                        ®2023 Ppol. Tecnología patentada
                    </li>
                
            </ul>
                </div>



                <ul className="navLeft">
                    <li className="ppolLogoContainer">
                        <a href="/">
                            <img className="ppolLogo" loading="lazy" src={ppolLogo} alt="Ppol Logo"/>
                            
                        </a>
                    </li>
                    <li className="ppolLogoNavLi">
                        <a href="/#custom-Section">Producto</a>
                    </li>
                    {/*<li className="ppolLogoNavLi">
                        <a href="/#communitySection">Comunidad</a>
                    </li>*/}
                    <li className="ppolLogoNavLi">
                        <a href="/#ppolMeUs">Públicos</a>
                    </li>
                    <li className="ppolLogoNavLi">
                        <a href="/#faqSection">FAQ</a>
                    </li>
                    <li className="ppolLogoNavLi">
                        <a href="/#suscriptionSection">Suscríbete</a>
                    </li>
                </ul>
                <ul className="navRight">
                    <li className="ppolLogoNavLi">
                        <a href="/launch">
                            <button id="btnLogin" className="btnNav">Iniciar sesión</button>
                        </a>
                    </li>
                    <li className="ppolLogoNavLi">
                        <a href="/launch">
                            <button id="btnCreate" className="btnNav">Crear cuenta</button>
                        </a>
                    </li>
                </ul>
            </nav>

            <section className='launch-date'>
                <h1>Lanzamiento</h1>
                <h2>19.06.23</h2>
                <img alt="Perfiles ppol" loading="lazy" src={profilesImg}/>
            </section>

            <section className="suscriptionSection">
                <p>Quiero ser parte</p>
                <span>Suscríbete y entérate de todas las promociones,</span>
                <span>descuentos, eventos y más.</span>
                <form className="suscriptionSectionForm" target="_blank" action="https://ppol.us21.list-manage.com/subscribe/post?u=395cc463b19c7104cfd42420f&amp;id=9e65811a91&amp;f_id=00ae56e1f0" method="post">
                        <input className="suscriptionFormInput suscriptionFormInput1" onChange={changeEmail} placeholder="Nombre" type="text" value={email} name="NAME" id="mce-NAME" required/>
                        <span id="mce-NAME-HELPERTEXT" className="helper_text"></span>
                        <input className="suscriptionFormInput suscriptionFormInput2" onChange={changeName} placeholder="Correo" type="email" value={name} name="EMAIL" id="mce-EMAIL" required/>
                        <span id="mce-EMAIL-HELPERTEXT" className="helper_text"></span>
                        <div className="response" id="mce-error-response" style={{display: "none"}}></div>
                        <div className="response" id="mce-success-response" style={{display: "none"}}></div>
                           
                        {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups*/}
                        <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true">
                            <input onChange={(e)=>e.target.value} type="text" name="b_395cc463b19c7104cfd42420f_9e65811a91" tabIndex="-1" value=""/>
                        </div>
                        <input className="suscriptionSectionBtn" type="submit" value="Enviar" name="subscribe" id="mc-embedded-subscribe"/> 
                </form>
            </section>  
            
            <footer className="foot">
                <p className="footP1">Conecta con nosotros</p>
                <div className="footImgContainer">
                    <a href="https://www.facebook.com/profile.php?id=100089400941802" target="_blank" rel="noreferrer">
                        <img loading="lazy" src={facebookLogo} alt="Facebook Ppol"/>
                    </a>
                    <a href="https://www.instagram.com/somosppol/" target="_blank" rel="noreferrer">
                        <img loading="lazy" src={instagramLogo} alt="Instagram Ppol"/>
                    </a>
                    <a href="https://www.linkedin.com/company/somosppol/" target="_blank" rel="noreferrer">
                        <img loading="lazy" src={linkedinLogo} alt="LinkedIn Ppol"/>
                    </a>
                    <a href="https://www.tiktok.com/@somosppol" target="_blank" rel="noreferrer">
                        <img loading="lazy" src={tiktokLogo} alt="TikTok Ppol"/>
                    </a>
                    <a href="https://www.youtube.com/@somosppol" target="_blank" rel="noreferrer">
                        <img loading="lazy" src={youtubeLogo} alt="Youtube Ppol"/>
                    </a>
                    {/*<a href="https://twitter.com/somosppol" target="_blank" rel="noreferrer">
                        <img loading="lazy" src={youtubeLogo} alt="Twitter Ppol"/>
                    </a>
                    <a href="https://www.pinterest.es/somosppol/" target="_blank" rel="noreferrer">
                        <img loading="lazy" src={youtubeLogo} alt="Pinterest Ppol"/>
                    </a>
                    <a href="https://open.spotify.com/user/31nq66sd45n6ab3twe2wxmw32uge" target="_blank" rel="noreferrer">
                        <img loading="lazy" src={youtubeLogo} alt="Spotify Ppol"/>
                    </a>
                    <a href="https://discord.gg/Qrgqdkr5dU" target="_blank" rel="noreferrer">
                        <img loading="lazy" src={youtubeLogo} alt="Discord Ppol"/>
                    </a>*/}
                </div>
                <ul className="footLiContainer">
                    <a href="/#custom-Section">
                        <li>Producto</li>
                    </a>
                    {/*<a href="/#communitySection">
                        <li>Comunidad</li>
                    </a>*/}
                    <a href="/#ppolMeUs">
                        <li>Públicos</li>
                    </a>
                    <a href="/#faqSection">
                        <li>FAQ</li>
                    </a>
                    <a href="/#suscriptionSection">
                        <li>Suscríbete</li>
                    </a>
                </ul>
                <p className="footP2">®2023 Ppol. Tecnología patentada</p>
            </footer>
        </div>
        
    )
}

export default Launch