import feriasImg from './banner-1.webp'
import materialPopImg from './table-tend.webp'
import firmaCorporativaImg from './firma-corporativa.webp'
import walletImg from './wallet.webp'
import piezasFisicasImg from './lanyard.webp'
import widgetsImg from './widgets.webp'

export const shareMetodsData = [
    {
      id: 1,
      title: 'Wallet y Apple Watch',
      description: 'Agrega tu PPOL a tu Apple/Google Wallet y Apple Watch para llevar tu tarjeta de presentación contigo en todo momento.',
      image: walletImg,
    },
    {
      id: 2,
      title: 'Ferias',
      description: 'Asegúrate de mostrar toda tu información y añade tu QR en materiales impresos como: trípticos, volantes, parantes y más.',
      image: feriasImg,
    },
    {
      id: 3,
      title: 'Piezas físicas',
      description: 'Amplía la presencia de tu marca añadiendo tu código QR en piezas físicas como stickers, lanyards, empaques, pins y más. ',
      image: piezasFisicasImg,
    },
    {
        id: 4,
        title: 'Material POP',
        description: 'Agrega tu QR para que tus clientes puedan acceder fácilmente a información relevante, ofertas exclusivas o enlaces directos, brindando una experiencia única. ',
        image: materialPopImg,
      },
      {
        id: 5,
        title: 'Firma Corporativa',
        description: 'Dale un toque único a tus correos al adjuntar tu tarjeta de presentación, creando una firma exclusiva que refleje tu profesionalismo.',
        image: firmaCorporativaImg,
      },
      {
        id: 6,
        title: 'Widgets',
        description: 'Incorpora tu código QR en widgets de celular, tenlo siempre a la mano y destaca en todo momento.',
        image: widgetsImg,
      },

  ];