import React, {useEffect, useState} from 'react'
import { makeRequest } from '../../axios'

import "./styles.css"
import ppolLogo from "../../images/ppolLogo.png"

function CodeGenerator() {
    const [numCodes, setNumCodes] = useState(1)
    const [codes, setCodes] = useState([])
    const numDigits = 8;
    const [status, setStatus] = useState([])

    const generateCodes = () => {
        setCodes([])
        for(let i = 0; i<numCodes; i++){
            const code = (Math.floor(Math.random()*Math.pow(10, numDigits)).toString().padStart(numDigits,"0"))
            makeRequest.get("/verifyCode.php", {params: {code: code}})
            .then(() => {
                setCodes(prevCodes=>{
                    const newCodes = [...prevCodes];
                    newCodes.push({code:code, free:true})
                    return newCodes
                })
                console.log(`${i}: ${code} válido ✔️`)
            })
            .catch(() => {
                setCodes(prevCodes=>{
                    const newCodes = [...prevCodes];
                    newCodes.push({code:code, free:false})
                    return newCodes
                })
                console.log(`${code} inválido ❌`)
            })
        }
    }

    const copyCodes = (codes) => {
        const freeCodes = []
        for(let i = 0; i < codes.length; i++){
            if(codes[i].free){
                freeCodes.push(codes[i].code)
            }
        }
        const tempURL = document.createElement("input");
        tempURL.setAttribute("value", freeCodes.join(" "));
        document.body.appendChild(tempURL);
        tempURL.select();
        document.execCommand("copy");
        document.body.removeChild(tempURL);
        window.alert(freeCodes.length>1?`${freeCodes.length} códigos copiados.`:freeCodes.length===1?`${freeCodes.length} código copiado.`:"No se copió ningún código.")
    }

    useEffect(()=>{
        setStatus([])
        if(codes.length>0){
            setStatus(
                <>
                    {(codes.length/numCodes)>=1?<button className="copyCodesBtn" onClick={()=>copyCodes(codes)}>Copiar códigos</button>:<></>}
                    <p className='copyCodeTitle'>Generado {codes.length} de {numCodes} | {Math.ceil((codes.length/numCodes)*100)}%</p>
                </>
            )
        }
    },[codes])

    return (
    <div className="codeGenerator">
        <img className="codeGeneratorImg" loading="lazy" src={ppolLogo} alt=""/>
        <h1 className="codeGeneratorTitle">Generador de Códigos</h1>
        <div className="codeGeneratorInfo">

            <input className="codeGeneratorNumber" min="1" max="1000" type="number" placeholder='Cantidad de códigos' value={numCodes} onChange={(e)=>setNumCodes(e.target.value)}/>
            <button className='codeGeneratorBtn' onClick={generateCodes}>Generar códigos</button>
        </div>
        <div className='codesGenerated'>
            {status}
            {codes.map(infocode=>(
                <span key={infocode.code} className='codeGenerated'>{infocode.free?"✔️":"❌"} {infocode.code}</span>
            ))}
        </div>
    </div>
  )
}

export default CodeGenerator