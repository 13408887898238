export const faqData = [
    {
        id: 1,
        question: '¿Por qué elegir una tarjeta PPOL?',
        answer: 'Con PPOL podrás compartir tu información de contacto de forma rápida y moderna, sin necesidad de reimprimir tarjetas cada vez que modifiques tus datos. Con una sola tarjeta tendrás la posibilidad de mostrar no solo tu información básica sino que tu perfil PPOL será una manera atractiva, impactante y diferente de presentarte al mundo.',
    },
    {
        id: 2,
        question: '¿Cuánto demora la creación de mi perfil PPOL y qué información puedo colocar?',
        answer: 'La creación de tu perfil es rápida y sencilla. Luego de registrarte ya puedes acceder inmediatamente a tu cuenta y comenzar a crear tu perfil. Si necesitas asesoramiento o tienes alguna pregunta, nuestro equipo de soporte siempre estará disponible para ayudarte a crear el perfil que deseas. Podrás crear tu perfil de acuerdo a tus necesidades, colocando foto de perfil, foto de portada, botones de redes sociales, títulos y textos, imágenes, videos, carruseles, archivos pdf, botones de acción entre muchas otras funcionalidades.',
    },
    {
        id: 3,
        question: '¿Cuántas veces puedo modificar mi información?',
        answer: 'Entendemos la importancia de crear un perfil único y personalizado que refleje siempre tu mejor versión. Por eso, ofrecemos la opción de modificar tu perfil tantas veces como sea necesario, sin costo adicional. Creemos que es fundamental que puedas adaptar tu perfil a medida que cambian tus objetivos y necesidades.',
    },
    {
        id: 4,
        question: '¿Qué es la tecnología NFC? ¿Cualquier dispositivo la tiene?',
        answer: 'El NFC (Near Field Communication) es una tecnología que permite la transferencia de datos entre un dispositivo a otro, la cual se da de manera inalámbrica sin la necesidad de usar alguna conexión de internet. Hoy en día, la mayoría de los celulares Android y Iphone están equipados con esta tecnología. No obstante, si el dispositivo no cuenta con esta tecnología no te preocupes, para ello se encuentra el código QR que puedes escanear con tu cámara',
    },
    {
        id: 5,
        question: '¿Tengo que pagar una suscripción?',
        answer: 'No, con PPOL harás un único pago y obtendrás no solo tu tarjeta personalizada sino el acceso a la plataforma para poder crear tu perfil, editarlo cuantas veces necesites, ver sus métricas y gestionar los contactos que generes. ',
    }
  ];