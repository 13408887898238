import React, { useContext, useState, useEffect} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/authContext'
import { useQuery } from '@tanstack/react-query'
import { makeRequest } from '../../axios'

import Loading from '../Loading/Loading'

import verifiedIcon from "../../images/verifiedIcon.svg"
import profileDefaultImg from "../../images/profileDefaultImg.png"
import bannerDefaultImg from "../../images/bannerDefaultImg.png"

function AdminUsers({adminList}) {

  let {currentUser} = useContext(AuthContext);
  const navigate = useNavigate()
  const userId = useLocation().pathname.split("/")[1];

  const [usersList, setUsersList] = useState([])
  const [showInfo, setShowInfo] = useState(false)
  const [userSearchBar, setUserSearchBar] = useState('')
  const [usersFilteredList, setUsersListeredList] = useState([])
  const [userMainModal, setUserMainModal] = useState(false)
  const [userConfigData, setUserConfigData] = useState(null)
  const [showUserConfigData, setShowUserConfigData] = useState(false)

  const { } = useQuery(['user'], async () =>{
    try{
      makeRequest.get('findUserCode.php', {params: {user: JSON.parse(adminList)}})
      .then((res)=>{
        setUsersList(res.data)
        setShowInfo(true)
      })
      .catch(()=>{
        setUsersList([])
        setShowInfo(true)
      })
    } catch(error){
      localStorage.clear()
      navigate('/404')
    }
  })

  /*useEffect(()=>{
    console.log(usersList)
  },[usersList])*/

  //Aplica el filtro de Búsqueda 
  useEffect(()=>{
    if(userSearchBar.length>0){
      const filteredContacts = usersList.filter((user)=>
        JSON.parse(user.profileBio).name.toLowerCase().includes(userSearchBar.toLowerCase()) ||
        JSON.parse(user.profileBio).title.toLowerCase().includes(userSearchBar.toLowerCase())
      )
      console.log(filteredContacts)
      setUsersListeredList(filteredContacts)
    } else {
      setUsersListeredList(usersList)
    }
  },[userSearchBar, usersList])

  const handleUserConfig = async (username) => {
    try {
      const response = await makeRequest.get('findUserBasicInfo.php', {params: {user: username}})
        setUserConfigData(response.data);
        setShowUserConfigData(true);
    } catch (error){
      console.error("Error al obtener la información de usuario.")
    }
  }

  const handleUserCheckboxChange = (propertyName) => {
    const userSettings = JSON.parse(userConfigData.profileSettings);
    userSettings[propertyName] = !userSettings[propertyName];
    const stringUserSettings = JSON.stringify(userSettings);
    setUserConfigData({...userConfigData, profileSettings: stringUserSettings})
  }

const handleUserConfigSave = async (userlink, profileSettings) => {
  try{
    await makeRequest.post('/updateUserSettings.php', {
      "userlink":userlink,
      "profileSettings":profileSettings
    })
    setUserMainModal(false)
    setUserConfigData(null)
    setShowUserConfigData(false)
    }catch(error){
      throw(error)
    }
  }

  return (
    <div className='configuration'>
      {!showInfo ? 
        <Loading/>
        :
        <section className='adminUsersMainCont'>
          {userMainModal&&
            <div className='floatInfoBg'>
              {showUserConfigData?
                <div className='floatAdminUserCont'>
                  <div className='floatAdminUserTitleCont'>
                    <button
                      className='floatAdminUserTitleContBtn'
                      onClick={()=>{
                        setUserMainModal(false)
                        setUserConfigData(null)
                        setShowUserConfigData(false)
                      }}
                    >
                      <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.4494 16.2337C11.2982 16.2337 11.1542 16.1761 11.039 16.0609L5.20695 10.2073C4.98375 9.98408 4.98375 9.61688 5.20695 9.39368L11.039 3.54728C11.2622 3.32408 11.6294 3.32408 11.8526 3.54728C12.0758 3.77048 12.0758 4.13768 11.8526 4.36088L6.42375 9.80408L11.8526 15.2473C12.0758 15.4705 12.0758 15.8377 11.8526 16.0609C11.7373 16.1761 11.5934 16.2265 11.4494 16.2265V16.2337Z" fill="#141414"/>
                      </svg>
                      <span>Atrás</span>
                    </button>
                    <p className='floatAdminUserTitle'>Configuración de Usuario</p>
                  </div>
                  <div className='floatAdminSeparation'/>
                  <div className='floatAdminUserDetailsCont'>
                    <div className='floatAdminUserDetailsMain'>
                      <img className='floatAdminUserProfileImg' alt='' src={JSON.parse(userConfigData.profileImages).profile?JSON.parse(userConfigData.profileImages).profile.includes('storage.googleapis')?JSON.parse(userConfigData.profileImages).profile:`https://ppol.io/media/${userConfigData.userlink}/${JSON.parse(userConfigData.profileImages).profile}`:profileDefaultImg}/>
                      <div className='floatAdminUserProfileTitleCont'>
                        <span className='floatAdminUserProfileTitle'>{JSON.parse(userConfigData.profileBio).name}</span>
                        <span className='floatAdminUserProfileSubtitle'>{JSON.parse(userConfigData.profileBio).title}</span>
                        <div className='floatAdminUserProfileLabelCont'>
                          <div className='floatAdminUserProfileLabelCircle'/>
                          <span className='floatAdminUserProfileLabelText'>Recursos Humanos</span>
                        </div>
                      </div>
                    </div>
                    <div className='floatAdminUserDetailsBtnsCont'>
                      <a
                        className='floatAdminUserDetailsBtn'
                        href={`/${userId}/${JSON.parse(userConfigData.userlink)}/config`}
                        target='_blank'
                        rel='noreferrer'
                      >
                        Editar Configuración
                      </a>
                      <a
                        className='floatAdminUserDetailsBtn'
                        href={`/${userId}/${JSON.parse(userConfigData.userlink)}/edit`}
                        target='_blank'
                        rel='noreferrer'
                      >
                        Editar Perfil
                      </a>
                      {/*<a
                        className='floatAdminUserDetailsBtn'
                        href={`/${userId}/${JSON.parse(userConfigData.userlink)}/metrics`}
                        target='_blank'
                        rel='noreferrer'
                      >
                        Ver Métricas
                      </a>*/}
                      <a
                        className='floatAdminUserDetailsBtn'
                        href={`/${userId}/${JSON.parse(userConfigData.userlink)}/contacts`}
                        target='_blank'
                        rel='noreferrer'
                      >
                        Ver Contactos
                      </a>
                    </div>
                  </div>
                  <div className='floatAdminSeparation'/>
                  <p className='floatAdminSectionTitle'>Permitir al usuario</p>
                  <p className='floatAdminSectionSubtitle'>Crea las plantillas que te permitirán crear los perfiles de tus equipos de trabajo de mane (texto de referencia).</p>
                  <div className='floatAdminPermitSectionCont'>
                    <div className='floatAdminPermitSectionColumn'>
                      <div className='floatAdminPermitSection'>
                        <p className='floatAdminPermitSectionTitle'>Perfil</p>
                        {/*<div className='floatAdminPermitSectionCheckRow'>
                          <p className='floatAdminPermitSectionCheckRowText'>Habilitar edición de grupo</p>
                          <input type='checkbox'/>
                        </div>*/}
                        <div className='floatAdminPermitSectionCheckRowCont'>
                          <div className="floatAdminPermitSectionCheckRow">
                            <p className='floatAdminPermitSectionCheckRowText'>Cambiar foto de perfil</p>
                            <input type='checkbox'
                              checked={JSON.parse(userConfigData.profileSettings).canChangeProfilePhoto}
                              onChange={()=>handleUserCheckboxChange('canChangeProfilePhoto')}
                            />
                          </div>
                          <div className="floatAdminPermitSectionCheckRow">
                            <p className='floatAdminPermitSectionCheckRowText'>Cambiar foto de portada</p>
                            <input type='checkbox'
                              checked={JSON.parse(userConfigData.profileSettings).canChangeBannerPhoto}
                              onChange={()=>handleUserCheckboxChange('canChangeBannerPhoto')}
                            />
                          </div>
                          <div className="floatAdminPermitSectionCheckRow">
                            <p className='floatAdminPermitSectionCheckRowText'>Cambiar nombre</p>
                            <input type='checkbox'
                              checked={JSON.parse(userConfigData.profileSettings).canChangeWebName}
                              onChange={()=>handleUserCheckboxChange('canChangeWebName')}
                            />
                          </div>
                          <div className="floatAdminPermitSectionCheckRow">
                            <p className='floatAdminPermitSectionCheckRowText'>Cambiar título</p>
                            <input type='checkbox'
                              checked={JSON.parse(userConfigData.profileSettings).canChangeTitle}
                              onChange={()=>handleUserCheckboxChange('canChangeTitle')}
                            />
                          </div>
                          <div className="floatAdminPermitSectionCheckRow">
                            <p className='floatAdminPermitSectionCheckRowText'>Cambiar descripción</p>
                            <input type='checkbox'
                              checked={JSON.parse(userConfigData.profileSettings).canChangeDescription}
                              onChange={()=>handleUserCheckboxChange('canChangeDescription')}
                            />
                          </div>
                          <div className="floatAdminPermitSectionCheckRow">
                            <p className='floatAdminPermitSectionCheckRowText'>Cambiar redes sociales</p>
                            <input type='checkbox'
                              checked={JSON.parse(userConfigData.profileSettings).canChangeSocialMedia}
                              onChange={()=>handleUserCheckboxChange('canChangeSocialMedia')}
                            />
                          </div>
                          <div className="floatAdminPermitSectionCheckRow">
                            <p className='floatAdminPermitSectionCheckRowText'>Edición de botones</p>
                            <input type='checkbox'
                              checked={JSON.parse(userConfigData.profileSettings).canChangeButtonsConfig}
                              onChange={()=>handleUserCheckboxChange('canChangeButtonsConfig')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='floatAdminPermitSection'>
                        <p className='floatAdminPermitSectionTitle'>Componentes</p>
                        {/*<div className='floatAdminPermitSectionCheckRow'>
                          <p className='floatAdminPermitSectionCheckRowText'>Habilitar edición de grupo</p>
                          <input type='checkbox'/>
                        </div>*/}
                        <div className='floatAdminPermitSectionCheckRowCont'>
                          <div className="floatAdminPermitSectionCheckRow">
                            <p className='floatAdminPermitSectionCheckRowText'>Crear y eliminar componentes</p>
                            <input type='checkbox'
                              checked={JSON.parse(userConfigData.profileSettings).canAddDeleteComponents}
                              onChange={()=>handleUserCheckboxChange('canAddDeleteComponents')}
                            />
                          </div>
                          <div className="floatAdminPermitSectionCheckRow">
                            <p className='floatAdminPermitSectionCheckRowText'>Editar componentes</p>
                            <input type='checkbox'
                              checked={JSON.parse(userConfigData.profileSettings).canEditComponents}
                              onChange={()=>handleUserCheckboxChange('canEditComponents')}
                            />
                          </div>
                          <div className="floatAdminPermitSectionCheckRow">
                            <p className='floatAdminPermitSectionCheckRowText'>Mover Componentes</p>
                            <input type='checkbox'
                              checked={JSON.parse(userConfigData.profileSettings).canMoveComponents}
                              onChange={()=>handleUserCheckboxChange('canMoveComponents')}
                            />
                          </div>
                          <div className="floatAdminPermitSectionCheckRow">
                            <p className='floatAdminPermitSectionCheckRowText'>Seleccionar plantillas</p>
                            <input type='checkbox'
                              checked={JSON.parse(userConfigData.profileSettings).canSelectTemplates}
                              onChange={()=>handleUserCheckboxChange('canSelectTemplates')}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='floatAdminPermitSectionColumn'>
                      <div className='floatAdminPermitSection'>
                        <p className='floatAdminPermitSectionTitle'>Contactos</p>
                        {/*<div className='floatAdminPermitSectionCheckRow'>
                          <p className='floatAdminPermitSectionCheckRowText'>Habilitar edición de grupo</p>
                          <input type='checkbox'/>
                        </div>*/}
                        <div className='floatAdminPermitSectionCheckRowCont'>
                          <div className="floatAdminPermitSectionCheckRow">
                            <p className='floatAdminPermitSectionCheckRowText'>Editar contactos</p>
                            <input type='checkbox'
                              checked={JSON.parse(userConfigData.profileSettings).canEditContacts}
                              onChange={()=>handleUserCheckboxChange('canEditContacts')}
                            />
                          </div>
                          <div className="floatAdminPermitSectionCheckRow">
                            <p className='floatAdminPermitSectionCheckRowText'>Descargar contactos (vcards)</p>
                            <input type='checkbox'
                              checked={JSON.parse(userConfigData.profileSettings).canDownloadContacts}
                              onChange={()=>handleUserCheckboxChange('canDownloadContacts')}
                            />
                          </div>
                          <div className="floatAdminPermitSectionCheckRow">
                            <p className='floatAdminPermitSectionCheckRowText'>Eliminar contactos</p>
                            <input type='checkbox'
                              checked={JSON.parse(userConfigData.profileSettings).canDeleteContacts}
                              onChange={()=>handleUserCheckboxChange('canDeleteContacts')}
                            />
                          </div>
                          <div className="floatAdminPermitSectionCheckRow">
                            <p className='floatAdminPermitSectionCheckRowText'>Exportar base de datos</p>
                            <input type='checkbox'
                              checked={JSON.parse(userConfigData.profileSettings).canExportContacts}
                              onChange={()=>handleUserCheckboxChange('canExportContacts')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='floatAdminPermitSection'>
                        <p className='floatAdminPermitSectionTitle'>Información de registro</p>
                        {/*<div className='floatAdminPermitSectionCheckRow'>
                          <p className='floatAdminPermitSectionCheckRowText'>Habilitar edición de grupo</p>
                          <input type='checkbox'/>
                        </div>*/}
                        <div className='floatAdminPermitSectionCheckRowCont'>
                          <div className="floatAdminPermitSectionCheckRow">
                            <p className='floatAdminPermitSectionCheckRowText'>Cambiar nombre de usuario</p>
                            <input type='checkbox'
                              checked={JSON.parse(userConfigData.profileSettings).canChangeUsername}
                              onChange={()=>handleUserCheckboxChange('canChangeUsername')}
                            />
                          </div>
                          <div className="floatAdminPermitSectionCheckRow">
                            <p className='floatAdminPermitSectionCheckRowText'>Cambiar contraseña</p>
                            <input type='checkbox'
                              checked={JSON.parse(userConfigData.profileSettings).canChangePassword}
                              onChange={()=>handleUserCheckboxChange('canChangePassword')}
                            />
                          </div>
                          <div className="floatAdminPermitSectionCheckRow">
                            <p className='floatAdminPermitSectionCheckRowText'>Cambiar nombre</p>
                            <input type='checkbox'
                              checked={JSON.parse(userConfigData.profileSettings).canChangeName}
                              onChange={()=>handleUserCheckboxChange('canChangeName')}
                            />
                          </div>
                          <div className="floatAdminPermitSectionCheckRow">
                            <p className='floatAdminPermitSectionCheckRowText'>Cambiar apellido</p>
                            <input type='checkbox'
                              checked={JSON.parse(userConfigData.profileSettings).canChangeLastname}
                              onChange={()=>handleUserCheckboxChange('canChangeLastname')}
                            />
                          </div>
                          <div className="floatAdminPermitSectionCheckRow">
                            <p className='floatAdminPermitSectionCheckRowText'>Cambiar correo</p>
                            <input type='checkbox'
                              checked={JSON.parse(userConfigData.profileSettings).canChangeEmails}
                              onChange={()=>handleUserCheckboxChange('canChangeEmails')}
                            />
                          </div>
                          <div className="floatAdminPermitSectionCheckRow">
                            <p className='floatAdminPermitSectionCheckRowText'>Cambiar teléfono</p>
                            <input type='checkbox'
                              checked={JSON.parse(userConfigData.profileSettings).canChangePhones}
                              onChange={()=>handleUserCheckboxChange('canChangePhones')}
                            />
                          </div>
                          <div className="floatAdminPermitSectionCheckRow">
                            <p className='floatAdminPermitSectionCheckRowText'>Cambiar compañía</p>
                            <input type='checkbox'
                              checked={JSON.parse(userConfigData.profileSettings).canChangeCompany}
                              onChange={()=>handleUserCheckboxChange('canChangeCompany')}
                            />
                          </div>
                          <div className="floatAdminPermitSectionCheckRow">
                            <p className='floatAdminPermitSectionCheckRowText'>Cambiar cargo</p>
                            <input type='checkbox'
                              checked={JSON.parse(userConfigData.profileSettings).canChangePosition}
                              onChange={()=>handleUserCheckboxChange('canChangePosition')}
                            />
                          </div>
                          <div className="floatAdminPermitSectionCheckRow">
                            <p className='floatAdminPermitSectionCheckRowText'>Cambiar web en VCard</p>
                            <input type='checkbox'
                              checked={JSON.parse(userConfigData.profileSettings).canChangeURL}
                              onChange={()=>handleUserCheckboxChange('canChangeURL')}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*<p className='floatAdminSectionTitle'>Cambiar etiqueta</p>
                  <p className='floatAdminSectionSubtitle'>Crea las plantillas que te permitirán crear los perfiles de tus equipos de trabajo de mane (texto de referencia).</p>
                  <div className='floatAdminPermitSectionCont'>
                    <button className='floatAdminUserDetailsBtn'>Gerencia</button>
                    <button className='floatAdminUserDetailsBtn'>Comercial</button>
                    <button className='floatAdminUserDetailsBtn'>RRHH</button>
                    <button className='floatAdminUserDetailsBtn'>Marketing</button>
                    <button className='floatAdminUserDetailsBtn'>Logística</button>
                  </div>*/}
                  <button className='floatAdminUserDetailsBtn' onClick={()=>handleUserConfigSave(userConfigData.userlink, userConfigData.profileSettings)}>Guardar cambios</button>
                </div>
                :
                <Loading/>
              }               
            </div>
          }
          <div className='adminUsersBtns'>
            <div className='adminUsersBtnsRow'>
              <div className='adminUsersFilterInputCont'>
                <svg className='adminUsersFilterInputIcon' width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16.625 16.625L13.1812 13.1812M15.0417 8.70833C15.0417 12.2061 12.2061 15.0417 8.70833 15.0417C5.21053 15.0417 2.375 12.2061 2.375 8.70833C2.375 5.21053 5.21053 2.375 8.70833 2.375C12.2061 2.375 15.0417 5.21053 15.0417 8.70833Z" stroke="#141414" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <input className='adminUsersFilterInput' type='text' placeholder='Buscar' value={userSearchBar} onChange={(e)=>setUserSearchBar(e.target.value)}/>
              </div>
              {/*<button className='adminUsersFilterBtn'>
                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M1.66699 5.49984C1.66699 5.0396 2.04009 4.6665 2.50033 4.6665H17.5003C17.9606 4.6665 18.3337 5.0396 18.3337 5.49984C18.3337 5.96007 17.9606 6.33317 17.5003 6.33317H2.50033C2.04009 6.33317 1.66699 5.96007 1.66699 5.49984ZM4.16699 10.4998C4.16699 10.0396 4.54009 9.6665 5.00033 9.6665H15.0003C15.4606 9.6665 15.8337 10.0396 15.8337 10.4998C15.8337 10.9601 15.4606 11.3332 15.0003 11.3332H5.00033C4.54009 11.3332 4.16699 10.9601 4.16699 10.4998ZM6.66699 15.4998C6.66699 15.0396 7.04009 14.6665 7.50033 14.6665H12.5003C12.9606 14.6665 13.3337 15.0396 13.3337 15.4998C13.3337 15.9601 12.9606 16.3332 12.5003 16.3332H7.50033C7.04009 16.3332 6.66699 15.9601 6.66699 15.4998Z" fill="#141414"/>
                </svg>
                <span>Filtros</span>
              </button>*/}
            </div>
            <div className='adminUsersBtnsRow'>
              {/*<button className='adminUsersBtn'>
                <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.4979 13.019C3.43342 11.9545 2.36173 10.8967 1.30703 9.82246C0.760892 9.26602 0.496831 8.56443 0.507126 7.79541C0.514332 7.2503 0.620883 6.70468 0.701697 6.16266C0.824205 5.34216 0.973995 4.52579 1.09187 3.70478C1.22055 2.8081 1.50624 2.00716 2.22893 1.39617C2.63352 1.05387 3.07825 0.815027 3.58527 0.71877C4.47423 0.549936 5.37039 0.420221 6.26346 0.275579C6.83431 0.183441 7.40309 0.0593885 7.97806 0.0115177C8.94834 -0.0692964 9.78377 0.278153 10.4684 0.959668C12.4934 2.97436 14.5106 4.99729 16.5289 7.01919C16.6854 7.17618 16.8455 7.33884 16.9639 7.52414C17.4199 8.23603 17.5919 9.0102 17.4374 9.8518C17.3391 10.3856 17.144 10.8869 16.7693 11.2678C15.1504 12.9145 13.5089 14.5395 11.8757 16.1723C11.8118 16.2361 11.7562 16.3108 11.6837 16.3617C10.9033 16.9084 10.0478 17.124 9.1038 16.93C8.622 16.8306 8.16028 16.6577 7.80819 16.3144C6.6943 15.2267 5.60151 14.1174 4.50048 13.0164C4.49945 13.0174 4.49842 13.0185 4.49739 13.0195L4.4979 13.019ZM8.54685 15.3101C8.94937 15.7054 9.46514 15.8084 10.0144 15.7415C10.4679 15.6859 10.8364 15.4722 11.1602 15.1464C12.7147 13.5801 14.2841 12.0286 15.834 10.4571C16.1156 10.1715 16.2309 9.78128 16.252 9.37412C16.2834 8.76724 16.0492 8.27772 15.6235 7.85358C13.6175 5.85536 11.6183 3.85045 9.61493 1.84965C9.13622 1.37146 8.56229 1.15064 7.88335 1.26028C6.54863 1.47492 5.21391 1.68803 3.88125 1.91503C3.08134 2.05143 2.4899 2.74376 2.37615 3.53646C2.24437 4.45733 2.08223 5.37408 1.93398 6.29237C1.86398 6.72733 1.76309 7.16022 1.73272 7.59775C1.69257 8.17478 1.89435 8.67819 2.31386 9.0941C3.3418 10.1138 4.36304 11.1402 5.38686 12.164C5.3884 12.1625 5.38995 12.1609 5.39149 12.1594C6.44259 13.2105 7.48751 14.2683 8.54788 15.3096L8.54685 15.3101Z" fill="#141414"/>
                  <path d="M6.02076 6.88087C6.76842 6.88087 7.37452 6.27477 7.37452 5.52711C7.37452 4.77944 6.76842 4.17334 6.02076 4.17334C5.27309 4.17334 4.66699 4.77944 4.66699 5.52711C4.66699 6.27477 5.27309 6.88087 6.02076 6.88087Z" fill="#141414"/>
                </svg>
                <span>Etiquetas</span>
              </button>*/}
              {/*<button className='adminUsersBtn'>
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.1909 0H1.80906C0.809942 0 0 0.809942 0 1.80906V15.1909C0 16.1901 0.809942 17 1.80906 17H15.1909C16.1901 17 17 16.1901 17 15.1909V1.80906C17 0.809942 16.1901 0 15.1909 0ZM2.18586 1.15211H14.8147C15.3858 1.15211 15.8484 1.61472 15.8484 2.18586V5.3052H1.15211V2.18586C1.15211 1.61472 1.61472 1.15211 2.18586 1.15211ZM1.15211 14.8147V6.4568H5.12843V15.8479H2.18586C1.61472 15.8479 1.15211 15.3853 1.15211 14.8141V14.8147ZM14.8147 15.8484H6.28002V6.4568H15.8479V14.8141C15.8479 15.3853 15.3853 15.8479 14.8141 15.8479L14.8147 15.8484Z" fill="#141414"/>
                </svg>
                <span>Plantillas</span>
              </button>*/}
              {/*<button className='adminUsersBtn'>
                <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 4.73383C0 4.48335 0.105357 4.24312 0.292893 4.066C0.48043 3.88889 0.734784 3.78938 1 3.78938H8.586L6.293 1.62377C6.19749 1.53665 6.12131 1.43244 6.0689 1.31721C6.01649 1.20198 5.9889 1.07805 5.98775 0.952651C5.9866 0.827249 6.0119 0.702884 6.06218 0.586816C6.11246 0.470747 6.18671 0.365298 6.2806 0.276621C6.3745 0.187945 6.48615 0.117817 6.60905 0.0703295C6.73194 0.022842 6.86362 -0.00105406 6.9964 3.56591e-05C7.12918 0.00112537 7.2604 0.0271791 7.3824 0.0766765C7.50441 0.126174 7.61475 0.198124 7.707 0.288328L11.707 4.06611C11.8945 4.24322 11.9998 4.48339 11.9998 4.73383C11.9998 4.98426 11.8945 5.22444 11.707 5.40155L7.707 9.17933C7.61475 9.26953 7.50441 9.34148 7.3824 9.39098C7.2604 9.44048 7.12918 9.46653 6.9964 9.46762C6.86362 9.46871 6.73194 9.44481 6.60905 9.39733C6.48615 9.34984 6.3745 9.27971 6.2806 9.19103C6.18671 9.10236 6.11246 8.99691 6.06218 8.88084C6.0119 8.76477 5.9866 8.64041 5.98775 8.515C5.9889 8.3896 6.01649 8.26567 6.0689 8.15045C6.12131 8.03522 6.19749 7.93101 6.293 7.84388L8.586 5.67827H1C0.734784 5.67827 0.48043 5.57877 0.292893 5.40165C0.105357 5.22453 0 4.98431 0 4.73383ZM21 11.3449H13.414L15.707 9.17933C15.8892 9.0012 15.99 8.76263 15.9877 8.515C15.9854 8.26737 15.8802 8.0305 15.6948 7.85539C15.5094 7.68028 15.2586 7.58095 14.9964 7.5788C14.7342 7.57665 14.4816 7.67184 14.293 7.84388L10.293 11.6217C10.1055 11.7988 10.0002 12.0389 10.0002 12.2894C10.0002 12.5398 10.1055 12.78 10.293 12.9571L14.293 16.7349C14.4816 16.9069 14.7342 17.0021 14.9964 17C15.2586 16.9978 15.5094 16.8985 15.6948 16.7234C15.8802 16.5483 15.9854 16.3114 15.9877 16.0638C15.99 15.8161 15.8892 15.5776 15.707 15.3994L13.414 13.2338H21C21.2652 13.2338 21.5196 13.1343 21.7071 12.9572C21.8946 12.7801 22 12.5399 22 12.2894C22 12.0389 21.8946 11.7987 21.7071 11.6216C21.5196 11.4444 21.2652 11.3449 21 11.3449Z" fill="#141414"/>
                </svg>
                <span>Comparar</span>
              </button>*/}
            </div>
          </div>
          <span className='adminUsersUsersQuantityText'>{`${usersFilteredList.length} contacto(s) encontrado(s)`}</span>
          <section className='adminUsersBoxCont'>
            {usersFilteredList.map((user)=>{
              return (
                <section className='adminUserBox' style={{backgroundColor:"#141414", width:"240px"}}>
                  {/*<div className='adminUserBoxCheckboxCont'>
                    <div className='adminUserBoxCheckboxTextCont'>
                      <div className='adminUserBoxCheckboxTextColor'/>
                      <span className='adminUserBoxCheckboxText'>Gerencia</span>
                    </div>
                    <input type='checkbox'/>
                  </div>*/}
                  <img className='adminUserBoxBannerImg' alt='' src={JSON.parse(user.profileImages).banner?JSON.parse(user.profileImages).banner.includes('storage.googleapis')?JSON.parse(user.profileImages).banner:`https://ppol.io/media/${user.userlink}/${JSON.parse(user.profileImages).banner}`:bannerDefaultImg}/>
                  <img className='adminUserBoxProfileImg' alt='' src={JSON.parse(user.profileImages).profile?JSON.parse(user.profileImages).profile.includes('storage.googleapis')?JSON.parse(user.profileImages).profile:`https://ppol.io/media/${user.userlink}/${JSON.parse(user.profileImages).profile}`:profileDefaultImg}/>
                  {user.isVerified==='1'&&<img loading="lazy" src={verifiedIcon} alt="✓" className='adminUserBoxVerifiedIcon'/>}
                  <span className='adminUserBoxProfileName'>{JSON.parse(user.profileBio).name}</span>
                  <span className='adminUserBoxProfileTitle'>{JSON.parse(user.profileBio).title}</span>
                  <div className='adminUserBoxProfileBtnCont'>
                    <a className='adminUserBoxProfileBtn' href={`https://www.ppol.io/${user.username}`} rel='noreferrer' target='_blank'>Ver perfil</a>
                    <button
                      className='adminUserBoxProfileBtn'
                      onClick={()=>{
                        setUserMainModal(true)
                        handleUserConfig(user.username)
                      }}
                    >
                      Configuración
                    </button>
                  </div>
                  
                </section>
              )
            })}
          </section>
        </section>
      }
    </div>
  )
}

export default AdminUsers