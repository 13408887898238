import React from 'react'
import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ppolLogo from "../../images/ppolLogo.png"
import "react-datepicker/dist/react-datepicker.css";
import "./auth.css"
import axios from 'axios';
import { AuthContext } from '../../context/authContext';
import CountryDataJSON from '../../countryData';
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import passwordHide from "../../images/passwordHide.svg";
import passwordShow from "../../images/passwordShow.svg";
import { es } from 'date-fns/locale/es';
registerLocale('es', es)


const pageURL = process.env.REACT_APP_PPOL_URL==="PRODUCTION"?"/backend":process.env.REACT_APP_PPOL_LOCAL_URL

function SignUp() {
    const navigate = useNavigate()
    let {register} = useContext(AuthContext)

    const[code, setCode] = useState("");
    const[username, setUsername] = useState("")
    const[email1, setEmail1] = useState("");
    const[email2, setEmail2] = useState("")
    const[password, setPassword] = useState("")
    const[confirmPassword, setConfirmPassword] = useState("")
    const[name, setName] = useState("")
    const[lastname, setLastname] = useState("")
    const[birthday, setBirthday] = useState(new Date())
    const[phone1, setPhone1] = useState("")
    const[phone2, setPhone2] = useState("")
    const[phone3, setPhone3] = useState("")
    const[company, setCompany] = useState("")
    const[position, setPosition] = useState("")
    const[phonesQuantity, setPhonesQuantity] = useState(1)
    const[emailQuantity, setEmailQuantity] = useState(1)
    const[errorCode, setErrorCode] = useState("")
    const[infoErrorCode1, setInfoErrorCode1] = useState("")
    const[verificationCode, setVerificationCode] = useState(true)
    const[selectCountry1, setSelectCountry1] = useState('+51')
    const[selectCountry2, setSelectCountry2] = useState('+51')
    const[selectCountry3, setSelectCountry3] = useState('+51')
    const[watchPassword, setWatchPassword] = useState(false)
    const[watchPasswordAlt, setWatchPasswordAlt] = useState(false)

    const peruData = CountryDataJSON.find(item => item.name_es === "Perú")
    const restOfCountries = CountryDataJSON
    .filter(item => item.name_es !== "Perú")
    .sort((a,b) => (a.name_es || '').localeCompare(b.name_es || ''))

    const CountryData = [peruData, ...restOfCountries]

    const toggleVerificationCode = () => {
        setVerificationCode(false);
    }

    function onChangeUsername(e) {
        setUsername(e.target.value.toLowerCase().replace(/[^a-z._\d]/, ''));
    }

    const checkInfo1 = () => {
        const errorList = [];
        const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;
        if(username.length<1){
            errorList.push("Nombre de Usuario")
        }
        if(!emailRegex.test(email1)){
            errorList.push("Correo")
        }
        if(email2.length>0 && !emailRegex.test(email2)){
            errorList.push("Correo 2")
        }
        if(password.length<1){
            errorList.push("Contraseña")
        }
        if(phone1.length<5){
            errorList.push("Teléfono")
        }
        if(phone2.length>0 && phone2.length<5){
            errorList.push("Teléfono 2")
        }
        if(phone3.length>0 && phone3.length<5){
            errorList.push("Teléfono 3")
        }
        if(errorList.length===0){
            if(password!==confirmPassword){
                setInfoErrorCode1("Las contraseñas no coinciden.")
            } else {
                setInfoErrorCode1("")
                checkInfo2()
            }
        } else {
            setInfoErrorCode1(`Campos incompletos o inválidos: ${errorList.join(', ')}.`)
        }
    }

    const checkInfo2 = () => {
        if(document.getElementById("checkTYC").checked){
            setInfoErrorCode1("")
            handleSignUp()
        } else {
            setInfoErrorCode1("Por favor, acepta los Términos y Condiciones junto a la Política de Privacidad para continuar.")
        }
    }

    const handleSignUp = async() => {
        const joinedPhone1 = `${selectCountry1} ${phone1}`
        const joinedPhone2 = `${selectCountry2} ${phone2}`
        const joinedPhone3 = `${selectCountry3} ${phone3}`
        try{
            await register({
                code,
                username,
                email1,
                email2,
                password,
                name,
                lastname,
                birthday,
                joinedPhone1,
                joinedPhone2,
                joinedPhone3,
                company,
                position
            })
            navigate(`/${username}/edit`)
        }catch(error){
            setInfoErrorCode1(error.response.data.message)
        }
    }

    const checkVerificationCode = async (e) => {
        e.preventDefault()

        try{
            await axios.get(`${pageURL}/getRegisterCode.php`, {params:{ code: code }})
            toggleVerificationCode()
        }catch(error){
            setErrorCode("*" + error.response.data.error)
        }
    }

    function toggleCodeError(err){
        setErrorCode("*" + err)
    }

    return(
        <div className="authContainer">
            <section className='authSectionContainer'>
                {verificationCode?
                    <section className='authFormContainer'>
                        <div className='authLogoContainer'>
                            <img className='authLogo' src={ppolLogo} alt='Ppol Logo'/>
                        </div>
                        <div className='authTitleContainer'>
                            <h1 className='authTitle'>¡Bienvenido!</h1>
                            <p className='authText'>A la comunidad de Ppol, este es el primer paso para acceder a tu perfil y conectar con el mundo.</p>
                        </div>
                        <div className='authForm'>
                            <div className='authFormSection'>
                                <input
                                    className='authFormInput'
                                    placeholder='Código'
                                    type='text'
                                    onChange={(e)=>setCode(e.target.value)}
                                    value={code}
                                    maxLength={10}
                                />
                            </div>
                            <div className="authFormSection">
                                <button
                                    className='authFormBtn'
                                    id='signInBtn'
                                    onClick={(code.length===8)?checkVerificationCode:() => toggleCodeError("Código inválido")}
                                >
                                    Continuar
                                </button>
                            </div>
                            <div className='authFormSection'>
                                <span className='authFormErrorText'>{errorCode}</span>
                            </div>
                        </div>
                    </section>
                    :
                    <section className='authFormContainer'>
                        <div className='authLogoContainer'>
                            <img className='authLogo' src={ppolLogo} alt='Ppol Logo'/>
                        </div>
                        <div className='authTitleContainer'>
                            <h1 className='authTitle'>Crea tu cuenta</h1>
                            <p className='authText'>Elige tu nombre de usuario y llena tus datos, recuerda que puedes actualizarlos luego.</p>
                        </div>
                        <div className='authForm'>
                            <div className='authFormSection'>
                                <input
                                    className='authFormInput authFormInputUser'
                                    placeholder='dominio'
                                    type='text'
                                    onChange={(e)=>onChangeUsername(e)}
                                    value={username}
                                    maxLength={30}
                                />
                                <span className='authFormInputUserText'>ppol.io/</span>
                            </div>
                            <div className='authFormSection'>
                                <input
                                    className='authFormInput authFormInputPw'
                                    placeholder='Contraseña'
                                    type={watchPassword?'text':'password'}
                                    onChange={(e)=>setPassword(e.target.value)}
                                    value={password}
                                    maxLength={50}
                                />
                                <button className='watchPassWord' onClick={()=>setWatchPassword(!watchPassword)}><img src={watchPassword?passwordHide:passwordShow} loading="lazy" alt="Ver contraseña"/></button>
                            </div>
                            <div className='authFormSection'>
                                <input
                                    className='authFormInput authFormInputPw'
                                    placeholder='Confirmar contraseña'
                                    type={watchPasswordAlt?'text':'password'}
                                    onChange={(e)=>setConfirmPassword(e.target.value)}
                                    value={confirmPassword}
                                    maxLength={50}
                                />
                                <button className='watchPassWord' onClick={()=>setWatchPasswordAlt(!watchPasswordAlt)}><img src={watchPasswordAlt?passwordHide:passwordShow} loading="lazy" alt="Ver contraseña"/></button>
                            </div>
                            <div className='authFormSection'>
                                <hr className='authFormSeparation'></hr>
                            </div>
                            <div className='authFormSection'>
                                <input
                                    className='authFormInput'
                                    placeholder='Nombre'
                                    type='text'
                                    onChange={(e)=>setName(e.target.value)}
                                    value={name}
                                    maxLength={90}
                                />
                                <input
                                    className='authFormInput'
                                    placeholder='Apellido'
                                    type='text'
                                    onChange={(e)=>setLastname(e.target.value)}
                                    value={lastname}
                                    maxLength={90}
                                />
                            </div>
                            <div className='authFormSection' style={{gap:"0"}}>
                                {/*<input
                                    className='authFormInput'
                                    placeholder='HOLA'
                                    type='date'
                                    onChange={(e)=>setBirthday(e.target.value)}
                                    value={birthday}
                                />*/}
                                <DatePicker
                                    className='authFormInput'
                                    onChange={(date)=>setBirthday(date)}
                                    selected={birthday}
                                    locale="es"
                                    dateFormat='dd/MM/yyyy'
                                    maxDate={new Date()}
                                />
                            </div>
                            <div className='authFormSection'>
                                <input
                                    className='authFormInput'
                                    placeholder='Compañía'
                                    type='text'
                                    onChange={(e)=>setCompany(e.target.value)}
                                    value={company}
                                    maxLength={80}
                                />
                                <input
                                    className='authFormInput'
                                    placeholder='Cargo'
                                    type='text'
                                    onChange={(e)=>setPosition(e.target.value)}
                                    value={position}
                                    maxLength={80}
                                />
                            </div>
                            <div className='authFormSection'>
                                <hr className='authFormSeparation'></hr>
                            </div>
                            <div className='authFormSection'>
                                <select
                                    className='authFormInput'
                                    onChange={(e)=>setSelectCountry1(e.target.value)}
                                    value={selectCountry1}
                                >
                                    {CountryData.map((item)=>{
                                        return(
                                            <option value={item.phone} key={item.id}>{item.emoji} {item.name_es} {item.phone}</option>
                                        )
                                    })}
                                </select>
                                <input
                                    className='authFormInput'
                                    placeholder='Telefono'
                                    type='text'
                                    onChange={(e)=>{
                                        const value = e.target.value.replace(/[^\d-]/g, '')
                                        setPhone1(value)
                                    }}
                                    value={phone1}
                                    maxLength={20}
                                />
                            </div>
                            {phonesQuantity>=2?
                                <div className='authFormSection'>
                                    <select
                                        className='authFormInput'
                                        onChange={(e)=>setSelectCountry2(e.target.value)}
                                        value={selectCountry2}
                                    >
                                        {CountryData.map((item)=>{
                                            return(
                                                <option value={item.phone} key={item.id}>{item.emoji} {item.name_es} {item.phone}</option>
                                            )
                                        })}
                                    </select>
                                    <input
                                        className='authFormInput'
                                        placeholder='Telefono 2'
                                        type='text'
                                        onChange={(e)=>{
                                            const value = e.target.value.replace(/[^\d-]/g, '')
                                            setPhone2(value)
                                        }}
                                        value={phone2}
                                        maxLength={20}
                                    />
                                </div>
                                :
                                <></>
                            }
                            {phonesQuantity>=3?
                                <div className='authFormSection'>
                                    <select
                                        className='authFormInput'
                                        onChange={(e)=>setSelectCountry3(e.target.value)}
                                        value={selectCountry3}
                                    >
                                        {CountryData.map((item)=>{
                                            return(
                                                <option value={item.phone} key={item.id}>{item.emoji} {item.name_es} {item.phone}</option>
                                            )
                                        })}
                                    </select>
                                    <input
                                        className='authFormInput'
                                        placeholder='Telefono 3'
                                        type='text'
                                        onChange={(e)=>{
                                            const value = e.target.value.replace(/[^\d-]/g, '')
                                            setPhone3(value)
                                        }}
                                        value={phone3}
                                        maxLength={20}
                                    />
                                </div>
                                :
                                <></>
                            }
                            {phonesQuantity<3?
                                <div className='authFormSection'>
                                    <button
                                        className='authFormAddBtn'
                                        onClick={()=>setPhonesQuantity(phonesQuantity + 1)}
                                    >
                                        Agregar teléfono
                                    </button>
                                </div>
                                :
                                <></>
                            }
                            <div className='authFormSection'>
                                <input
                                    className='authFormInput'
                                    placeholder='Correo'
                                    type='email'
                                    onChange={(e)=>setEmail1(e.target.value)}
                                    value={email1}
                                    maxLength={90}
                                />
                            </div>
                            {emailQuantity>=2?
                                <div className='authFormSection'>
                                    <input
                                        className='authFormInput'
                                        placeholder='Correo 2'
                                        type='email'
                                        onChange={(e)=>setEmail2(e.target.value)}
                                        value={email2}
                                        maxLength={90}
                                    />
                                </div>
                                :
                                <></>
                            }
                            {emailQuantity<2?
                                <div className='authFormSection'>
                                    <button
                                        className='authFormAddBtn'
                                        onClick={()=>setEmailQuantity(emailQuantity + 1)}
                                    >
                                        Agregar correo
                                    </button>
                                </div>
                                :
                                <></>
                            }
                            <div className='authFormSection'>
                                <input id='checkTYC' type='checkbox' className='authFormCheckbox'/>
                                <span className='authFormText'>
                                    He leído y estoy de acuerdo con los
                                    <a className='authFormTextLink' href="https://www.ppol.io/tyc-privacidad/" target='_blank' rel='noreferrer'> Términos y Condiciones </a>
                                    y la
                                    <a className='authFormTextLink' href="https://www.ppol.io/tyc-privacidad/" target='_blank' rel='noreferrer'> Política de Privacidad.</a>
                                </span>
                            </div>
                            <div className="authFormSection">
                                <button
                                    className='authFormBtn'
                                    onClick={checkInfo1}
                                >
                                    Continuar a mi perfil
                                </button>
                            </div>
                            <div className='authFormSection'>
                                <span className='authFormErrorText'>{infoErrorCode1}</span>
                            </div>
                        </div>
                    </section>
                }
            </section>
            <footer className='authFooter'>
                <span className='authFooterText'>2024 PPOL®</span>
                <a className='authFooterText' href='/tyc-privacidad/' target='_blank' rel='noreferrer'>Términos y condiciones</a>
                <a className='authFooterText' href='/tyc-privacidad/' target='_blank' rel='noreferrer'>Política de privacidad</a>
            </footer>
        </div>
    )
}

export default SignUp