import React, { useState } from 'react';
import QRCode from 'qrcode-generator';

import "./styles.css"
import ppolLogo from "../../images/ppolLogo.png"

const QRGenerator = () => {
  const [link, setLink] = useState("");
  const [qrcodes, setQrcodes] = useState([])

  const generateQRCode = () => {
    const format = document.getElementById('formatSelect').value
    const codes = link.split(" ").map(code => code.trim())
    setQrcodes([])
    
    if(format==="svg"){
      for(const code of codes){
        if(code.length !== 8 || isNaN(code)){
          window.alert(`El código ${code} no tiene 8 dígitos numéricos y será omitido`)
        } else {
          
          const qr = QRCode(0, 'H');
          qr.addData(`https://ppol.io/u/${code}`);
          qr.make();
          
          const svg = qr.createSvgTag({scalable: true, cellSize:1.89, margin:8});
          const blob = new Blob([svg], {type:'image/svg+xml'});
          const url = URL.createObjectURL(blob);

          setQrcodes(prevQrcodes => {
            const newQrcodes = [...prevQrcodes];
            newQrcodes.push(
              <div key="QRCODE" className='qrCodeContainer'>
                <div key={code} className='qrCodeContainerMedia' dangerouslySetInnerHTML={{__html:svg}}/>
                <span className="qrCodeContainerTitle">{code}</span>
              </div>
            );
            return newQrcodes
          });

          const linkElement = document.createElement('a');
          linkElement.href = url;
          linkElement.download = `${code}.svg`;
          linkElement.click();

          URL.revokeObjectURL(url);
        }
      }
    } else if (format === "img"){
      if(codes.length > 5){
        window.alert("El formato imagen admite un máximo de 5 códigos por consulta")
      } else {
        for(const code of codes){
          if(code.length !== 8 || isNaN(code)){
            window.alert(`El código ${code} no tiene 8 dígitos numéricos y será omitido`)
          } else {
            
            const qr = QRCode(0, 'H');
            qr.addData(`https://ppol.io/u/${code}`);
            qr.make();
            
            const png = qr.createImgTag(25);
            const src = png.split('"')[1]
            const blob = new Blob([png], {type:'image/gif'});
            const url = URL.createObjectURL(blob);
  
            setQrcodes(prevQrcodes => {
              const newQrcodes = [...prevQrcodes];
              newQrcodes.push(
                <div className='qrCodeContainer'>
                  <img loading="lazy" src={src} className='qrCodeContainerMedia' alt='QR'/>
                  <span className="qrCodeContainerTitle">{code}</span>
                </div>
              );
              return newQrcodes
            });
  
            const linkElement = document.createElement('a');
            linkElement.href = src;
            linkElement.download = `${code}.gif`;
            linkElement.click();
  
            URL.revokeObjectURL(url);
          }
        }
      }
    } else {
      window.alert("Error al seleccionar el formato")
    }
  }


  
  return (
    <div className="qrGenerator">
      <img className="qrGeneratorImg" loading="lazy" src={ppolLogo} alt=""/>
      <h1 className="qrGeneratorTitle">Generador de QR</h1>
      <div className="qrGeneratorInfo">
        <input className="qrGeneratorInput" type='text' placeholder='Ingresa código(s)' value={link} onChange={(e) => setLink(e.target.value)}/>
        <div className="qrGeneratorOptions">
          <select className="qrGeneratorSelect" id="formatSelect">
            <option className="qrGeneratorSelectOpt" value="svg">SVG</option>
            <option className="qrGeneratorSelectOpt" value="img">IMG</option>
          </select>
          <button className="qrGeneratorBtn" onClick={generateQRCode}>Generar y descargar QR</button>
        </div>
      </div>
      <div className="qrCodesContainer">{qrcodes}</div>
    </div>
  )
}

export default QRGenerator