import React from 'react'

import "../Dashboard/dashboard.css"

import flag404 from "../../images/flag404.png"

function NotFoundPage() {
  return (
    <div className='notFoundContainer'>
      <img className='notFoundImg' loading="lazy" src={flag404} alt=''/>
      <div className='notFoundRight'>
        <p>404 Oops!</p>
        <span>No hemos podido encontrar lo que estás buscando. Puedes regresar a la página principal o contactarnos si lo necesitas.</span>
        <a href='/'>Ir a casa</a>
      </div>
    </div>
  )
}

export default NotFoundPage