import React, { useContext, useState, useEffect } from 'react'
import "../Dashboard/dashboard.css"
import { useQuery } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthContext } from '../../context/authContext'
import Navbar from '../Navbar/Navbar.jsx';
import NavbarMobile from '../Navbar/NavbarMobile.jsx'
import Loading from '../Loading/Loading.jsx';
import { makeRequest } from '../../axios'
import proximamenteImg from '../../images/proximamenteImg.png'

function ProfileTutorial() {
    document.title = `Proximamente | PPOL`
    let {currentUser} = useContext(AuthContext);
    const userId = useLocation().pathname.split("/")[1];
    const navigate = useNavigate()
    /*const {isLoading} = useQuery(["user"],() => 
        makeRequest.get("findUserBasicInfo.php", {params: {user: userId}})
        .then((res) => {
            if(currentUser.userlink === res.data.userlink){
                localStorage.setItem("profileImg", JSON.parse(res.data.profileImages).profile)
                makeRequest.get("getTutorialVideos.php", {params: {language: 'es'}})
                .then(({data})=>{
                    //Reemplazar "replaceJSON" por "data"
                    setTutorialInfo(JSON.parse(data))
                })
            } else {
                localStorage.clear()
                navigate("/404")
            }
        })
    )*/

    const { data, isFetching, isLoading } = useQuery(["data"],() => {
        return Promise.all([
            makeRequest.get("findUserBasicInfo.php", {params: {user: userId}}),
        ]).then(([userInfoRes]) => {
            const userInfo = userInfoRes.data
            localStorage.setItem("profileImg", JSON.parse(userInfo.profileImages).profile)
            return userInfo
        }).catch(()=>{
            localStorage.clear()
            navigate("/404")
        })
    })

    return (
        <div className="main">
            <Navbar/>
            {isLoading?
                <Loading/>
                :
                <div className="configuration">
                    <NavbarMobile/>
                    <section className='main-title-container'>
                        <h1 className='main-title'>Página en desarrollo</h1>
                    </section>
                    <section className='infoSectionContainer' style={{width:"100%", height:"100%", display:"flex", alignItems:"center", justifyContent:"center"}}>
                        <section><img src={proximamenteImg} style={{width:"300px", objectFit:"contain"}}/></section>
                        
                    </section>
                </div>
            }
        </div>
    )
}

export default ProfileTutorial