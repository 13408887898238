import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ppolLogo from "../../images/ppolLogo.png"
import { AuthContext } from '../../context/authContext';

import './auth.css'

import passwordHide from "../../images/passwordHide.svg";
import passwordShow from "../../images/passwordShow.svg"

function SignIn() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("")
    const [err, setErr] = useState("");
    const [watchPassword, setWatchPassword] = useState(false)

    const navigate = useNavigate()
    
    let {login} = useContext(AuthContext)

    const handleSignIn = async (e) => {
        e.preventDefault()
        try{
            await login({username, password})
            navigate(`/${username}/edit`)
        }catch(error){
            console.log(error)
            setErr("*" + error.response.data.error)
        }
    }
    
    const handleSignInEnter = (e) => {
        if(e.keyCode === 13){
            document.getElementById("signInBtn").click()
        }
    }

    return(
        <main className='authContainer' onKeyDown={(e)=>handleSignInEnter(e)}>
            <section className='authSectionContainer'>
                <section className='authFormContainer'>
                    <div className='authLogoContainer'>
                        <img className='authLogo' src={ppolLogo} alt='Ppol Logo'/>
                    </div>
                    <div className='authTitleContainer'>
                        <h1 className='authTitle'>¡Bienvenido!</h1>
                        <p className='authText'>Que bueno tenerte de vuelta.</p>
                    </div>
                    <div className='authForm'>
                        <div className='authFormSection'>
                            <input
                                className='authFormInput'
                                placeholder='Usuario'
                                type='text'
                                onChange={(e)=>setUsername(e.target.value)}
                                value={username}
                                maxLength={30}
                            />
                        </div>
                        <div className='authFormSection'>
                            <input
                                className='authFormInput authFormInputPw'
                                placeholder='Contraseña'
                                type={watchPassword?'text':'password'}
                                onChange={(e)=>setPassword(e.target.value)}
                                value={password}
                                maxLength={50}
                            />
                            <button className='watchPassWord' onClick={()=>setWatchPassword(!watchPassword)}><img src={watchPassword?passwordHide:passwordShow} loading="lazy" alt="Ver contraseña"/></button>
                        </div>
                        <div className='authFormSection'>
                            <a className='authFormLink' href="https://wa.me/51912199842" target="_blank" rel="noreferrer">¿Has olvidado tu contraseña?</a>
                        </div>
                        <div className='authFormSection'>
                            <button className='authFormBtn'
                                id='signInBtn'
                                onClick={handleSignIn}
                            >
                                Iniciar sesión
                            </button>
                        </div>
                        <div className='authFormSection'>
                            <span className='authFormErrorText'>{err}</span>
                        </div>
                    </div>
                </section>
            </section>
            <footer className='authFooter'>
                <span className='authFooterText'>2024 PPOL®</span>
                <a className='authFooterText' href='/tyc-privacidad/' target='_blank' rel='noreferrer'>Términos y condiciones</a>
                <a className='authFooterText' href='/tyc-privacidad/' target='_blank' rel='noreferrer'>Política de privacidad</a>
            </footer>
        </main>
    )
}

export default SignIn