import React, {useEffect, useState} from 'react'
import { makeRequest } from '../../axios'

import "./styles.css"
import ppolLogo from "../../images/ppolLogo.png"

function UserCreator() {
    const [userCodes, setUserCodes] = useState("")
    const [userPassword, setUserPassword] = useState("")
    const [codes, setCodes] = useState([])
    const [status, setStatus] = useState([])

    const createUsers = () => {
        //console.log("HOLA")
        setCodes([])
        const inputPassword = document.getElementById("userGeneratorPassword").value;
        const inputCodes = document.getElementById("userGeneratorCodes").value;
        if(inputPassword===process.env.REACT_APP_CONVERTER_SECRET){
            const codes = inputCodes.split(" ").map(code => code.trim())
            for(const code of codes){
                if(code.length !== 8 || isNaN(code)){
                  window.alert(`El código ${code} no tiene 8 dígitos numéricos y será omitido`)
                } else {
                    makeRequest.get("/createUser.php", {params: {code: code}})
                    .then(() => {
                        setCodes(prevCodes=>{
                            const newCodes = [...prevCodes];
                            newCodes.push(code)
                            return newCodes
                        })
                        console.log(`${code} válido ✔️`)
                    })
                    .catch(() => {
                        setCodes(prevCodes=>{
                            const newCodes = [...prevCodes];
                            newCodes.push(code)
                            return newCodes
                        })
                        console.log(`${code} inválido ❌`)
                    })
                }
            }
        } else {
            console.log("Esa no es la contraseña pillín vanidón 🤨. La contraseña es adminPPOL2023")
        }
    }

    useEffect(()=>{
        setStatus([])
        if(codes.length>0){
            setStatus(
                <>
                    <p className='copyCodeTitle'>{codes.length} usuarios generados.</p>
                </>
            )
        }
    },[codes])

    return (
        <div className="userGenerator">
            <img className="userGeneratorImg" loading="lazy" src={ppolLogo} alt=""/>
            <h1 className="userGeneratorTitle">Creador de Usuarios</h1>
            <div className="userGeneratorInfo">
                <p className='userGeneratorInfoTitle'>Ingresa el códigos o los códigos a crear separados por un espacio.</p>
                <p className='userGeneratorInfoDescription'>Ejemplo: 12345678 12345679 12345680</p>
                <input id="userGeneratorCodes" className="userGeneratorInput" type="text" placeholder='Códigos' value={userCodes} onChange={(e)=>setUserCodes(e.target.value)}/>
                <input id="userGeneratorPassword" className="userGeneratorInput" type="password" placeholder='Contraseña para acceso' value={userPassword} onChange={(e)=>setUserPassword(e.target.value)}/>
                <button className='userGeneratorBtn' onClick={createUsers}>Generar códigos</button>
                {status}
            </div>
        </div>
      )
}

export default UserCreator