import React, { useEffect } from 'react'
import { makeRequest } from '../../axios'
import { useNavigate } from 'react-router-dom'

import "./styles.css"
import ppolLogo from "../../images/ppolLogo.png"
import loadingGif from "../../images/loadingGif.gif"

const pageURL = process.env.REACT_APP_PPOL_URL==="PRODUCTION"?"https://ppol.io/backend":process.env.REACT_APP_PPOL_LOCAL_URL

function CodeGenerator() {
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try{
                const response = await makeRequest.get(`${pageURL}/getPpolDynamicQRLink.php`)
                const link = response.data.link
                const url = document.createElement("a")
                url.href = link
                url.click()
                //navigate(link)
            } catch (err) {

            }
        }
        fetchData()
    },[navigate])

    return (
    <div style={{height:"100vh", display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column"}}>
        <img src={ppolLogo} alt="" loading="lazy" />
        <img src={loadingGif} alt="" style={{height:"80px", marginTop:"40px"}} loading="lazy" />
    </div>
  )
}

export default CodeGenerator