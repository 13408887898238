import React from 'react'

import "./tyc.css"

import ppolLogo from "../../images/ppolLogo.png"
import facebookLogo from "../../images/facebookLogo.png"
import instagramLogo from "../../images/instagramLogo.png"
import linkedinLogo from "../../images/linkedinLogo.png"
import tiktokLogo from "../../images/tiktokLogo.png"
import youtubeLogo from "../../images/youtubeLogo.png"

function TyC() {
    const closeNavbar = () => {
        document.querySelector("#menu-toggle").checked = false
    }

    return (
        <div>
            <nav className="navbar">
                <div className="navbarMini">
                    <a href="https://www.ppol.io" onClick={closeNavbar}>
                        <img className="ppolLogoMini" loading="lazy" src={ppolLogo} alt="Ppol Logo"/>
                    </a>
                    <input id="menu-toggle" type="checkbox"/>
                    <label className="menu-button-container" htmlFor="menu-toggle">
                        <div className="menu-button"></div>
                    </label>
                    <ul className="menu"> 
                    
                        <li className="ppolLogoNavLi">
                            <a href="/login">
                                <button id="navBtnMini2" className="navBtnMini">
                                    Iniciar sesión
                                </button>
                            </a>
                            <a href='/buy/ppol' target='_blank' rel='noreferrer'>
                                <button id="navBtnMini2Alt" className="navBtnMini">
                                    Comprar
                                </button>
                            </a>
                        </li>
                        {/*<li className="ppolLogoNavLi">
                            <a href="/#custom-Section" onClick={closeNavbar}>Producto</a>
                        </li>*/}
                        {/*<li className="ppolLogoNavLi">
                            <a href="#communitySection" onClick={closeNavbar}>Comunidad</a>
                        </li>*/}
                        {/*<li className="ppolLogoNavLi">
                            <a href="/#ppolMeUs" onClick={closeNavbar}>Públicos</a>
                        </li>
                        <li className="ppolLogoNavLi">
                            <a href="/#faqSection" onClick={closeNavbar}>FAQ</a>
                        </li>
                        <li className="ppolLogoNavLi">
                            <a href="/#suscriptionSection" onClick={closeNavbar}>Suscríbete</a>
                        </li>*/}
                        <li id="navCopyright">
                            ®2023 Ppol. Tecnología patentada
                        </li>
                    </ul>
                </div>



                <ul className="navLeft">
                    <li className="ppolLogoContainer">
                        <a href="https://www.ppol.io">
                            <img className="ppolLogo2" loading="lazy" src={ppolLogo} alt="Ppol Logo"/>
                                
                        </a>
                    </li>
                    {/*<li className="ppolLogoNavLi">
                        <a href="/#custom-Section">Producto</a>
                    </li>*/}
                    {/*<li className="ppolLogoNavLi">
                        <a href="#communitySection">Comunidad</a>
                    </li>*/}
                    {/*<li className="ppolLogoNavLi">
                        <a href="/#ppolMeUs">Públicos</a>
                    </li>
                    <li className="ppolLogoNavLi">
                        <a href="/#faqSection">FAQ</a>
                    </li>
                    <li className="ppolLogoNavLi">
                        <a href="/#suscriptionSection">Suscríbete</a>
                </li>*/}
                </ul>
                <ul className="navRight">
                    <li className="ppolLogoNavLi">
                        <a href="/login">
                            <button id="btnCreate" className="btnNav">Iniciar sesión</button>
                        </a>
                    </li>
                    <li className="ppolLogoNavLi">
                        <a href='/buy/ppol' target='_blank' rel='noreferrer'>
                            <button id="btnCreateAlt" className="btnNav">Comprar</button>
                        </a>
                    </li>
                </ul>
            </nav>

            <div className='tycprivCont'>
                <div id="Terminos-y-Condiciones" className='tycCont'>
                    <h2>TÉRMINOS Y CONDICIONES GENERALES DE USO</h2>
                    <p>¡Te damos la bienvenida a PPOL! PPOL S.A.C. es una empresa identificada con RUC No. 20610616616 y domiciliada en Jr. Tarapacá 357, Magdalena del Mar, Lima. Estas condiciones generales ("Condiciones") rigen el uso que haces de PPOL.</p>
                    <u>Acerca del producto</u>
                    <p>PPOL hace los esfuerzos necesarios para entregar sus productos de acuerdo con los plazos indicados en su pedido.</p>
                    <p>El usuario asume todos los costos de envío, costos de manejo, costos de servicio, y cargos de envío para los productos adquiridos. El riesgo de pérdida o daño de los productos adquiridos se transferirá al Usuario en el momento de la entrega de dichos productos. El usuario es el único responsable del cuidado de nuestros productos después de la entrega, por lo que PPOL no es responsable de ningún daño o pérdida de funcionalidad del producto una vez efectuada la entrega.</p>
                    <p>El usuario es responsable de su producto contra pérdida o robo, así como, de mantener la confidencialidad de la contraseña de su cuenta. El usuario es el único responsable de mantener su tarjeta NFC en condiciones de funcionamiento adecuadas. PPOL podrá cobrar una tarifa de reemplazo por tarjeta NFC perdida, robada, extraviada, deteriorada o abusada.</p>
                    <p>En caso el usuario pierda o sufra el robo del producto, debe notificar a PPOL para desactivar el producto. Para ello, el usuario debe enviar un correo electrónico a <a href='mailto:contacto@ppol.io'>contacto@ppol.io</a>.</p>
                    <u>Aceptación de los términos</u>
                    <p>Cuando creas una cuenta de PPOL o usa PPOL, aceptas estas condiciones. Al registrarte, acceder o utilizar nuestros servicios aceptas suscribir un contrato vinculante con PPOL (incluso si estás utilizando nuestros Servicios en nombre de una empresa). Si no estás conforme con este contrato («Contrato» o «Condiciones de uso»), no hagas clic en «Registrarte» (u otro enlace similar) y no accedas ni utilices de otro modo nuestros Servicios.</p>
                    <p>Puedes aceptar los Términos accediendo o utilizando nuestros Servicios. Entiendes y acuerdas que trataremos tu acceso o uso que hagas de los Servicios como la aceptación de estos Términos desde tal momento en lo sucesivo.</p>
                    <p>Aceptamos proporcionarte el Servicio de PPOL, que incluye todos los productos, las funciones, las apps, los servicios, las tecnologías y el software que ofrecemos para cumplir el propósito de PPOL: conectarte con el mundo impulsando tu mejor versión, juntos.</p>
                    <p>Si eres Visitante o Miembro de nuestros Servicios, cualquier recopilación, uso e información compartida en relación con tus datos personales quedarán sujetos a esta Política de privacidad, y sus actualizaciones, lo que abarca el modo en que recabamos, utilizamos, compartimos y almacenamos tu información personal.</p>
                    <p>El Servicio se compone de los siguientes aspectos:</p>
                    <ul>
                        <li><b>Desarrollar y usar tecnologías que nos ayuden a servir continuamente a nuestra comunidad en constante crecimiento.</b> Organizar y analizar información de nuestra creciente comunidad es central para nuestro Servicio. Gran parte de nuestro Servicio consiste en crear y usar tecnologías de vanguardia que nos ayuden a personalizar, proteger y mejorar nuestro Servicio a gran escala para una amplia comunidad.</li>
                        <li><b>Realizar investigaciones e innovar.</b> Usamos la información que tenemos para analizar nuestro Servicio y colaborar con otros en investigaciones que nos permitan mejorarlo y contribuir con el bienestar de nuestra comunidad.</li>
                    </ul>
                    <u>Tu cuenta con nosotros</u>
                    <p>Aceptas que eres el único responsable de manera civil y penal por la actividad que ocurra en tu cuenta. Si estás utilizando PPOL en nombre de una empresa o individuo, aceptas que estás autorizado por ellos para actuar y aceptar estos Términos en su nombre. El nombre de usuario que elijas debe ser apropiado para todos y no puede infringir los derechos de otra persona. Es importante que tu nombre de usuario sea apropiado para todas las audiencias y no infrinja los derechos de nadie, incluidos los derechos de propiedad intelectual (como los derechos de autor y las marcas registradas) ("Derechos de PI"). No puedes usar el nombre de otra persona, marca o empresa, tener un nombre de usuario que sea ofensivo, vulgar u obsceno, o crear una cuenta con un nombre de usuario con el que no tienes conexión solo para beneficiarte de él en el futuro (lo que se conoce como "ocupación ilegal de dominios"). En el supuesto que se presentase alguno de los supuestos antes señalados, PPOL alternativamente podrá según la gravedad del hecho:</p>
                    <ul>
                        <li>Solicitarte que cambies tu nombre de usuario, pudiendo reasignarlo a otra persona.</li>
                        <li>Suspender tu cuenta.</li>
                        <li>Cancelar tu cuenta.</li>
                    </ul>
                    <u>Tu contenido</u>
                    <p>Cuando publicas contenido en PPOL, nos otorgas el derecho a mostrarlo, usarlo y compartirlo. Cuando publicas contenido en tu perfil, nos otorgas una licencia para (i) usar, mostrar públicamente, distribuir, modificar, adaptar y crear trabajos derivados de dicho contenido; y (ii) usar tu nombre, imagen, voz, fotografía, semejanza y cualquier otro atributo personal en el contenido; en la Plataforma y en nuestro marketing en todos los medios (como nuestros canales sociales y cualquier otra publicidad). Esta licencia es mundial, libre de regalías y perpetua, lo que significa que podemos usar tu contenido en cualquier parte del mundo, sin pagarte tarifas, durante el tiempo que queramos. Aceptas que tiene todos los derechos de terceros necesarios para publicar el contenido en PPOL y otorgarnos esta licencia.</p>
                    <p>Conservarás todos tus derechos sobre tu contenido. Pero ten en cuenta que tu contenido será de acceso público y puede ser utilizado y compartido por otras personas en PPOL y en Internet.</p>
                    <p>Al registrarse en la plataforma de PPOL, el usuario manifiesta su consentimiento expreso para que se realice el tratamiento de sus datos personales. Este tratamiento comprende expresamente la posibilidad de compartir dichos datos, ya sea a título oneroso o gratuito, con terceros para toda clase de fines, incluyendo la gestión de negocios y el aprovechamiento comercial. Es importante destacar que la protección y confidencialidad de los datos personales de nuestros usuarios son una prioridad para nosotros, por lo que nos comprometemos a adoptar las medidas técnicas y organizativas necesarias para salvaguardar la privacidad de la información proporcionada.<b> Todo el proceso de tratamiento de datos se realizará de acuerdo con la normativa vigente en materia de protección de datos personales en Perú. </b>Al utilizar nuestros servicios, el usuario acepta y autoriza este tratamiento de datos en los términos aquí mencionados.</p>
                    <u>Información publicada</u>
                    <p>La información y el contenido que compartes o publicas pueden ser visibles para Miembros, Visitantes u otras personas (incluso fuera del Servicio). No compartas información personal que no quieras que el mundo vea.</p>
                    <u>Datos personales</u>
                    <p>Acuerdas con PPOL que, si el contenido incluye datos personales, quedará sujeto a nuestra Política de privacidad. Asimismo, acuerdas con PPOL que podremos acceder, almacenar, procesar y usar cualquier información y dato personal que facilites de acuerdo con lo establecido en los términos de la Política de privacidad y tus elecciones (incluida la configuración). Al enviar a PPOL sugerencias u otros comentarios sobre nuestros Servicios, aceptas que PPOL utilice y comparta (aunque no tiene que hacerlo) dichos comentarios para cualquier finalidad sin compensarte.</p>
                    <u>Nuestra plataforma</u>
                    <p>Somos propietarios de la Plataforma, pero te habilitamos el uso de la misma para compartir contenido y ver e interactuar con el contenido de otros usuarios. No somos responsables de ningún contenido, producto o servicio disponible a través de los perfiles de otros usuarios.</p>
                    <p>Todos los derechos (incluidos los derechos de propiedad intelectual) sobre la Plataforma (excluyendo su contenido) (la "IP de PPOL") son y seguirán siendo propiedad exclusiva de PPOL o de nuestros licenciantes. Usted no adquirirá ningún tipo de derecho sobre PPOL, ya sea respecto a la propiedad intelectual y/o propiedad física sobre la empresa PPOL S.A.C. de PPOL y no podrá utilizarla (incluido nuestro nombre de marca o logotipo) para ningún fin (como implicar una asociación con, o el respaldo de PPOL), sin nuestro consentimiento previo por escrito.</p>
                    <u>Servicios de terceros</u>
                    <p>Podemos incluir en la Plataforma el acceso a funciones o servicios específicos proporcionados por terceros (por ejemplo, un portal de pago o una tienda en línea).</p>
                    <p>PPOL se integra con varios productos y servicios de terceros. A menos que indiquemos lo contrario, no respaldamos (ni hacemos ninguna garantía o representación en relación con) ningún producto o servicio de terceros y no ofrecemos reembolsos por los montos que haya pagado a un tercero.</p>
                    <p>Queremos informarte que en ocasiones contratamos terceros o utilizamos servicios necesarios para el funcionamiento de PPOL. Sin embargo, es relevante destacar que, en estos casos, PPOL no ejerce control sobre dichos terceros o servicios. Por lo tanto, no asumimos responsabilidad por defectos en la prestación de los servicios proporcionados por ellos, lo cual te será informado a la brevedad posible. Te recomendamos comunicarte directamente con los terceros en caso de alguna duda o inconveniente con sus servicios.</p>
                    <u>Tus visitantes y clientes</u>
                    <p>Tú eres responsable de los visitantes de tu perfil, incluidos los clientes que compran bienes o servicios a través de tu perfil PPOL (colectivamente, "Usuarios finales"). Tú eres el único responsable de (i) la forma en que los Usuarios finales usan e interactúan con tu perfil y tu contenido; (ii) cumplir con todas las leyes en relación con tus Usuarios finales y todas las transacciones entre tú y los Usuarios finales realizadas a través de tu perfil PPOL.</p>
                    <p>Es importante aclarar que PPOL no participa de ninguna manera en las relaciones jurídicas o de consumo que puedan surgir entre nuestros clientes y sus visitantes como resultado de la compra de bienes o servicios. Nuestra plataforma actúa únicamente como un medio para facilitar la interacción entre ambas partes.</p>
                    <p>Cualquier transacción o interacción que se produzca entre los clientes y sus visitantes, incluyendo la compra de bienes o servicios, será de exclusiva responsabilidad de las partes involucradas. PPOL no será responsable por los términos, condiciones, garantías, entregas, pagos o cualquier otro aspecto relacionado con dichas transacciones.</p>
                    <u>Avisos y mensajes</u>
                    <p>Aceptas que te enviemos avisos y mensajes a través de nuestros sitios web, así como a través de la información de contacto que nos has proporcionado. Si tu información de contacto no está actualizada, podrías no recibir estos avisos importantes.</p>
                    <u>Derechos de Propiedad Intelectual</u>
                    <p>Como una condición de su acceso y uso de los Servicios, acuerdas que no utilizarás los Servicios para infringir ningún derecho de propiedad intelectual. Nos reservamos el derecho, con o sin notificación, en cualquier momento y a nuestra absoluta discreción, a bloquear el acceso a y/o cerrar las cuentas de cualquier usuario que infrinja o que supuestamente haya infringido cualesquier derechos de autor u otros derechos de propiedad intelectual.</p>
                    <p>No realizamos declaraciones ni otorgamos garantías, expresas ni implícitas, respecto a que cualquier contenido de PPOL (incluyendo el Contenido de Usuario) sea preciso, esté completo o actualizado. Cuando nuestros servicios contengan enlaces a otros sitios y a recursos proporcionados por terceros, estos enlaces se le proporcionan para efectos informativos únicamente. No tenemos control sobre el contenido de dichos sitios o recursos. Dichos enlaces no deberán interpretarse como una aprobación por nuestra parte sobre los sitios web enlazados o información que pueda obtener de ellos. Tú reconoces que no tenemos ninguna obligación de seleccionar de forma preliminar, monitorear, revisar ni editar cualquier contenido publicado por ti o por otros usuarios en los Servicios (incluyendo el Contenido de Usuario).</p>
                    <u>Seguridad</u>
                    <p>No garantizamos que nuestros Servicios serán seguros o libres de errores o virus. Tú eres responsable por configurar tu información, tecnología, programas computacionales y plataforma para acceder a nuestros Servicios. Tú deberás utilizar tu propio software de protección contra virus.</p>
                    <u>Quién asume la responsabilidad si ocurre algo</u>
                    <p>Nuestro Servicio se proporciona "tal como está", y no podemos garantizar que será seguro o funcionará correctamente en todo momento. En la medida en que la ley lo permita, no asumimos ninguna responsabilidad, garantía, ya sea explícita o implícita, incluida la responsabilidad por defectos o fallas, falta de derechos de dominio e infracción de derechos de propiedad intelectual de terceros.</p>
                    <p>Tampoco controlamos lo que las personas u otros hacen o dicen, ni somos responsables por sus acciones, conductas (online u offline) o contenidos (ni por los tuyos), incluido contenido ilegal o cuestionable. Tampoco somos responsables por los servicios y las funciones que otras personas o empresas ofrecen, incluso si accedes a ellos mediante nuestro Servicio.</p>
                    <u>Disponibilidad de los servicios</u>
                    <p>Podemos cambiar, suspender o retirar cualquiera de nuestros Servicios. De la misma forma, podemos modificar nuestros precios con carácter prospectivo, previa notificación dentro de un plazo razonable y en la medida en la que la legislación lo permita.</p>
                    <u>Modificaciones a los términos</u>
                    <p>Podemos modificar este Contrato, nuestra Política de privacidad y nuestra Política de cookies periódicamente. Si introducimos algún cambio importante en el Contrato, te avisaremos a través de nuestros Servicios o por otros medios, para darte la oportunidad de revisar los cambios antes de que se hagan efectivos. Acordamos que las modificaciones no pueden ser retroactivas. Si no estás de acuerdo con cualquiera de los cambios, puedes cerrar tu cuenta. Si continúas utilizando nuestros Servicios después de publicar o de enviar un aviso sobre los cambios en estos términos, esto implicará que estás de acuerdo con los términos actualizados desde su fecha de entrada en vigor.</p>
                    <p>Al registrar tu cuenta en PPOL reconoces expresamente tu aceptación para que tus datos personales sean conservados y utilizados por PPOL, para fines estadísticos, administrativos y de gestión comercial u otros relacionados con los fines comerciales de PPOL. La presente autorización comprende también el envío de invitaciones y publicidad referida a nuevos productos, eventos y actividades, promociones y descuentos de servicios propios o de terceros, entre otros, tanto de PPOL como de terceros con los PPOL tenga un acuerdo comercial vigente. Asimismo, tus datos personales podrán ser compartidos con todas las empresas, sellers, comercio físicos o virtuales que integren PPOL, incluyendo empresas privadas, instituciones del Estado, consorcios y demás agentes económicos nacionales o extranjeros. Todos estos terceros podrán remitirle información comercial o publicitaria referida a sus productos o servicios u actividades relacionadas con su objeto social o instrumento de constitución legal. Conforme a Ley, los datos personales que comparta serán conservados en un banco <b>cuyo titular es PPOL S.A.C.</b>, ubicado en <b>Av Grau 273, oficina 801</b>. Para el ejercicio de tus derechos sobre los datos, puedes dirigirte a la dirección indicada o enviar un correo electrónico a <a href='mailto:contacto@ppol.io'>contacto@ppol.io</a>. La presente autorización es por tiempo indefinido y podrá ser revocada en cualquier momento. En caso de ejercer tus derechos sobre los datos directamente ante los terceros antes señalados, por favor sírvete informarlo también a PPOL.</p>
                </div>
                <div id="Politica-de-Privacidad" className='privContainer'>
                    <h2>POLÍTICA DE PRIVACIDAD</h2>
                    <p>¡Gracias por elegir formar parte de nuestra comunidad! Desde PPOL nos comprometemos a proteger tu información personal y tu derecho a la privacidad. Si tienes alguna pregunta o duda sobre nuestra política o nuestras prácticas con respecto a tu información personal, ponte en contacto con nosotros en <a href='mailto:contacto@ppol.io'>contacto@ppol.io</a>.</p>
                    <p>La presente política se encuentra sujeta a las disposiciones vigentes de la legislación peruana y en particular a la Ley N° 29733, Ley de Protección de Datos Personales y el Decreto Supremo N°003-2013-JUS que aprueba el Reglamento de la Ley de Protección de Datos Personales.</p>
                    <p>La presente política de privacidad se aplica a toda la información recogida a través de nuestro sitio web (en <a href='https://www.ppol.io'>https://www.ppol.io</a>) (el "Sitio"), y a cualquier servicio, aplicación, software, contenido, marketing, evento, hardware y tecnología relacionados que se ofrezcan a través del Sitio o en relación con él (los "Servicios").</p>
                    <p>Cuando visitas el Sitio o utilizas nuestros Servicios, nos confías tu información personal. Nos tomamos tu privacidad muy en serio. En esta Política de Privacidad, tratamos de explicarte de la manera más clara posible qué información recogemos, cómo la utilizamos y qué derechos tienes en relación con ella. Esperamos que te tomes un tiempo para leerla detenidamente, ya que es importante. Si hay algún término de esta Política de Privacidad que no aceptes, te rogamos que dejes de utilizar nuestro Sitio y nuestros Servicios. Al acceder o utilizar el Sitio o los Servicios, reconoces haber leído y comprendido esta Política de Privacidad. Lee atentamente esta política de privacidad, ya que te ayudará a tomar decisiones informadas sobre la posibilidad de compartir tu información personal con nosotros.</p>
                    <u>Información personal que recopilamos</u>
                    <ul>
                        <li>Información que proporcionas voluntariamente. Cuando te registras para convertirte en Usuario de PPOL, utilizas o interactúas con nuestros Servicios o personal de PPOL, visitas nuestra Plataforma, visitas un Perfil de usuario, te registras en nuestro Portal de desarrollador, respondes a una encuesta o participas en una promoción comercial, nosotros podemos pedirte que proporciones información personal voluntariamente.</li>
                        <li>Información que recopilamos automáticamente. Cuando visitas nuestra Plataforma, utilizas nuestros Servicios de PPOL, interactúas con un perfil de usuario, respondes a una encuesta o participa en una promoción comercial, recopilamos cierta información automáticamente desde tu dispositivo. Específicamente, la información que recopilamos automáticamente puede incluir información como tu dirección IP, tipo de dispositivo, números únicos de identificación del dispositivo, tipo de navegador, ubicación geográfica amplia (por ejemplo, ubicación a nivel de país o ciudad), zona horaria, datos de uso, datos de diagnóstico y otra información técnica. También podemos recopilar información sobre cómo tu dispositivo ha interactuado con nuestra Plataforma, el Servicio PPOL o los Perfiles de usuario, incluidas las páginas a las que accediste y los enlaces en los que hiciste clic. La recopilación de esta información nos permite entenderte mejor, de dónde vienes y qué contenido es de tu interés. Usamos esta información para nuestros fines analíticos internos, para mejorar la calidad y la relevancia de nuestra Plataforma y los Servicios de PPOL, para proporcionar sugerencias y sugerencias a nuestros Usuarios de PPOL y para hacer recomendaciones de Perfiles de PPOL que podrías estar interesado en ver.</li>
                        <li>Datos de niños. Nuestros servicios no están destinados a niños menores de 18 años (el "Límite de edad"). Si estás por debajo del límite de edad, no utilices los servicios de PPOL y no nos proporciones tu información personal. Si eres padre o tutor y sabes que una persona (de la que eres padre o tutor) por debajo del límite de edad nos ha proporcionado información personal, comunícate con nosotros. En caso de notificación o descubrimiento, haremos todos los esfuerzos razonables para borrar o destruir cualquier información personal que podamos haber recopilado o almacenado sobre esa persona.</li>
                    </ul>
                    <u>Por qué recopilamos tu información personal</u>
                    <p>En general, utilizaremos la información que recopilamos para los fines descritos en este Aviso de privacidad o para los fines que te explicamos en el momento en que recopilamos tu información personal. Éstas incluyen:</p>
                    <ul>
                        <li>Proporcionar y prestar los Servicios de PPOL y evaluar, mantener y mejorar el rendimiento y la funcionalidad de los Servicios de PPOL.</li>
                        <li>Para garantizar que los Servicios de PPOL sean relevantes para tí y tu dispositivo, para notificarte sobre los cambios en los Servicios de PPOL y para entregar contenido específico y/o localizado en función de tus datos de usuario, ubicación y preferencias. </li>
                        <li>Para la investigación del consumidor y para permitirte participar en encuestas o funciones interactivas de los Servicios de PPOL cuando elijas hacerlo.</li>
                        <li>Para brindar atención al cliente y para procesar y responder a una solicitud, queja o Informe de Propiedad Intelectual o Contraaviso que haya realizado.</li>
                        <li>Supervisar el uso de los Servicios de PPOL y detectar, prevenir y abordar problemas técnicos.</li>
                        <li>Para llevar a cabo la planificación, elaboración de informes y pronósticos comerciales.</li>
                        <li>Para entregar materiales promocionales, ofertas especiales e información general sobre otros bienes, servicios y eventos que ofrecemos que son similares a los que ya compraste o preguntaste, a menos que hayas optado por no recibir dicha información.</li>
                        <li>Para la administración de nuestro negocio, incluido el cumplimiento y ejercicio de nuestras obligaciones y derechos, el ejercicio o la defensa de reclamaciones legales, el cumplimiento de nuestras obligaciones legales y las solicitudes de aplicación de la ley, y la gestión de la relación con usted.</li>
                        <li>Para verificar tu identidad y detectar fraudes y posibles fraudes, incluidos los pagos fraudulentos y el uso fraudulento de los Servicios de PPOL.</li>
                        <li>Para incluir el contenido del usuario de PPOL como parte de nuestras campañas de publicidad y marketing para promocionar PPOL.</li>
                        <li>Informar a nuestros algoritmos para que podamos ofrecerte las recomendaciones más relevantes, incluidos los perfiles de usuario que te puedan interesar.</li>
                    </ul>
                    <u>Base legal para el procesamiento de información personal</u>
                    <p>Nuestra base legal para recopilar y utilizar la información personal descrita anteriormente dependerá de la información personal en cuestión y de las circunstancias específicas en las que la recopilamos.</p>
                    <p>Sin embargo, normalmente recopilaremos tu información personal solo cuando tengamos tu consentimiento, cuando necesitemos la información personal para realizar un contrato contigo o cuando el procesamiento sea en nuestro interés legítimo y no sea anulado por tus intereses de protección de datos o derechos fundamentales y libertades. En algunos casos, también podemos tener la obligación legal de recopilar tu información personal, o podemos necesitar la información personal para proteger tus intereses vitales o los de otra persona.</p>
                    <p>Si te solicitamos que proporciones información personal para cumplir con un requisito legal, lo dejaremos claro en el momento pertinente y te informaremos si la provisión de tu información personal es obligatoria o no (así como las posibles consecuencias si no proporcionas tu información personal). Como se indicó anteriormente, requerimos cierta información personal para celebrar un contrato contigo como usuario de PPOL. Sin tu información personal, no podremos brindarte los Servicios de PPOL disponibles para los Usuarios de PPOL.</p>
                    <p>Si recopilamos y usamos tu información personal en función de nuestros intereses legítimos (o los de cualquier tercero), este interés normalmente será desarrollar y mejorar los Servicios de PPOL, proporcionar funcionalidad adicional, garantizar la seguridad adecuada o implementar contenido confidencial. advertencias y moderación de contenido. Es posible que tengamos otros intereses legítimos y, si corresponde, te dejaremos claro en el momento pertinente cuáles son esos intereses legítimos.</p>
                    <u>Cuánto tiempo retenemos tu información personal</u>
                    <p>Conservaremos tu información personal durante el período necesario para cumplir con los fines descritos en este Aviso de privacidad y, en cada caso, de acuerdo con los requisitos legales y reglamentarios aplicables con respecto a los períodos de retención permitidos o requeridos y los períodos de limitación relevantes para acciones legales.</p>
                    <u>Cómo aseguramos tu información personal</u>
                    <p>Hemos implementado medidas de seguridad adecuadas para evitar que tu información personal se pierda, use o acceda accidentalmente de forma no autorizada, se altere o se divulgue.</p>
                    <p>Además, limitamos el acceso a tu información personal a empleados, agentes, contratistas y otros terceros que tengan una necesidad comercial de acceso. Solo procesarán tu información personal siguiendo nuestras instrucciones y están sujetos a un deber de confidencialidad.</p>
                    <p>Si bien nos esforzamos por utilizar medios comercialmente aceptables para proteger tu información personal, no podemos garantizar su seguridad absoluta. Por lo tanto, hemos implementado procedimientos para hacer frente a cualquier sospecha de violación de la información personal y te notificaremos a ti y a cualquier regulador aplicable sobre una violación cuando estemos legalmente obligados a hacerlo.</p>
                    <u>Cambios en el Aviso de privacidad</u>
                    <p>Es posible que actualicemos nuestro Aviso de privacidad de vez en cuando en respuesta a cambios en los desarrollos legales, técnicos o comerciales. Cuando actualicemos nuestro Aviso de privacidad, tomaremos las medidas adecuadas para informarte, de acuerdo con la importancia de los cambios que realicemos. Obtendremos tu consentimiento para cualquier cambio importante en el Aviso de privacidad siempre y cuando así lo exijan las leyes de protección de datos aplicables.</p>
                    <u>Ejercicio de Derechos ARCO</u>
                    <p>El usuario podrá ejercitar sus derechos de Información, Acceso, Rectificación, Cancelación, Revocación, Oposición, etc. (ARCO) al uso de sus datos personales, de conformidad con la Ley de Protección de Datos Personales, Ley N° 29733. En ese sentido, el usuario tiene derecho, entre otros, a acceder a su información personal, a solicitar la rectificación de datos inexactos y a revocar su consentimiento para el tratamiento de la misma; asimismo, podrá solicitar la supresión de sus datos u oponerse al tratamiento de los mismos, incluso cuando estos ya no resulten necesarios para los fines que motivaron su recopilación.</p>
                    <p>El ejercicio de estos derechos es gratuito. Para ello, puede dirigirse por correo electrónico <a href='mailto:contacto@ppol.io'>contacto@ppol.io</a> adjuntando la “Solicitud de Atención de Derechos ARCO - Ley N° 29733, Ley de Protección de Datos Personales” y copia del documento de identidad (DNI/CE/Pasaporte) que acredite su titularidad sobre los datos personales respecto de los cuales ejercerá su derecho.</p>
                    <u>Consentimiento</u>
                    <p>Al aceptar la presente Política de Privacidad, nos brindarás tu consentimiento previo, libre, expreso, informado e inequívoco. Por lo tanto, tendremos la autorización correspondiente para realizar el tratamiento de tus datos personales de conformidad con los puntos expuestos anteriormente.</p>
                </div>
            </div>

            <footer className="foot">
                <p className="footP1">Conecta con nosotros</p>
                <div className="footImgContainer">
                    <a href="https://www.facebook.com/profile.php?id=100089400941802" target="_blank" rel="noreferrer">
                        <img loading="lazy" src={facebookLogo} alt="Facebook Ppol"/>
                    </a>
                    <a href="https://www.instagram.com/somosppol/" target="_blank" rel="noreferrer">
                        <img loading="lazy" src={instagramLogo} alt="Instagram Ppol"/>
                    </a>
                    <a href="https://www.linkedin.com/company/somosppol/" target="_blank" rel="noreferrer">
                        <img loading="lazy" src={linkedinLogo} alt="LinkedIn Ppol"/>
                    </a>
                    <a href="https://www.tiktok.com/@somosppol" target="_blank" rel="noreferrer">
                        <img loading="lazy" src={tiktokLogo} alt="TikTok Ppol"/>
                    </a>
                    <a href="https://www.youtube.com/@somosppol" target="_blank" rel="noreferrer">
                        <img loading="lazy" src={youtubeLogo} alt="Youtube Ppol"/>
                    </a>
                    {/*<a href="https://twitter.com/somosppol" target="_blank" rel="noreferrer">
                        <img loading="lazy" src={youtubeLogo} alt="Twitter Ppol"/>
                    </a>
                    <a href="https://www.pinterest.es/somosppol/" target="_blank" rel="noreferrer">
                        <img loading="lazy" src={youtubeLogo} alt="Pinterest Ppol"/>
                    </a>
                    <a href="https://open.spotify.com/user/31nq66sd45n6ab3twe2wxmw32uge" target="_blank" rel="noreferrer">
                        <img loading="lazy" src={youtubeLogo} alt="Spotify Ppol"/>
                    </a>
                    <a href="https://discord.gg/Qrgqdkr5dU" target="_blank" rel="noreferrer">
                        <img loading="lazy" src={youtubeLogo} alt="Discord Ppol"/>
                    </a>*/}
                </div>
                {/*<ul className="footLiContainer">
                    <a href="#custom-Section">
                        <li>Producto</li>
                    </a>
                    {/*<a href="#communitySection">
                        <li>Comunidad</li>
                    </a>}
                    <a href="#ppolMeUs">
                        <li>Públicos</li>
                    </a>
                    <a href="#faqSection">
                        <li>FAQ</li>
                    </a>
                    <a href="#suscriptionSection">
                        <li>Suscríbete</li>
                    </a>
                </ul>*/}
                <p className="footP2">®2023 Ppol. Tecnología patentada</p>
            </footer>
        </div>
    )
}

export default TyC