export const languageProfile = (language) => {
    switch (language) {
      case 'es':
        return {
          header: 'Rellena tus datos',
          name: 'Nombre',
          lastname: 'Apellido',
          email: 'Correo',
          phone: 'Teléfono',
          company: 'Compañía (opcional)',
          position: 'Cargo (opcional)',
          message: 'Mensaje (opcional)',
          btnSend: 'Enviar',
          btnContact: 'Guardar Contacto',
          btnInformation: 'Envía tu información',
          confirmSend: 'Contacto enviado.'
        };
      case 'pt':
        return {
          header: 'Preencha seus dados',
          name: 'Primeiro nome',
          lastname: 'Sobrenome',
          email: 'E-mail',
          phone: 'Telefone',
          company: 'Empresa (opcional)',
          position: 'Posição (opcional)',
          message: 'Mensagem (opcional)',
          btnSend: 'Enviar',
          btnContact: 'Salvar contato',
          btnInformation: 'Envie suas informações',
          confirmSend: 'Contato enviado.'
        }
      default:
        return {
          header: 'Fill in your details',
          name: 'First Name',
          lastname: 'Surname',
          email: 'Email',
          phone: 'Phone',
          company: 'Company (optional)',
          position: 'Position (optional)',
          message: 'Message (optional)',
          btnSend: 'Send',
          btnContact: 'Save Contact',
          btnInformation: 'Send your information',
          confirmSend: 'Contact sent.'
        };
    }
  };