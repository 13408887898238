import React, { useRef, useState } from 'react';

import { dataTestimonials } from './dataTestimonials'

import '../landing2.css'

const Testimonials = () => {

    const containerRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - containerRef.current.offsetLeft);
        setScrollLeft(containerRef.current.scrollLeft);
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        const x = e.pageX - containerRef.current.offsetLeft;
        const walk = x - startX;
        containerRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

  return (
    <section className="testimonials-section-container">
                <div className="testimonials-section">
                    <h1>Conoce lo que las personas están diciendo de PPOL 🎯</h1>   
                    <div className='testimonials-item-container' ref={containerRef}
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}
                        onMouseLeave={handleMouseUp}
                    >
                        <div className="column-1">
                            {dataTestimonials.slice(0, 2).map((item, index) => {
                                const itemClass = `testimonials-item item-${index + 1}`;
                                return (
                                    <div className={itemClass} key={item.id}>
                                        <header className="testimonials-item-profile">
                                            <img src={item.image} alt="#" />
                                            <div>
                                                <p className="testimonials-item-name">{item.name}</p>
                                                <p className="testimonials-item-position">{item.position}</p>
                                            </div>
                                        </header>
                                        <p className="testimonials-item-description">{item.testimony}</p>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="column-2">
                            {dataTestimonials.slice(2, 4).map((item, index) => {
                                const itemClass = `testimonials-item item-${index + 3}`;
                                return (
                                    <div className={itemClass} key={item.id}>
                                        <header className="testimonials-item-profile">
                                            <img src={item.image} alt="#" />
                                            <div>
                                                <p className="testimonials-item-name">{item.name}</p>
                                                <p className="testimonials-item-position">{item.position}</p>
                                            </div>
                                        </header>
                                        <p className="testimonials-item-description">{item.testimony}</p>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="column-3">
                            {dataTestimonials.slice(4).map((item, index) => {
                                const itemClass = `testimonials-item item-${index + 5}`;
                                return (
                                    <div className={itemClass} key={item.id}>
                                        <header className="testimonials-item-profile">
                                            <img src={item.image} alt="#" />
                                            <div>
                                                <p className="testimonials-item-name">{item.name}</p>
                                                <p className="testimonials-item-position">{item.position}</p>
                                            </div>
                                        </header>
                                        <p className="testimonials-item-description">{item.testimony}</p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </section>
  )
}

export default Testimonials