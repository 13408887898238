import React, { useState, useEffect } from 'react';

import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, FreeMode, Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'mapbox-gl/dist/mapbox-gl.css'

import { shareMetodsData } from './shareMetodsData';

const ShareMetods = () => {

    const [slidesPerView, setSlidesPerView] = useState(3);
    const [spaceBetween, setSpaceBetween] = useState('3.5%')

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 767) {
                setSlidesPerView(1);
                setSpaceBetween(30);
              } else if (window.innerWidth <= 1450) {
                setSlidesPerView(2);
                setSpaceBetween(30);
              } else {
                setSlidesPerView(3);
                setSpaceBetween('3.5%');
              }
        };
    
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

  return (
    <section className="share-metods-section-container">
      <div className="share-metods-section">
        <h1>Descubre las diversas maneras <br /> de compartir tu PPOL</h1>
        <p className="share-metods-subtitle">Comparte toda tu información en diferentes formatos</p>
          <Swiper
            slidesPerView={slidesPerView}
            spaceBetween={spaceBetween}
            centeredSlides={false}
            pagination={{
               clickable: true,
            }}
            modules={[FreeMode, Pagination, Autoplay]}
            autoplay={{ delay: 5000, disableOnInteraction: false }}
            className='share-metods-article-container'
          >
            {shareMetodsData.map((article) => (
            <SwiperSlide key={article.id}>
              <article>
                <div className="share-metods-article-item">
                  <img src={article.image} alt="" />
                </div>
                <div className="share-metods-article-description">
                  <h3>{article.title}</h3>
                  <p>{article.description}</p>
                </div>
              </article>
            </SwiperSlide>
          ))}
          </Swiper>
      </div>
    </section>
  )
}

export default ShareMetods